import { SET_ARMY_ID, LOGIN } from './user.types';

// eslint-disable-next-line import/prefer-default-export
export const setArmyId = (armyId) => ({
  type: SET_ARMY_ID,
  payload: armyId
});

export const login = (user) => ({
  type: LOGIN,
  payload: user
});
