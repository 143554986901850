/* eslint-disable max-len */
import { columns, DashabIsCore } from './data-for-table';
import { changeDateFormat } from '../../utils';

export const getJobStatusOptions = () => {
  const JobStatusCol = columns.find((column) => column.id === 'jobStatus');
  return JobStatusCol.options;
};

const getIsCore = (isCore) => {
  switch (isCore) {
    case true:
      return DashabIsCore.core.value;
    case false:
      return DashabIsCore.notCore.value;
    default:
      return '';
  }
};

export const getSetupRows = (setRowsEditingState, setRows, translate) => (
  jobs,
  numOfRows,
  indexEditedRow,
) => {
  if (numOfRows === 0) {
    const newRowsState = [];
    jobs.forEach(() => {
      newRowsState.push(false);
    });
    setRowsEditingState(newRowsState);
  }
  const rowsWithInput = jobs.map((job, index) => {
    if (numOfRows === 0 || indexEditedRow !== index) {
      return {
        ...job,
        jobStatus: translate(
          getJobStatusOptions().find((option) => option.key === job.jobStatus)
            .value,
        ),
        category: job.category,
        unitGroup: job.unitGroup,
        isCore: getIsCore(job.isCore),
        finalApplicationDate: changeDateFormat(job.finalApplicationDate),
        bunchDate: changeDateFormat(job.bunchDate),
        dashabDate: changeDateFormat(job.dashabDate),
      };
    }
    return {};
  });
  setRows(rowsWithInput);
};
