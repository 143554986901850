import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './sidebar-personal-info.styles.scss';
import UserAvatar from '../../user-avatar/user-avatar.component';

const PersonalInfoForSideBar = (props) => {
  const { name, gender, picture } = props;

  return (
    <div className="sidebar-personal-info-container">
      <UserAvatar small gender={gender} picture={picture} />
      <span className="user-name">{name}</span>
    </div>
  );
};

PersonalInfoForSideBar.propTypes = {
  name: PropTypes.string,
  gender: PropTypes.string,
  picture: PropTypes.string,
};

PersonalInfoForSideBar.defaultProps = {
  name: '',
  gender: '',
  picture: '',
};

const mapStateToProps = (state) => ({
  name: `${state.user.firstName || ''} ${state.user.lastName || ''}`,
  gender: state.user.gender,
  picture: state.user.picture,
});

export default connect(mapStateToProps)(PersonalInfoForSideBar);
