/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '../../../../common/components/dialog/dialog.component';
import Button from '../../../../common/components/button/button.component';

const DeleteJobPopup = (props) => {
  const {
    isOpen, setIsOpen, deleteJob, index
  } = props;

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    if (!Number.isNaN(index)) {
      deleteJob(index);
      handleClose();
    }
  };

  return (
    <Dialog isOpen={isOpen} handleClose={handleClose} header="DELETING_JOB">
      <div className="buttons">
        <Button text="BACK" onClick={handleClose} />
        <Button text="APPROVE" onClick={handleDelete} />
      </div>
    </Dialog>
  );
};

DeleteJobPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired,
  index: PropTypes.number
};

DeleteJobPopup.defaultProps = {
  index: null
};

export default DeleteJobPopup;
