/* eslint-disable operator-linebreak */
/* eslint-disable nonblock-statement-body-position */
export const changeDateFormat = (dateAsString) => {
  if (!dateAsString) return '-';
  if (dateAsString !== '-') {
    return new Date(dateAsString).toLocaleDateString('en-GB');
  }

  return dateAsString;
};

export const createQueryFromSearchingUser = (
  searchValue,
  displayableObj = null
) => {
  let query = {};
  if (displayableObj) {
    query = displayableObj;
  }
  if (/^[0-9]*$/.test(searchValue)) {
    query = { ...query, armyId: searchValue };
    return query;
  }
  if (/^(?=.*[0-9])(?=.*[א-תA-Za-z])([א-ת0-9A-Za-z]+)$/.test(searchValue)) {
    throw new Error('לא ניתן לחפש מ.א ושם במקביל');
  }
  const [firstName, lastName] = searchValue.split(' ');
  if ((firstName?.length || 0) + (lastName?.length || 0) >= 3) {
    query = { ...query, firstName, lastName };
    return query;
  }
  throw new Error('מספר התווים המינימלי לחיפוש הוא שלושה');
};

export const shortDate = (stringDate) => {
  const date = new Date(stringDate);

  return `${date.getDate()}.${date.getMonth() + 1}`;
};

export const getErrorMessage = (err, t) => {
  if (err.response?.data?.type === 'ExpirationDateError') {
    return `${err.response?.data.message} ${t('ERROR.EXPIRATION_DATE')} `;
  }
  if (/[\u0590-\u05FF]/.test(err.toString())) {
    return err.toString().replace('Error: ', '');
  }
  if (/[\u0590-\u05FF]/.test(err.response?.data.message)) {
    return err.response.data.message;
  }
  switch (err.response?.status) {
    case 404:
      if (err.response.data.message?.toLowerCase().includes('user')) {
        return t('ERROR.USER_NOT_FOUND');
      }
      if (err.response.data.message?.toLowerCase().includes('job')) {
        return t('ERROR.JOB_NOT_FOUND');
      }
      return t('ERROR.GENERAL');
    case 401:
      return t('ERROR.UNAUTHORIZED');
    case 403:
      return t('ERROR.FORBIDDEN');
    case 400:
      if (
        err.response.data.type?.toLowerCase().includes('valid') ||
        err.response.data.message?.toLowerCase().includes('valid')
      ) {
        return t('ERROR.VALIDATION');
      }
      if (err.response.data.message?.toLowerCase().includes('candidacy')) {
        return t('ERROR.PRIORITY');
      }
      return t('ERROR.GENERAL');
    default:
      return t('ERROR.GENERAL');
  }
};
