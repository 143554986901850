/* eslint-disable import/prefer-default-export */
const columns = [
  {
    id: 'title',
    label: 'SEASON_TITLE'
  },
  {
    id: 'startDate',
    label: 'START_DATE',
  },
  {
    id: 'endDate',
    label: 'END_DATE',
  },
  {
    id: 'rank',
    label: 'RANK',
  },
  {
    id: 'rbz',
    label: 'IS_RBZ',
  },
  {
    id: 'candidaciesAmountLimit',
    label: 'CANDIDACIES_AMOUNT_LIMIT',
  },
  {
    id: 'editAndDeleteSeason',
    label: 'EDIT_AND_DELETE'
  },
];

export default columns;
