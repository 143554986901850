// eslint-disable-next-line import/prefer-default-export
export const RankOptions = [
  { key: '', value: '' },
  { key: 'סרן', value: 'SEREN' },
  { key: 'רסן', value: 'RASAN' },
  { key: 'סאל', value: 'SAAL' },
  { key: 'רבז', value: 'RBZ' },
  { key: 'אלם', value: 'ALAM' },
  { key: 'נגדים', value: 'NAGADIM' },
];

export const RankOptionsAdmins = [
  { key: '', value: '' },
  { key: 'רסן', value: 'RASAN' },
];

export const RankOptionsMultiRank = [
  { key: '', value: '' },
  { key: 'רסן', value: 'RASAN' },
  { key: 'סרן', value: 'SEREN' },
];
