/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../common/components/button/button.component';

import './new-content-container.styles.scss';

const NewQuestion = (props) => {
  const { onSave, children } = props;

  return (
    <div className="new-content-container">
      {children}
      <div className="save-button">
        <Button
          text="SAVING"
          onClick={onSave}
        />
      </div>
    </div>
  );
};

NewQuestion.propTypes = {
  onSave: PropTypes.func.isRequired,
  children: PropTypes.shape().isRequired,
};

export default NewQuestion;
