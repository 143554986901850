/* eslint-disable max-len */
import {
  ADD_JOB,
  REMOVE_JOB,
  RESET_JOBS,
  UPDATE_CURRENT_USER_ID,
  SET_DID_SEASON_CANDIDATE_SENT,
  UPDATE_JOBS
} from './desired-jobs.types';

const initialState = {
  jobs: {}
};

const findIndexOfJob = (jobs, id) => (
  jobs ? jobs.findIndex((job) => job.id === id) : -1
);

const removeItemFromArray = (array, itemToRemove) => {
  const index = findIndexOfJob(array, itemToRemove);
  array.splice(index, 1);
  return [...array];
};

const addJob = (jobs, seasonId, newJob) => {
  const currJobs = jobs[seasonId] ? [...jobs[seasonId]] : [];
  if (!currJobs.find((job) => job.candidacyForJobId === newJob.candidacyForJobId)) {
    currJobs.push(newJob);
  }
  return currJobs;
};

const getNewJobData = (payload) => {
  const { seasonId, ...newJobData } = payload;
  return newJobData;
};

const desiredJobsReducer = (namespace) => (state = initialState, action) => {
  switch (action.type) {
    case `${namespace}/${ADD_JOB}`:
      // if (state.jobs.length >= 10) throw new Error('לא ניתן להוסיף יותר מ10 תפקידים');
      return {
        ...state,
        jobs: { ...state.jobs, [action.payload.seasonId]: addJob(state.jobs, action.payload.seasonId, getNewJobData(action.payload.jobInfo)) }
      };
    case `${namespace}/${REMOVE_JOB}`:
      return {
        ...state,
        jobs: { ...state.jobs, [action.payload.seasonId]: removeItemFromArray(state.jobs[action.payload.seasonId], action.payload.jobInfo.id) }
      };
    case `${namespace}/${RESET_JOBS}`:
      return {
        ...state,
        jobs: {}
      };
    case `${namespace}/${UPDATE_CURRENT_USER_ID}`:
      return {
        ...state,
        userId: action.payload
      };
    case `${namespace}/${SET_DID_SEASON_CANDIDATE_SENT}`:
      return {
        ...state,
        didSeasonCandidateSent: { ...state.didSeasonCandidateSent, [action.payload.seasonId]: action.payload.didSeasonCandidateSent }
      };
    case `${namespace}/${UPDATE_JOBS}`:
      return {
        ...state,
        jobs: { ...state.jobs, [action.payload.seasonId]: action.payload.jobs }
      };
    default:
      return state;
  }
};

export default desiredJobsReducer;
