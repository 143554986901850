/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';
import { PermissionType } from '@dashabim/users';
import Dialog from '../../../../../common/components/dialog/dialog.component';
import Button from '../../../../../common/components/button/button.component';
import UserAvatar from '../../../../../common/components/user-avatar/user-avatar.component';

import './edit-personal-info-dialog.styles.scss';

const EditPersonalInfoDialog = (props) => {
  const {
    user,
    permissions,
    isEditMode,
    setIsEditMode,
    sendUpdateForm,
    setUpdateForm,
    createInfoBlockOnEdit,
    editablePersonalInfo,
    updateForm,
  } = props;

  return (
    <>
      <Dialog
        isOpen={
          (permissions.includes(PermissionType.Manager)
            || permissions.includes(PermissionType.SystemAdmin)
            || permissions.includes(PermissionType.professionalGroupAdmin)
            || permissions.includes(PermissionType.unitAdmin)
            || permissions.includes(PermissionType.serenGroupAdmin)
            || permissions.includes(PermissionType.serenUnitAdmin))
          && isEditMode
        }
        fullWidth
        handleClose={() => setIsEditMode(false)}
        header={`${user?.firstName} ${user?.lastName}`}
        avatar={
          <UserAvatar small picture={user?.picture} gender={user?.gender} />
        }
        // eslint-disable-next-line react/no-children-prop
      >
        <div>
          <div className="edit-personal-info-container">
            {editablePersonalInfo.map((line) => createInfoBlockOnEdit(line))}
          </div>
          <div className="edit-personal-info-action-buttons">
            <Button
              text="SAVE"
              isSubmit
              disabled={Object.keys(updateForm).length === 0}
              onClick={() => sendUpdateForm(updateForm)}
            />
            <Button
              text="CANCEL"
              onClick={() => {
                setUpdateForm({});
                setIsEditMode(false);
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

EditPersonalInfoDialog.propTypes = {
  user: PropTypes.shape().isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  editablePersonalInfo: PropTypes.arrayOf(PropTypes.string),
  createInfoBlockOnEdit: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  sendUpdateForm: PropTypes.func.isRequired,
  setUpdateForm: PropTypes.func.isRequired,
  setIsEditMode: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  updateForm: PropTypes.any,

  // personalInfoArray: PropTypes.arrayOf(PropTypes.any),
};

export default EditPersonalInfoDialog;
