import FormData from 'form-data';
import { HttpClient } from '../http.client';
import { config } from '../config';

const fileUrl = `${config.endpoints.usersCompositor}/files`;

class FileService {
  static async uploadFile(data) {
    const {
      armyId, file, date, title, candidacyId
    } = data;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('armyId', armyId);
    formData.append('date', date);
    formData.append('title', title);
    if (candidacyId) {
      formData.append('candidacyId', candidacyId);
    }
    return HttpClient.post(`${fileUrl}`, formData);
  }

  static async downloadFile(id, armyId) {
    const response = await HttpClient.getFull(
      `${fileUrl}/${id}`,
      { armyId },
      true
    );
    const file = response.data;
    const { headers } = response;
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', decodeURIComponent(headers['file-name']));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

// eslint-disable-next-line import/prefer-default-export
export { FileService };
