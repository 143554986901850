/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import calendar from '../../../../assets/logos/calendar.svg';
import { BannerService } from '../../../services/banner.service';

import NewContentContainer from '../new-content-container/new-content-container.component';

import './new-message.styles.scss';
import TextInput from '../../../common/components/text-input/text-input.component';
import { setMessageError, setSuccessMessage } from '../../../store/events/events.actions';

const getDateString = (dateString) => {
  const date = new Date(dateString);
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
};

const NewMessage = (props) => {
  const { refetch } = props;

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [banner, setBanner] = useState();
  const [expiration, setExpiration] = useState();

  const createBanner = (async () => {
    try {
      await BannerService.create({
        message: banner,
        visibility: {
          level: 'EVERYONE',
        },
        expiration
      });
      refetch({});
      dispatch(setSuccessMessage());
      setBanner('');
      setExpiration('');
    } catch (err) {
      dispatch(setMessageError(err));
    }
  });

  const getNextDayDate = () => {
    const date = new Date();
    const nextDayDate = new Date().getDate() + 1;
    date.setDate(nextDayDate);
    return date.toISOString().split('T')[0];
  };

  return (
    <NewContentContainer onSave={createBanner}>
      <div className="new-message-header">
        <span>{t('NEW_MESSAGE')}</span>
      </div>
      <div className="message-date">
        <img src={calendar} alt="" />
        {getDateString(new Date())}
      </div>
      {/* <span>{t('MESSAGE')}</span> */}
      <textarea
        placeholder={t('TYPE_HERE')}
        className="input"
        value={banner}
        onChange={(e) => setBanner(e.target.value)}
      />
      <TextInput
        header="EXPIRATION_DATE"
        required
        boundValue={expiration}
        type="date"
        inverted
        min={getNextDayDate()}
        onChange={(e) => setExpiration(e)}
      />
    </NewContentContainer>
  );
};

NewMessage.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default NewMessage;
