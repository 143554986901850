/* eslint-disable no-unused-vars */
import { PermissionType } from '@dashabim/users';
import dashboard from '../../assets/logos/dashboard.svg';
import rbz from '../../assets/logos/rbz.svg';
import summary from '../../assets/logos/summary.svg';
import circle from '../../assets/logos/circle.svg';
import twoCircles from '../../assets/logos/two-circles.svg';
import threeRectangles from '../../assets/logos/threeRectangles.svg';
import qa from '../../assets/logos/qa.svg';
import rules from '../../assets/logos/rules.svg';
import anamTube from '../../assets/logos/amanTube.svg';
import editSeason from '../../assets/logos/edit-season.svg';
import editDashabStatus from '../../assets/logos/edit-dashab-status.svg';
import editContent from '../../assets/logos/edit-content.svg';
import editPermissions from '../../assets/logos/edit-permissions.svg';
import myOfficerRequests from '../../assets/logos/my-officer-requests.svg';
import alamRankLogo from '../../assets/logos/alam-logo.svg';
import rasarRankLogo from '../../assets/logos/rasar-logo.svg';
import { config } from '../config';

export const items = [
  {
    sidebarHeader: 'DASHBOARD',
    pageHeader: ' ',
    path: 'dashboard',
    logo: dashboard,
    isIncludeSearchBar: true,
    placeholder: 'SEARCH_PEOPLE',
  },
  {
    sidebarHeader: 'INSTATEMENT_DISCUSSION_SEREN',
    pageHeader: 'INSTATEMENT_DISCUSSION_SEREN',
    path: 'dashab-seren',
    logo: threeRectangles,
    isIncludeSearchBar: true,
    placeholder: 'SEARCH_JOB',
    namespace: 'dashabSeren',
  },
  {
    sidebarHeader: 'INSTATEMENT_DISCUSSION_CIRCLE',
    pageHeader: 'INSTATEMENT_DISCUSSION_CIRCLE',
    path: 'dashab-rasan',
    logo: circle,
    isIncludeSearchBar: true,
    placeholder: 'SEARCH_JOB',
    namespace: 'dashabRasan',
  },
  {
    sidebarHeader: 'INSTATEMENT_DISCUSSION_TWO_CIRCLES',
    pageHeader: 'INSTATEMENT_DISCUSSION_TWO_CIRCLES',
    path: 'dashab-saal',
    logo: twoCircles,
    isIncludeSearchBar: true,
    placeholder: 'SEARCH_JOB',
    namespace: 'dashabSaal',
  },
  {
    sidebarHeader: 'INSTATEMENT_DISCUSSION_ALAM',
    pageHeader: 'INSTATEMENT_DISCUSSION_ALAM',
    path: 'dashab-alam',
    logo: alamRankLogo,
    isIncludeSearchBar: true,
    placeholder: 'SEARCH_JOB',
    namespace: 'dashabAlam',
  },
  {
    sidebarHeader: 'INSTATEMENT_DISCUSSION_NAGADIM_DISTINCT',
    pageHeader: 'INSTATEMENT_DISCUSSION_NAGADIM_DISTINCT',
    path: 'dashab-nagadim',
    logo: rasarRankLogo,
    isIncludeSearchBar: true,
    placeholder: 'SEARCH_JOB',
    namespace: 'dashabNagadim',
  },
  {
    sidebarHeader: 'INSTATEMENT_DISCUSSION_RBZ',
    pageHeader: 'INSTATEMENT_DISCUSSION_RBZ',
    path: 'dashab-rav-zroie',
    logo: rbz,
    isIncludeSearchBar: true,
    placeholder: 'SEARCH_JOB',
    namespace: 'dashabRbz',
  },
  {
    sidebarHeader: 'INSTATEMENT_DISCUSSION_SEREN_MODASH',
    dropDownOptions: [
      { label: 'DASHAB_SEREN_MODASH_LABEL', path: config.dashabSranimUrl },
      {
        label: 'DASHAB_SEREN_MODASH_RESULTS_LABEL',
        path: config.dashabSranimResultsUrl,
      },
    ],
    logo: threeRectangles,
  },
  {
    sidebarHeader: 'Q&A',
    pageHeader: 'Q&A',
    path: 'questions-and-answers',
    logo: qa,
  },
  {
    sidebarHeader: 'INSTATEMENT_DISCUSSION_SUMMARY',
    pageHeader: 'INSTATEMENT_DISCUSSION_SUMMARY',
    path: 'dashab-summary',
    logo: summary,
  },
  {
    sidebarHeader: 'POLICY_AND_RULES',
    path: config.rulesAndPoliciesUrl,
    logo: rules,
    isExternalUrl: true,
  },
];

export const managerItems = (setOpenOuterUserPopup) => [
  {
    sidebarHeader: 'EDIT_AND_ADD_SEASON',
    pageHeader: 'EDIT_AND_ADD_SEASON',
    path: 'edit-season',
    logo: editSeason,
    permissions: [
      PermissionType.Manager,
      PermissionType.SystemAdmin,
      PermissionType.professionalGroupAdmin,
      PermissionType.unitAdmin,
      PermissionType.serenGroupAdmin,
      PermissionType.serenUnitAdmin,
    ],
  },
  {
    sidebarHeader: 'EDIT_DASHAB_STATUS',
    pageHeader: 'EDIT_DASHAB_STATUS',
    path: 'edit-dashab-status',
    logo: editDashabStatus,
    permissions: [
      PermissionType.Manager,
      PermissionType.SystemAdmin,
      PermissionType.professionalGroupAdmin,
      PermissionType.unitAdmin,
      PermissionType.serenGroupAdmin,
      PermissionType.serenUnitAdmin,
    ],
  },
  {
    sidebarHeader: 'EDIT_PERMISSIONS',
    pageHeader: 'EDIT_PERMISSIONS',
    path: 'edit-permissions',
    logo: editPermissions,
    permissions: [PermissionType.Manager, PermissionType.SystemAdmin],
  },
  {
    sidebarHeader: 'EDIT_CONTENT',
    path: 'edit-content',
    logo: editContent,
    permissions: [PermissionType.Manager, PermissionType.SystemAdmin],
  },
  {
    sidebarHeader: 'MY_OFFICER_REQUESTS',
    pageHeader: 'MY_OFFICER_REQUESTS',
    path: 'my-officer-requests',
    logo: myOfficerRequests,
    permissions: [
      PermissionType.Manager,
      PermissionType.SystemAdmin,
      PermissionType.Watch,
      PermissionType.professionalGroupWatch,
      PermissionType.professionalGroupAdmin,
      PermissionType.unitAdmin,
      PermissionType.serenGroupAdmin,
      PermissionType.serenUnitAdmin,
    ],
  },
  {
    sidebarHeader: 'AMAN_TUBE_GUIDE',
    path: config.anam_tube_guide,
    logo: anamTube,
    isExternalUrl: true,
    permissions: [
      PermissionType.Manager,
      PermissionType.SystemAdmin,
      PermissionType.Watch,
      PermissionType.professionalGroupWatch,
      PermissionType.professionalGroupAdmin,
      PermissionType.unitAdmin,
      PermissionType.serenGroupAdmin,
      PermissionType.serenUnitAdmin,
    ],
  },
];
