import { combineReducers } from 'redux';
import headerPropertiesReducer from './header-properties/header-properties.reducer';
import desiredJobsReducer from './desired-jobs/desired-jobs.reducer';
import filterReducer from './filter/filter.reducer';
import userReducer from './user/user.reducer';
import dashboardReducer from './dashboard/dashboard.reducer';
import eventsReducer from './events/events.reducer';

const rootReducer = combineReducers({
  headerProperties: headerPropertiesReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  events: eventsReducer,
  dashabRasan: combineReducers({
    desiredJobs: desiredJobsReducer('dashabRasan'),
    filter: filterReducer('dashabRasan')
  }),
  dashabSaal: combineReducers({
    desiredJobs: desiredJobsReducer('dashabSaal'),
    filter: filterReducer('dashabSaal')
  }),
  dashabRbz: combineReducers({
    desiredJobs: desiredJobsReducer('dashabRbz'),
    filter: filterReducer('dashabRbz')
  }),
  dashabSeren: combineReducers({
    desiredJobs: desiredJobsReducer('dashabSeren'),
    filter: filterReducer('dashabSeren')
  }),
  dashabAlam: combineReducers({
    desiredJobs: desiredJobsReducer('dashabAlam'),
    filter: filterReducer('dashabAlam')
  }),
  dashabNagadim: combineReducers({
    desiredJobs: desiredJobsReducer('dashabNagadim'),
    filter: filterReducer('dashabNagadim')
  }),
});

export default rootReducer;
