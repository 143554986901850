/* eslint-disable arrow-body-style */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import {
  ADD_JOB,
  REMOVE_JOB,
  RESET_JOBS,
  UPDATE_CURRENT_USER_ID,
  SET_DID_SEASON_CANDIDATE_SENT,
  UPDATE_JOBS,
} from './desired-jobs.types';
import { ApplyCandidatesService } from '../../services/apply-candidates.service';

export const addJobAction = (namespace, jobInfo, seasonId) => ({
  type: `${namespace}/${ADD_JOB}`,
  payload: { jobInfo, seasonId },
});

export const updateUserIdAction = (namespace, userId) => ({
  type: `${namespace}/${UPDATE_CURRENT_USER_ID}`,
  payload: userId,
});

export const removeJobAction = (namespace, jobInfo, seasonId) => ({
  type: `${namespace}/${REMOVE_JOB}`,
  payload: { jobInfo, seasonId },
});

export const updateJobs = (namespace, jobs, seasonId) => ({
  type: `${namespace}/${UPDATE_JOBS}`,
  payload: { jobs, seasonId },
});

export const resetJobsAction = (namespace) => ({
  type: `${namespace}/${RESET_JOBS}`,
  payload: {},
});

export const setDidSeasonCandidateSent = (
  namespace,
  seasonId,
  didSeasonCandidateSent
) => ({
  type: `${namespace}/${SET_DID_SEASON_CANDIDATE_SENT}`,
  payload: { didSeasonCandidateSent, seasonId },
});

const findIndexOfJobByJobId = (jobs, jobId) => {
  return jobs ? jobs.findIndex((job) => job.id === jobId) : -1;
};

export const updateUserId = (namespace, userId) => (dispatch, getState) => {
  if (getState()[namespace].desiredJobs.userId !== userId) {
    dispatch(updateUserIdAction(namespace, userId));
    dispatch(resetJobsAction(namespace));
  }
};

export const updatePriorities =
  (namespace, seasonId, priorities) => (dispatch, getState) => {
    const jobs = getState()[namespace].desiredJobs.jobs[seasonId];
    const prioritiesArray = Object.values(priorities);
    const updatedJobs = jobs.map((job) => {
      const newPriority = prioritiesArray.find(
        (updatedPriority) =>
          updatedPriority.candidacyForJobId === job.candidacyForJobId
      ).priority;
      return { ...job, priority: newPriority };
    });
    dispatch(updateJobs(namespace, updatedJobs, seasonId));
  };

export const loadJobs = (namespace, seasonId) => async (dispatch, getState) => {
  const { userId } = getState()[namespace].desiredJobs;
  if (!getState()[namespace].desiredJobs.jobs[seasonId]?.length) {
    const summarizedJobs =
      await ApplyCandidatesService.getSummerizedCandidaciesOfUser(
        userId,
        seasonId
      ).catch(() => {
        throw new Error('לא ניתן להוסיף תפקידים לאחר ההגשה');
      });
    dispatch(
      setDidSeasonCandidateSent(
        namespace,
        seasonId,
        summarizedJobs.didSeasonCandidateSent
      )
    );
    if (summarizedJobs.jobs.length) {
      summarizedJobs.jobs.forEach((job) => {
        dispatch(addJobAction(namespace, { ...job, userId }, seasonId));
      });
    }
  }
};

export const removeJob =
  (namespace, jobInfo, seasonId) => async (dispatch, getState) => {
    const { candidacyForJobId } = getState()[namespace].desiredJobs.jobs[
      seasonId
    ].find((job) => job.id === jobInfo.id);
    const { userId } = getState()[namespace].desiredJobs;
    await ApplyCandidatesService.deleteCandidacyForJob(candidacyForJobId);
    dispatch(resetJobsAction(namespace));
    const summarizedJobs =
      await ApplyCandidatesService.getSummerizedCandidaciesOfUser(
        userId,
        seasonId
      ).catch(() => {});
    if (summarizedJobs.jobs.length) {
      summarizedJobs.jobs.forEach((job) => {
        dispatch(addJobAction(namespace, { ...job, userId }, seasonId));
      });
    }
  };

export const addJob =
  (namespace, jobInfo, seasonId) => async (dispatch, getState) => {
    const { userId } = getState()[namespace].desiredJobs;
    if (
      findIndexOfJobByJobId(
        getState()[namespace].desiredJobs.jobs[seasonId],
        jobInfo.id
      ) !== -1
    ) {
      throw new Error('לא ניתן להוסיף את אותו התפקיד פעמיים');
    }
    const createdCandidact = await ApplyCandidatesService.createCandidacy(
      userId,
      jobInfo.id
    );
    dispatch(
      addJobAction(
        namespace,
        { ...jobInfo, candidacyForJobId: createdCandidact.id, userId },
        seasonId
      )
    );
  };
