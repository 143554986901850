/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import cookies from 'js-cookie';

export class HttpClient {
  static async get(url, query, isDownloadMode = false) {
    const auth = cookies.get('omek-token');
    const downloadOptions = isDownloadMode ? {
      responseType: 'blob',
      responseEncoding: 'binary',
    } : {};
    return (await axios({
      method: 'get',
      url,
      params: query,
      headers: { Authorization: `Bearer ${auth}` },
      ...downloadOptions
    })).data;
  }

  static async getFull(url, query, isDownloadMode = false) {
    const auth = cookies.get('omek-token');
    const downloadOptions = isDownloadMode ? {
      responseType: 'blob',
      responseEncoding: 'binary',
    } : {};
    return (axios({
      method: 'get',
      url,
      params: query,
      headers: { Authorization: `Bearer ${auth}` },
      ...downloadOptions
    }));
  }

  static async getExcelFile(url, query) {
    const auth = cookies.get('omek-token');
    return (await axios({
      method: 'get',
      url,
      params: query,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
      responseType: 'blob'
    })).data;
  }

  static async post(url, body) {
    const auth = cookies.get('omek-token');
    return (await axios({
      method: 'post',
      url,
      data: body,
      headers: { Authorization: `Bearer ${auth}` }
    })).data;
  }

  static async put(url, data) {
    const auth = cookies.get('omek-token');
    return (await axios({
      method: 'put',
      url,
      data,
      headers: { Authorization: `Bearer ${auth}` }
    })).data;
  }

  static async delete(url) {
    const auth = cookies.get('omek-token');
    return (await axios({
      method: 'delete',
      url,
      headers: { Authorization: `Bearer ${auth}` }
    })).data;
  }
}
