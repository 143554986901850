import React from 'react';
import PropTypes from 'prop-types';

import UserAvatar from '../../../../common/components/user-avatar/user-avatar.component';
import TextInput from '../../../../common/components/text-input/text-input.component';
import DetailsBlock from '../details-block/details-block.component';

import styles from './personal-info.module.scss';
import { changeDateFormat } from '../../../../utils';

const PersonalInfo = (props) => {
  const { user } = props;

  const getCurrentRank = (currentRankStartDate) => {
    const dateDifference = Math.floor(
      new Date().getTime() - new Date(currentRankStartDate).getTime()
    );
    const day = 1000 * 60 * 60 * 24;
    const amountOfDays = dateDifference / day;
    const years = Math.floor(amountOfDays / 365);
    const months = Math.floor((amountOfDays % 365) / 31);
    const days = Math.floor((amountOfDays % 365) % 31);
    return `${years}:${months}:${days}`;
  };
  const calculateAge = (dob) => {
    const day = 1000 * 60 * 60 * 24;
    const dateDifference = Math.floor(
      new Date().getTime() - new Date(dob).getTime()
    );
    const amountOfDays = dateDifference / day;
    const years = Math.floor(amountOfDays / 365);
    const months = Math.floor((amountOfDays % 365) / 31);

    return `${years}.${months}`;
  };
  const getCorrectOrganizationalGate = () => {
    if (['סגמ', 'סגן', 'סרן', 'סמר', 'רסל', 'קמא'].includes(user.rank)) {
      return (
        <TextInput
          additionalClassName={styles['text-input-personal-info']}
          color="white"
          boundValue={changeDateFormat(user.fieldsManager.firstOrganizationalGate) || '-'}
          header="FIRST_ORGANIZATION_GATE"
          disabled
        />
      );
    }
    return (
      <TextInput
        additionalClassName={styles['text-input-personal-info']}
        color="white"
        boundValue={user.secondOrganizationalGate || '-'}
        header="SECOND_ORGANIZATION_GATE"
        disabled
      />
    );
  };

  // SECOND_ORGANIZATION_GATE
  // REVIEW
  // CORP_RATING
  // גיל, דירוג חיילי , בולט לחיוב, שער ארגוני שני
  return (
    <DetailsBlock
      header="PERSONAL_INFO"
      additionalClassName={styles['candidate-personal-info']}
    >
      <UserAvatar gender={user.gender} picture={user.picture} />

      <TextInput
        additionalClassName={styles['text-input-personal-info']}
        color="white"
        boundValue={user.armyId || '-'}
        header="ARMY_ID"
        disabled
      />
      <TextInput
        additionalClassName={styles['text-input-personal-info']}
        color="white"
        boundValue={user.rank || '-'}
        header="RANK"
        disabled
      />
      <TextInput
        additionalClassName={styles['text-input-personal-info']}
        color="white"
        boundValue={`${user.firstName} ${user.lastName}` || '-'}
        header="FULL_NAME"
        disabled
      />
      <TextInput
        additionalClassName={styles['text-input-personal-info']}
        color="white"
        boundValue={user.unit || '-'}
        header="UNIT"
        disabled
      />
      <TextInput
        additionalClassName={styles['text-input-personal-info']}
        color="white"
        boundValue={
          (user.currentRankStartDate
            && getCurrentRank(user.currentRankStartDate))
          || '-'
        }
        header="RANK_SENIORITY"
        disabled
      />

      <TextInput
        additionalClassName={styles['text-input-personal-info']}
        color="white"
        boundValue={user.kevaSeniority || '-'}
        header="KEVA_SENIORITY"
        disabled
      />
      <TextInput
        additionalClassName={styles['text-input-personal-info']}
        color="white"
        boundValue={calculateAge(user.birthDate) || '-'}
        header="AGE"
        disabled
      />
      <TextInput
        additionalClassName={styles['text-input-personal-info']}
        color="white"
        boundValue={user.corpRating || '-'}
        header="COPR_RATING"
        disabled
      />
      <TextInput
        additionalClassName={styles['text-input-personal-info']}
        color="white"
        boundValue={user.HVD ? 'כן' : '-'}
        header="REVIEW"
        disabled
      />
      {getCorrectOrganizationalGate()}
    </DetailsBlock>
  );
};

PersonalInfo.propTypes = {
  user: PropTypes.shape().isRequired,
};

export default PersonalInfo;
