import React from 'react';
import PropTypes from 'prop-types';

import './user-option.styles.scss';

const UserOption = (props) => {
  const { user, onClick } = props;

  return (
    <button type="button" onClick={() => onClick(user)} className="user-option">
      <span>{`${user.firstName} ${user.lastName}`}</span>
      <span>{`${user.rank}`}</span>
      <span>{`${user.armyId}`}</span>
    </button>
  );
};

UserOption.propTypes = {
  user: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
};

export default UserOption;
