/* eslint-disable max-len */
/* eslint-disable no-new */
/* eslint-disable import/prefer-default-export */

export const filterField = {
  label: 'PERMISSION',
  allOptionsLabel: 'ALL_PERMISSIONS',
  propertyName: 'permissions',
  getOptions: (users) => Array.from(new Set(Array.prototype.concat.apply([], users.map((user) => user.permissions)))),
};
