import {
  SET_HEADER_TEXT,
  SET_IS_INCLUDE_SEARCH_BAR,
  SET_PLACEHOLDER,
  SET_NAMESPACE
} from './header-properties.types';

const initialState = {
  headerText: '',
  isIncludeSearchBar: false,
  placeholder: '',
  namespace: ''
};

const headerPropertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HEADER_TEXT:
      return {
        ...state,
        headerText: action.payload
      };
    case SET_IS_INCLUDE_SEARCH_BAR:
      return {
        ...state,
        isIncludeSearchBar: action.payload
      };
    case SET_PLACEHOLDER:
      return {
        ...state,
        placeholder: action.payload
      };
    case SET_NAMESPACE:
      return {
        ...state,
        namespace: action.payload
      };
    default:
      return state;
  }
};

export default headerPropertiesReducer;
