/* eslint-disable quotes */
import { HttpClient } from '../http.client';
import { config } from '../config';

const url = config.endpoints.configService;

class ConfigService {
  static getOptions(route) {
    return HttpClient.get(`${url}/${route}`);
  }

  static getAllCategories() {
    return HttpClient.get(`${url}/categories`);
  }

  static getAllProfessionalGroups() {
    return HttpClient.get(`${url}/professional-groups`);
  }

  static getAllUnits() {
    return HttpClient.get(`${url}/units`);
  }

  static getFileTypes() {
    return HttpClient.get(`${url}/file-types`);
  }

  static getAllPotentialCommitteeDecisions() {
    return HttpClient.get(`${url}/potential-committee-decisions`);
  }

  static getAllRasanTopDiscussionDecisions() {
    return HttpClient.get(`${url}/rasan-top-discussion-decisions`);
  }

  static getAllClassificationDiscussionDecisions() {
    return HttpClient.get(`${url}/classification-discussion-decisions`);
  }

  static getAllOrganizationalDisciplines() {
    return HttpClient.get(`${url}/organizational-disciplines`);
  }

  static getMovement() {
    return HttpClient.get(`${url}/movement`);
  }

  static getNewMovement() {
    return HttpClient.get(`${url}/new_movement`);
  }

  static getCommittedToManager() {
    return HttpClient.get(`${url}/committedToManager`);
  }

  static getAccessTime() {
    return HttpClient.get(`${url}/access-time`);
  }
}

export default ConfigService;
