/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../../common/components/text-input/text-input.component';
import DetailsBlock from '../details-block/details-block.component';
import styles from './array-text-inputs.module.scss';

const _ = require('lodash');

const ArrayTextInputs = (props) => {
  const {
    placeholders,
    initialValues,
    onChange,
    fieldName,
    header,
    watchMode,
    seniority,
  } = props;

  const [values, setValues] = useState(
    initialValues.map((value, i) => ({ id: i, ...value }))
  );
  const [seniorityText, setSeniorityText] = useState([values[0] ? values[0].seniority : '0:0', '0:0', '0:0']);
  useEffect(() => {
    onChange({
      [fieldName]: values.map((value) => {
        let valueToReturn = {};
        if (value.name) {
          valueToReturn = { name: value.name };
        }
        if (value.data) {
          valueToReturn = { ...valueToReturn, data: value.data };
        }
        if (value.seniority) {
          valueToReturn = { ...valueToReturn, seniority: value.seniority };
        }
        return valueToReturn;
      }),
    });
    const tempSeniority = ['0:0', '0:0', '0:0'];

    values.forEach((val, i) => {
      tempSeniority[i] = values[i] ? values[i].seniority : '0:0';
    });
    setSeniorityText(tempSeniority);
  }, [values]);

  const handleOnChange = (id, field, newValue) => {
    setValues((prevValues) => {
      if (prevValues.map((value) => value.id).includes(id)) {
        return prevValues.map((value) =>
          (value.id === id ? { ...value, [field]: newValue } : value));
      }
      return [...prevValues, { id, [field]: newValue }];
    });
  };
  const handleSeniorityChange = (i, year, month) => {
    // STOP! very muslim code ahead. me sory.
    const temp = seniorityText[i] ? seniorityText[i].split(':') : ['0', '0'];

    let updateData = '';
    updateData = month ? `${temp[0]}:${month}` : `${year}:${temp[1]}`;
    const tempSeniorityText = _.cloneDeep(seniorityText);
    tempSeniorityText[i] = updateData;
    setSeniorityText(tempSeniorityText);
    // change values:
    const newValues = _.cloneDeep(values);
    if (!newValues[i]) {
      newValues[i] = { id: i };
    }
    newValues[i].seniority = updateData;
    setValues(newValues);
  };

  return (
    <DetailsBlock
      header={header}
      additionalClassName={styles['array-text-inputs']}
    >
      {placeholders.map((placeholder, i) => (
        <div key={i} className={styles.row}>
          <div className={styles.name} style={{ flexBasis: '23%' }}>
            <TextInput
              boundValue={initialValues[i] && initialValues[i].name}
              color="white"
              disabled={watchMode}
              onChange={(value) => handleOnChange(i, 'name', value)}
              placeholder={placeholder}
            />
          </div>
          <div className={styles.data} style={{ flexBasis: '45%' }}>
            <TextInput
              boundValue={initialValues[i] && initialValues[i].data}
              additionalClassName="data"
              color="white"
              disabled={watchMode}
              onChange={(value) => handleOnChange(i, 'data', value)}
              placeholder="EXPLAIN"
            />
          </div>
          {seniority && (
            <div style={{ display: 'flex', flexFlow: 'row', flexBasis: '32%' }}>
              <TextInput
                type="number"
                seniorityText="שנים"
                additionalClassName="data"
                boundValue={
                  initialValues[i] && initialValues[i].seniority
                    ? initialValues[i].seniority.split(':')[0] || '0'
                    : '0'
                }
                color="white"
                disabled={watchMode}
                onChange={(value) => {
                  handleSeniorityChange(i, value, undefined);
                }}
                min={0}
                max={100}
                placeholder="SENIORITY"
              />

              <TextInput
                additionalClassName="data"
                seniorityText="חודשים"
                boundValue={
                  initialValues[i] && initialValues[i].seniority
                    ? initialValues[i].seniority.split(':')[1] || '0'
                    : '0'
                }
                color="white"
                type="number"
                disabled={watchMode}
                onChange={(value) => {
                  handleSeniorityChange(i, undefined, value);
                }}
                min={0}
                max={12}
                placeholder="SENIORITY"
              />
            </div>
          )}
        </div>
      ))}
    </DetailsBlock>
  );
};

ArrayTextInputs.propTypes = {
  fieldName: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  placeholders: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialValues: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  watchMode: PropTypes.bool.isRequired,
  seniority: PropTypes.bool,
};

export default ArrayTextInputs;
