import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import './summary.styles.scss';
import SummaryTable from './components/summary-table/summary-table.component';
import TableFilters from './components/table-filters/table-filters.component';
import { ApplyCandidatesService } from '../../services/apply-candidates.service';
import { endLoading, startLoading } from '../../store/events/events.actions';
import { getDefaultFilter } from './components/table-filters/filter-fields.consts';

const SummaryPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [allTableData, setAllTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filterObj, setFilterObj] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const setupPassed = (candidates) => {
    if (candidates.length === 0) return null;
    const passed = candidates.find((candidate) => candidate.didPassDashab);
    if (!passed) return null;
    return {
      name: `${passed.firstName} ${passed.lastName}`,
      rank: passed.rank,
      corp: passed.corp,
    };
  };

  const setupCandidate = (candidates) => {
    if (candidates.length === 0) return null;
    return candidates.map((candidate) => ({
      name: `${candidate.firstName} ${candidate.lastName}`,
      userId: candidate.userId,
      rank: candidate.rank,
      corp: candidate.corp,
    }));
  };

  const setupData = (data) => data.map((row) => ({
    ...row,
    agreed: setupPassed(row.candidaciesForJob),
    candidaciesForJob: setupCandidate(row.candidaciesForJob),
  }));

  const changeFilter = (newFilter) => {
    setFilterObj(newFilter);
  };

  useEffect(async () => {
    dispatch(startLoading());
    setIsLoading(true);
    const dataForTable = await ApplyCandidatesService.getDashabResult();
    const initialData = setupData(dataForTable);
    setAllTableData(initialData);
    setFilteredTableData(initialData);
    dispatch(endLoading());
    setIsLoading(false);
    setFilterObj(getDefaultFilter(initialData));
  }, []);

  const filterStatus = (dataForTable, jobStatus) => {
    switch (jobStatus) {
      case t('AGREED'):
        // eslint-disable-next-line max-len
        return dataForTable.filter((data) => !!data.agreed);
      case t('SUMMARY_CANDIDACIES'):
        return dataForTable.filter((data) => !data.agreed && data.candidaciesForJob.length !== 0);
      default:
        return dataForTable;
    }
  };

  useEffect(() => {
    const { jobStatus, ...filterObjWithoutStatus } = filterObj;
    // eslint-disable-next-line max-len
    const dataForTable = allTableData.filter((data) => Object.keys(filterObjWithoutStatus).every((key) => filterObjWithoutStatus[key] === data[key]));
    setFilteredTableData(jobStatus ? filterStatus(dataForTable, jobStatus) : dataForTable);
  }, [filterObj]);

  return (
    <div>
      {filteredTableData.length === 0 && !isLoading ? (
        <div className="not-found">{`${t('SUMMARY.NOT_FOUND')}...`}</div>
      ) : (
        <>
          <TableFilters
            tableData={allTableData}
            filterObj={filterObj}
            changeFilter={changeFilter}
          />
          <div className="table-container">
            <SummaryTable tableData={filteredTableData} />
          </div>
        </>
      )}
    </div>
  );
};
export default SummaryPage;
