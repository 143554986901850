/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import ContainerHeader from '../container-header/container-header.component';
import MessageBanner from '../message-banner/message-banner.component';
import { BannerService } from '../../../../services/banner.service';

import './message-block.styles.scss';
import { setMessageError } from '../../../../store/events/events.actions';

const MessageBlock = (props) => {
  const { armyId } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [messageBanners, setMessageBanners] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);

  useEffect(async () => {
    if (armyId) {
      try {
        const usersMessageBanners = await BannerService.getByUserArmyId(armyId, startIndex, startIndex + 20);
        const allMessages = messageBanners.concat(usersMessageBanners);
        setMessageBanners(allMessages);
        if (usersMessageBanners.length < 20) setHasMoreMessages(false);
      } catch (err) {
        dispatch(setMessageError(err));
      }
    }
  }, [armyId, startIndex]);

  const onScroll = (async () => {
    setStartIndex(startIndex + 20);
  });

  const createMessageBanners = () => (
    (messageBanners.length
      ? (
        <div id="scrollableDiv" className="message-container">
          <InfiniteScroll dataLength={messageBanners.length} next={onScroll} scrollableTarget="scrollableDiv" hasMore={hasMoreMessages}>
            {messageBanners.map((messageBanner, index) => (
              <div key={messageBanner.id}>
                <MessageBanner messageBanner={messageBanner} />
                {index !== messageBanners.length - 1 ? <Divider /> : undefined}
              </div>
            ))}
          </InfiniteScroll>
        </div>

      )
      : (
        <div className="no-messages">
          {t('NO_NEW_MESSAGES')}
        </div>
      ))
  );

  return (
    <div className="message-block">
      <ContainerHeader header="MESSAGES" />
      {createMessageBanners()}
    </div>
  );
};

MessageBlock.propTypes = {
  armyId: PropTypes.string,
};

MessageBlock.defaultProps = {
  armyId: null
};

export default MessageBlock;
