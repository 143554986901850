/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PermissionType } from '@dashabim/users';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FilterBar from './components/filter-bar/filter-bar.component';
import ContainerBox from './components/container-box/container-box.component';
import OpenableContainerBox from './components/openable-container-box/openable-container-box.component';
import DesiredJob from './components/desired-job/desired-job.component';
import Button from '../../common/components/button/button.component';
import JobsService from '../../services/jobs.service';
import { ApplyCandidatesService } from '../../services/apply-candidates.service';
import { CandidaciesOfUsersService } from '../../services/candidacies-of-users.service';
import styles from './jobs.module.scss';
import {
  loadJobs,
  updateUserId,
} from '../../store/desired-jobs/desired-jobs.actions';
import { endLoading, setMessageError, startLoading } from '../../store/events/events.actions';

const JobsPage = (props) => {
  const {
    desiredJobs,
    namespace,
    filter,
    user,
    currentUserId,
    userDetailsIfManagerApply,
    updateUserIdDispatch,
    loadJobsDispatch,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [allJobs, setAllJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    updateUserIdDispatch(userDetailsIfManagerApply?.userId || user?.id);
  }, [user, userDetailsIfManagerApply, namespace]);

  useEffect(async () => {
    try {
      dispatch(startLoading());
      setIsLoading(true);
      if (filter.season?.seasonId) {
        let wantedJobs = await JobsService.getJobsBySeasonAndCategoryAndJobTitle(filter.season.seasonId, filter.category, filter.jobTitle);
        if ((user?.permissions.includes(PermissionType.professionalGroupAdmin) || user?.permissions.includes(PermissionType.serenGroupAdmin)) && userDetailsIfManagerApply) {
          wantedJobs = wantedJobs.filter((job) => user?.displayableProfessionalGroups.includes(job.unitGroup));
        }
        if ((user?.permissions.includes(PermissionType.unitAdmin) || user?.permissions.includes(PermissionType.serenUnitAdmin)) && userDetailsIfManagerApply) {
          wantedJobs = wantedJobs.filter((job) => user?.displayableUnits.includes(job.mainUnit));
        }
        setAllJobs(wantedJobs);
      } else {
        setAllJobs([]);
      }
    } catch (e) {
      dispatch(setMessageError(e, t));
    } finally {
      dispatch(endLoading());
      setIsLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    if (filter.season?.seasonId) {
      loadJobsDispatch(filter.season.seasonId).catch(() => null);
    }
    // reset search value
    filter.jobTitle = '';
  }, [namespace, filter.season?.seasonId, currentUserId]);

  useEffect(() => {
    setFilteredJobs(allJobs);
  }, [allJobs]);

  const createJobs = () => (
    filteredJobs.map((job) => (
      <OpenableContainerBox
        job={job}
        managerMode={!!userDetailsIfManagerApply?.userId}
        header={job.jobTitle}
        key={`job-${job.id}`}
        namespace={namespace}
        currentUserRank={user.rank}
        currentUserClassificationDiscussionDecision={user.fieldsManager?.classificationsDiscussionDecision || ''}
      />
    )));

  const getUnitGroupOptions = () => {
    let groups = Array.from(new Set(allJobs.map((job) => job.unitGroup))).filter((option) => option !== undefined);
    if ((user?.permissions.includes(PermissionType.professionalGroupAdmin) || user?.permissions.includes(PermissionType.serenGroupAdmin)) && userDetailsIfManagerApply) {
      groups = groups.filter((group) => user?.displayableProfessionalGroups.includes(group));
    }
    return groups;
  };

  const onFilterUnitGroup = (unitGroup) => {
    if (unitGroup === t('ALL_UNITGROUPS')) {
      setFilteredJobs(allJobs);
    } else {
      setFilteredJobs(allJobs.filter((job) => job.unitGroup === unitGroup));
    }
  };

  const handleSubmit = async () => {
    const desiredJobsArray = desiredJobs[filter.season?.seasonId];
    // console.log(desiredJobsArray);
    const obj = {
      pathname: (desiredJobsArray?.length > 0) ? `/apply-candidacy/${namespace}${userDetailsIfManagerApply ? '/manager-submission' : ''}` : '#',
      managerSubmissionMode: userDetailsIfManagerApply ? {
        userId: userDetailsIfManagerApply.userId,
      } : null
    };

    try {
      const candidaciesOfUser = await CandidaciesOfUsersService.getByFilter({
        userId: userDetailsIfManagerApply?.userId || user?.id,
        seasonId: filter.season?.seasonId,
      });
      // TODO: add validation .
      await ApplyCandidatesService.checkExpirations(candidaciesOfUser[0].id);
      history.push(obj);
    } catch (e) {
      dispatch(setMessageError(e, t));
    }
  };

  const createDesireJobsBody = () => {
    const desiredJobsArray = desiredJobs[filter.season?.seasonId] || [];
    const submitedJobs = desiredJobsArray.filter((job) => job.priority != null);
    return (
      <div className="card-container__body--column">
        <div className="card-scroll">
          <div className={styles['job-flex-item-wrap']}>
            {
          desiredJobsArray ? desiredJobsArray.map((job) => (
            <DesiredJob
              text={`(${job.mainUnit}) - ${job.jobTitle}`}
              refId={job.id}
              key={`desiredJob-${job.id}`}
              namespace={namespace}
              didSeasonCandidateSent={job.didSeasonCandidateSent}
              isEnabled={(submitedJobs.find((j) => j.id === job.id) === undefined)}
            />
          )) : []
        }
          </div>
        </div>
        <div className={styles['job-flex-item-wrap']}>
          <Button
            additionalClassName={styles['button--large']}
            text="SEND"
            disabled={desiredJobsArray?.length === 0 || !desiredJobsArray}
            onClick={handleSubmit}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles['job-page']}>
      <div className={styles['job-page__filter-bar']}>
        <FilterBar
          namespace={namespace}
          personalName={userDetailsIfManagerApply?.name}
          unitGroupOptions={getUnitGroupOptions()}
          onFilterUnitGroup={onFilterUnitGroup}
        />
      </div>
      {(filteredJobs.length === 0 && !isLoading) ? (
        <div className={styles['positions-not-found']}>{`${t('POSITIONS.NOT_FOUND')}...`}</div>
      ) : (
        <div className={styles.positions}>
          <div id="scrollableDiv" className={styles['positions__position-list']}>
            {createJobs()}
          </div>
          <div className={styles['positions__desire-jobs-container']}>
            <ContainerBox
              managerMode={!!userDetailsIfManagerApply?.userId}
              direction="column"
              header={t('DESIRED_JOBS')}
              body={createDesireJobsBody()}
              id="desired-jobs"
            />
          </div>
        </div>
      )}
    </div>
  );
};

JobsPage.propTypes = {
  desiredJobs: PropTypes.shape().isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    permissions: PropTypes.arrayOf(),
    rank: PropTypes.string,
    displayableProfessionalGroups: PropTypes.arrayOf(PropTypes.string),
    displayableUnits: PropTypes.arrayOf(PropTypes.string),
    fieldsManager: PropTypes.shape({
      classificationsDiscussionDecision: PropTypes.string
    }),
  }).isRequired,
  filter: PropTypes.shape({
    season: PropTypes.shape({
      seasonName: PropTypes.string,
      seasonId: PropTypes.string,
    }),
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
    jobTitle: PropTypes.string.isRequired,
  }).isRequired,
  currentUserId: PropTypes.string,
  // didSeasonCandidateSent: PropTypes.shape().isRequired,
  namespace: PropTypes.string.isRequired,
  userDetailsIfManagerApply: PropTypes.shape(),
  updateUserIdDispatch: PropTypes.func.isRequired,
  loadJobsDispatch: PropTypes.func.isRequired,
};

JobsPage.defaultProps = {
  currentUserId: '',
  userDetailsIfManagerApply: {},
};

const mapStateToProps = (state, ownProps) => {
  const myState = state[ownProps.namespace];
  return {
    user: state.user,
    desiredJobs: myState.desiredJobs.jobs,
    didSeasonCandidateSent: myState.desiredJobs.didSeasonCandidateSent,
    filter: myState.filter,
    currentUserId: myState.desiredJobs.userId,
    userDetailsIfManagerApply: ownProps.location.state,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateUserIdDispatch: (userId) => dispatch(updateUserId(ownProps.namespace, userId)),
  loadJobsDispatch: (seasonId) => dispatch(loadJobs(ownProps.namespace, seasonId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobsPage);
