import React from 'react';
import PropTypes from 'prop-types';

import './container-box.styles.scss';

const ContainerBox = (props) => {
  const {
    header,
    body,
    direction,
    managerMode,
    disabled,
  } = props;

  return (
    <div className={`card-container ${disabled && 'disabled'} ${direction === 'row' ? 'card-container--row' : 'card-container--column'}
     ${managerMode ? 'card-container--manager-mode' : ''}`}
    >
      <div className={`card-container__header ${direction === 'row' ? 'card-container__header--row' : 'card-container__header--column'}`}>
        <span>{header}</span>
      </div>
      {body}
    </div>
  );
};

ContainerBox.defaultProps = {
  header: '',
  direction: 'row',
  managerMode: false,
};

ContainerBox.propTypes = {
  header: PropTypes.string,
  direction: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  body: PropTypes.any.isRequired,
  managerMode: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ContainerBox;
