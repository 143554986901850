/* eslint-disable quotes */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Button as MUIButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import styles from "./button.module.scss";
import ConfigService from '../../../services/config.service';
import { setMessageError } from '../../../store/events/events.actions';

function Button({
  isSmall = false,
  isSubmit = false,
  text = "",
  onClick = () => {},
  disabled = false,
  isHollow = false,
  isUpload = false,
  additionalClassName = "",
  tooltip = "",
  image,
  style,
}) {
  const { t } = useTranslation();
  const [fileTypes, setFileTypes] = useState('');
  const dispatch = useDispatch();

  useEffect(async () => {
    if (isUpload) {
      const configFileTypes = await ConfigService.getFileTypes().catch(
        (err) => {
          dispatch(setMessageError(err));
        }
      );
      setFileTypes(configFileTypes);
    }
  }, []);

  const getImage = () => {
    let html = t(text);
    if (image) {
      html = (
        <div className={styles["image-button-container"]}>
          <img alt="buttonImage" src={image} />
          <span>{t(text)}</span>
        </div>
      );
    }
    return html;
  };

  const getButton = () => (
    isUpload
      ? (
        <MUIButton
          variant="contained"
          component="label"
          className={cx(
            styles.button,
            {
              [styles["button-small"]]: isSmall,
              [styles["button-hollow"]]: !!isHollow,
              [styles["button-disabled"]]: disabled,
            },
            additionalClassName
          )}
        >
          <div className={styles['image-button-container']}>
            <img alt="upload" src={image} />
            <span>{t('FILE_UPLOAD')}</span>
            <input
              hidden
              accept={fileTypes}
              type="file"
              id="input"
              onChange={onClick}
            />
          </div>
        </MUIButton>
      )
      : (
        <button
          style={style}
          disabled={disabled}
          className={cx(
            styles.button,
            {
              [styles["button-small"]]: isSmall,
              [styles["button-hollow"]]: !!isHollow,
              [styles["button-disabled"]]: disabled,
            },
            additionalClassName
          )}
          type={isSubmit ? "submit" : "button"}
          onClick={onClick}
        >
          {getImage()}
        </button>
      )
  );

  return (
    !tooltip
      ? (
        getButton()
      )
      : (
        <Tooltip
          TransitionComponent={Zoom}
          title={(
            <p style={{
              fontSize: '16px',
              margin: '4px',
              lineHeight: '16px',
            }}
            >
              {tooltip}
            </p>
      )}
          arrow
        >
          {getButton()}
        </Tooltip>
      )
  );
}

Button.propTypes = {
  isSmall: PropTypes.bool,
  isSubmit: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isHollow: PropTypes.bool,
  isUpload: PropTypes.bool,
  additionalClassName: PropTypes.string,
  tooltip: PropTypes.string,
  image: PropTypes.string,
  style: PropTypes.shape(),
};

export default Button;
