/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';

import femaleAvatar from '../../../../assets/logos/female-avatar.png';
import maleAvatar from '../../../../assets/logos/male-avatar.png';

import './user-avatar.styles.scss';

const UserAvatar = (props) => {
  const { gender, picture, small } = props;
  const [photo, setPhoto] = useState();
  const [openImage, setOpenImageView] = useState(false);

  useEffect(() => {
    if (picture) {
      setPhoto(picture);
    } else if (gender) {
      setPhoto(gender === 'נקבה' ? femaleAvatar : maleAvatar);
    }
  }, [picture]);

  return (
    <div className={`user-avatar-picture ${small ? 'small-image' : ''}`}>
      <Dialog onClose={() => setOpenImageView(false)} open={openImage}>
        <img src={photo} alt="User Avatar" />
      </Dialog>
      <img src={photo} alt="User Avatar" onError={() => setPhoto(gender === 'נקבה' ? femaleAvatar : maleAvatar)} onClick={() => setOpenImageView(true)} />
    </div>
  );
};

UserAvatar.propTypes = {
  gender: PropTypes.string,
  picture: PropTypes.string,
  small: PropTypes.bool,
};

UserAvatar.defaultProps = {
  gender: 'זכר',
  picture: undefined,
  small: false
};

export default UserAvatar;
