/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import TextInput from '../../../../common/components/text-input/text-input.component';
import DetailsBlock from '../details-block/details-block.component';

import styles from './excellence-and-courses.module.scss';

const ExcellenceAndCourses = (props) => {
  const {
    candidateFileds, onChange, watchMode, header
  } = props;

  const [candidateFiledsChange, setCandidateFiledsChange] = useState({});

  const [excellenceAndAwards, setExcellenceAndAwards] = useState(
    candidateFileds.excellenceAndAwards || []
  );
  const [educationalCourse, setEducationalCourse] = useState(
    candidateFileds.educationalCourse || []
  );
  // were gonna change from now on only "name" property on excellenceAndAwards and educationalCourse fields
  // meaning, "data" will be ignored. [muslim coding bellow]

  useEffect(() => {
    onChange(candidateFiledsChange);
  }, [candidateFiledsChange]);

  useEffect(() => {
    setCandidateFiledsChange({
      ...candidateFiledsChange,
      excellenceAndAwards,
    });
  }, [excellenceAndAwards]);

  useEffect(() => {
    setCandidateFiledsChange({
      ...candidateFiledsChange,
      educationalCourse,
    });
  }, [educationalCourse]);

  const handleExcellenceChange = (val, i) => {
    const temp = _.cloneDeep(excellenceAndAwards);
    temp[i] = { name: val };
    setExcellenceAndAwards(temp);
  };

  const handleEducationalChange = (val, i) => {
    const temp = _.cloneDeep(educationalCourse);
    temp[i] = { name: val };
    setEducationalCourse(temp);
  };

  return (
    <DetailsBlock additionalClassName={styles.main} header={header}>
      <div style={{
        width: '100%', display: 'flex', flexDirection: 'column'
      }}
      >
        <TextInput
          color="white"
          boundValue={excellenceAndAwards[0]?.name || ''}
          disabled={watchMode}
          onChange={(value) => handleExcellenceChange(value, 0)}
          placeholder="EXCELLENCE_AND_AWARDS_PLACEHOLDER"
        />
        <TextInput
          color="white"
          boundValue={excellenceAndAwards[1]?.name || ''}
          disabled={watchMode}
          onChange={(value) => handleExcellenceChange(value, 1)}
          placeholder="EXCELLENCE_AND_AWARDS_PLACEHOLDER"
        />
        <TextInput
          color="white"
          boundValue={excellenceAndAwards[2]?.name || ''}
          disabled={watchMode}
          onChange={(value) => handleExcellenceChange(value, 2)}
          placeholder="EXCELLENCE_AND_AWARDS_PLACEHOLDER"
        />
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <TextInput
          color="white"
          boundValue={educationalCourse[0]?.name || ''}
          disabled={watchMode}
          onChange={(value) => handleEducationalChange(value, 0)}
          placeholder="EDUCATIONAL_COURSE_PLACEHOLDER"
        />
        <TextInput
          color="white"
          boundValue={educationalCourse[1]?.name || ''}
          disabled={watchMode}
          onChange={(value) => handleEducationalChange(value, 1)}
          placeholder="EDUCATIONAL_COURSE_PLACEHOLDER"
        />
        <TextInput
          color="white"
          boundValue={educationalCourse[2]?.name || ''}
          disabled={watchMode}
          onChange={(value) => handleEducationalChange(value, 2)}
          placeholder="EDUCATIONAL_COURSE_PLACEHOLDER"
        />
      </div>
    </DetailsBlock>
  );
};

ExcellenceAndCourses.propTypes = {
  candidateFileds: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  watchMode: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired
};

export default ExcellenceAndCourses;
