/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { PermissionType } from '@dashabim/users';
import { connect, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import EditorTable from '../../common/components/editor-table/editor-table.component';
import { UsersService } from '../../services/users.service';
import TextInput from '../../common/components/text-input/text-input.component';
import { columns, permissionsOptions } from './data-for-table';
import './edit-permissions.styles.scss';
import {
  endLoading,
  setMessageError,
  startLoading,
} from '../../store/events/events.actions';
import TableFilters from './components/table-filters/table-filters.component';
import { filterField } from './components/table-filters/filter-fields.consts';
import RowByUser from './components/row-by-user/row-by-user';
import ConfigService from '../../services/config.service';

const EditPermissionsPage = (props) => {
  const { permissions } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchFilter, setSearchFilter] = useState({});
  const [rows, setRows] = useState([]);
  const [permissionToFilter, setPermissionToFilter] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [professionalGroups, setProfessionalGroups] = useState([]);
  const [units, setUnits] = useState([]);

  useEffect(async () => {
    // init professional groups
    const professionalGroupsArray = await ConfigService.getAllProfessionalGroups().catch(
      (err) => {
        dispatch(setMessageError(err));
      }
    );
    setProfessionalGroups(() => professionalGroupsArray);
    // init units
    const unitsArray = await ConfigService.getAllUnits().catch(
      (err) => {
        dispatch(setMessageError(err));
      }
    );
    setUnits(() => unitsArray);
  }, []);

  const initUsers = async (func) => {
    // init users
    dispatch(startLoading());
    setIsLoading(true);
    await func.then((res) => {
      if (!res) throw new Error(t('ERROR.USER_NOT_FOUND'));
      const wantedUsers = res;
      setAllUsers(wantedUsers);
    }).catch((err) => {
      dispatch(setMessageError(err));
    }).finally(() => {
      dispatch(endLoading());
      setIsLoading(false);
    });
  };

  const converUsersProfessionalGroup = (users) => users.map((user) => {
    if (!(user.displayableProfessionalGroups && user.displayableProfessionalGroups.length)) {
      return {
        ...user,
        displayableProfessionalGroups: [user.displayableProfessionalGroup]
      };
    }
    return user;
  });

  useEffect(async () => {
    setFilteredUsers(converUsersProfessionalGroup(allUsers));
  }, [allUsers]);

  useEffect(async () => {
    const isSearchFilterValid = Object.keys(searchFilter).length !== 0 && searchValue !== '';
    await initUsers(
      isSearchFilterValid
        ? UsersService.getByFilter({ ...searchFilter, isOr: true })
        : UsersService.getAllNonRegular()
    );
  }, [searchFilter]);

  const searchUsers = async () => {
    setSearchFilter(
      /^-?\d+$/.test(searchValue)
        ? {
          armyId: searchValue,
        }
        : {
          firstName: searchValue,
          lastName: searchValue,
        }
    );
  };

  const updateUserInState = async (newUser) => {
    const newUsersArray = [...filteredUsers];
    newUsersArray[
      newUsersArray.findIndex((userInArray) => userInArray.id === newUser.id)
    ] = newUser;
    setFilteredUsers(newUsersArray);
    const newAllUsersArray = [...allUsers];
    newAllUsersArray[
      newAllUsersArray.findIndex((userInArray) => userInArray.id === newUser.id)
    ] = newUser;
    setAllUsers(newAllUsersArray);
  };

  useEffect(() => {
    setRows(filteredUsers.map((user) => ({
      ...user,
      fullName: `${user.firstName} ${user.lastName}`,
      permissions: (
        <RowByUser
          userOfRow={user}
          updateUserInState={updateUserInState}
          professionalGroups={professionalGroups}
          units={units}
        />)
    })));
  }, [filteredUsers]);

  const getHebrewName = (option) => {
    // eslint-disable-next-line max-len
    const wantedPermissionOption = permissionsOptions.find(
      (permissionOption) => permissionOption.key === option
    );
    return t(wantedPermissionOption ? wantedPermissionOption.value : option);
  };

  useEffect(() => {
    // reset users
    setSearchValue('');

    // filter by permission
    const usersWithPermission = allUsers.filter((user) => user.permissions.some(
      (permission) => getHebrewName(permission) === permissionToFilter
    ));
    setFilteredUsers(
      permissionToFilter === t(filterField.allOptionsLabel)
        ? allUsers
        : usersWithPermission
    );
  }, [permissionToFilter]);

  return (
    <>
      <div className="edit-permissions-page">
        <div className="head-container">
          <Grid
            container
            spacing={3}
            alignItems="center"
            justify="center"
            className="grid-contanier"
          >
            <Grid item xs={7} />
            <Grid item xs={2} className="grid-item">
              <TableFilters
                users={allUsers}
                permissionToFilter={permissionToFilter}
                changeFilter={(newPermission) => setPermissionToFilter(newPermission)}
                getHebrewName={getHebrewName}
              />
            </Grid>
            <Grid item xs={3} className="grid-item">
              <TextInput
                className="text-input"
                isSearchInput
                onSearch={() => searchUsers()}
                placeholder={t('SEARCH_PEOPLE')}
                onChange={(value) => setSearchValue(value)}
              />
            </Grid>
          </Grid>
        </div>
        {
        rows.length
          ? <EditorTable rows={rows} columns={columns} />
          : isLoading
            ? <div />
            : <div className="not-found">{`${t('PERMISSION.NOT_FOUND')}...`}</div>
      }
        {(permissions.includes(PermissionType.Manager) || permissions.includes(PermissionType.SystemAdmin)) ? <></> : (<Redirect to="/" />)}
      </div>
    </>
  );
};

EditPermissionsPage.defaultProps = {
  permissions: [],
};

EditPermissionsPage.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  permissions: state.user.permissions,
});

export default connect(mapStateToProps)(EditPermissionsPage);
