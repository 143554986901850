// eslint-disable-next-line import/prefer-default-export
export const config = {
  endpoints: {
    usersCompositor: '/api/users',
    questionsAnswers: '/api/questions-answers',
    applyCandidates: '/api/apply-candidates',
    banners: '/api/banners',
    jobsApi: '/api/jobs',
    seasonsApi: '/api/seasons',
    configService: '/api/config',
    positionCompositor: '/api/positions',
    candidaciesForJobsApi: '/api/candidacies-for-jobs',
    candidaciesOfUsersApi: '/api/candidacies-of-users',
    authenticator: '/auth/login',
    produceExcelCompositor: '/api/produce-excel',
    photosApi: '',
    hi: '/api/hi',
  },
  token: 'omek-token',
  minCandidaciesAmount: '1',
  hiBaseUrl: 'https://hi.prod.services.idf',
  mainDashboardUrl: window.location.origin.replace('hrp', 'career'),
  rulesAndPoliciesUrl: 'https://aman-wp.prod.services.idf/main-power-policy/',
  anam_tube_guide: 'https://amantube.yesodot.prod.services.idf/channels/62456605fd771ba7fff4b883',
  dashabSranimUrl: 'https://aman-sites.prod.services.idf/wordpress/forms/',
  dashabSranimResultsUrl: 'https://aman-sites.prod.services.idf/wordpress/forms/publishing-results/',
};
