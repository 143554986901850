/* eslint-disable import/prefer-default-export */

export const headers = [
  { label: 'SEASON', id: 'seasonTitle' },
  { label: 'JOB', id: 'jobTitle' },
  { label: 'UNITGROUP', id: 'unitGroup' },
  { label: 'UNIT', id: 'mainUnit' },
  { label: 'CATEGORY', id: 'categoryName' },
  { label: 'CANDIDACIES', id: 'candidaciesForJob' },
  { label: 'AGREED', id: 'agreed' },
];
