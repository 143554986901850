import { HttpClient } from '../http.client';
import { config } from '../config';

const url = config.endpoints.hi;

class HiService {
  static async getChatUrl() {
    return HttpClient.get(`${url}/chat`, null);
  }
}
export default HiService;
