import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FeedbackIcon from '@material-ui/icons/Feedback';
import PropTypes from 'prop-types';

import HiService from '../../../services/hi.service';
import './chat.styles.scss';

const Chat = (props) => {
  const { isOpen, handleClose } = props;
  const { t } = useTranslation();
  const [error, setError] = useState({ hasError: false, errorMassage: '' });
  const [chatUrl, setChatUrl] = useState(null);

  useEffect(async () => {
    if (isOpen) {
      try {
        setChatUrl(await HiService.getChatUrl());
      } catch (err) {
        setError((oldError) => ({ ...oldError, hasError: true }));
      }
    }
  }, [isOpen]);

  return (
    <>
      {isOpen ? (
        <Draggable handle=".chat-header">
          <div className="chat-container">
            <div className="chat-header">
              <p>{t('CHAT.TITLE')}</p>
              <IconButton onClick={handleClose}>
                <CloseIcon className="close-icon" />
              </IconButton>
            </div>
            {error.hasError ? (
              <div className="chat-error">
                <p>{t('CHAT.ERROR')}</p>
                <FeedbackIcon className="feedback-icon" />
              </div>
            ) : (
              <iframe title="hi-chat" src={chatUrl} />
            )}
          </div>
        </Draggable>
      ) : null}
    </>
  );
};

Chat.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Chat;
