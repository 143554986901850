import { HttpClient } from '../http.client';
import { config } from '../config';

const url = config.endpoints.candidaciesOfUsersApi;

class CandidaciesOfUsersService {
  static async getByFilter(filter) {
    return HttpClient.get(`${url}`, filter);
  }

  static async getByUserId(id) {
    return HttpClient.get(`${url}/${id}`);
  }
}

// eslint-disable-next-line import/prefer-default-export
export { CandidaciesOfUsersService };
