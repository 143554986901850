/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import DashboardPage from './dashboard/dashboard';
import ApplyCandidacyPage from './apply-candidacy/apply-candidacy';
import EditDashabStatus from './edit-dashab-status/edit-dashab-status';
import EditSeason from './edit-season/edit-season';
import EditContent from './edit-content/edit-content.page';
import MyOfficerRequests from './my-officer-requests/my-officer-requests.page';
import JobsPage from './jobs/jobs.page';
import SummaryPage from './summary/summary.page';
import QuestionsAndAnswersPage from './questions-and-answers/questions-and-answers.page';
import EditPermissions from './edit-permissions/edit-permissions';

function AppRouter() {
  return (
    <Switch>
      <Route path="/dashboard" render={() => <DashboardPage />} />
      <Route
        path="/dashab-rasan"
        render={(props) => <JobsPage namespace="dashabRasan" {...props} />}
      />
      <Route
        path="/dashab-saal"
        render={(props) => <JobsPage namespace="dashabSaal" {...props} />}
      />
      <Route
        path="/dashab-rav-zroie"
        render={(props) => <JobsPage namespace="dashabRbz" {...props} />}
      />
      <Route
        path="/dashab-seren"
        render={(props) => <JobsPage namespace="dashabSeren" {...props} />}
      />
      <Route
        path="/dashab-alam"
        render={(props) => <JobsPage namespace="dashabAlam" {...props} />}
      />
      <Route
        path="/dashab-nagadim"
        render={(props) => <JobsPage namespace="dashabNagadim" {...props} />}
      />
      <Route
        path="/apply-candidacy/:id/:mode?"
        render={(props) => <ApplyCandidacyPage {...props} />}
      />
      <Route
        path="/questions-and-answers"
        render={() => <QuestionsAndAnswersPage />}
      />
      <Route path="/dashab-summary" component={SummaryPage} />
      <Route path="/edit-dashab-status" render={() => <EditDashabStatus />} />
      <Route path="/edit-permissions" render={() => <EditPermissions />} />
      <Route path="/edit-season" render={() => <EditSeason />} />
      <Route path="/edit-content" render={() => <EditContent />} />
      <Route path="/my-officer-requests" render={() => <MyOfficerRequests />} />
      <Redirect path="/" to="/dashboard" />
    </Switch>
  );
}

export default AppRouter;
