import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { changeDateFormat } from '../../../utils';
import './editor-table.styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(3),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles();
  const {
    count, page, rowsPerPage, onChangePage
  } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const getActionButton = (onClick, disabled, ariaLabel, icon) => (
    <IconButton onClick={onClick} disabled={disabled} aria-label={ariaLabel}>
      {icon}
    </IconButton>
  );

  return (
    <div className={classes.root}>
      {getActionButton(
        handleFirstPageButtonClick,
        page === 0,
        'first page',
        <LastPageIcon />,
      )}
      {getActionButton(
        handleBackButtonClick,
        page === 0,
        'previous page',
        <KeyboardArrowRight />,
      )}
      {getActionButton(
        handleNextButtonClick,
        page >= Math.ceil(count / rowsPerPage) - 1,
        'next page',
        <KeyboardArrowLeft />,
      )}
      {getActionButton(
        handleLastPageButtonClick,
        page >= Math.ceil(count / rowsPerPage) - 1,
        'last page',
        <FirstPageIcon />,
      )}
    </div>
  );
}

const EditorTable = (props) => {
  const {
    columns, rows, pagination, onRowClick
  } = props;
  const {
    count, page, rowsPerPage, onChangePage
  } = pagination || {};
  const { t } = useTranslation();
  const emptyRows = rowsPerPage - rows.length;

  return (
    <TableContainer className="editor-table">
      <Table className="editor-table-root" aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id}>{t(column.label)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              className={`editor-table-row ${onRowClick && 'hover'}`}
              onClick={onRowClick ? () => onRowClick(index) : () => {}}
            >
              {columns.map((column) => {
                const value = row[column.id];
                return (
                  <TableCell key={column.id}>
                    {column.date && value ? changeDateFormat(value) : value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
          {pagination && emptyRows > 0 && (
            <TableRow style={{ height: 55 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          )}
        </TableBody>
        {pagination && (
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={columns.length}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={onChangePage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
};

EditorTable.defaultProps = {
  pagination: null,
  onRowClick: undefined,
};

EditorTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onRowClick: PropTypes.func,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
  }),
};

export default EditorTable;
