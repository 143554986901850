const columns = [
  {
    id: 'fullName',
    label: 'FULL_NAME'
  },
  {
    id: 'armyId',
    label: 'ARMY_ID'
  },
  {
    id: 'jobTitle',
    label: 'JOB'
  },
  {
    id: 'unit',
    label: 'UNIT'
  },
  {
    id: 'mainUnit',
    label: 'JOB_UNIT'
  },
  {
    id: 'seasonName',
    label: 'SEASON'
  },
  {
    id: 'submittedAt',
    label: 'SUBMIT_CANDIDACIES_DATE'
  },
];

export default columns;
