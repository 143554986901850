/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../../../../common/components/text-input/text-input.component';
import DetailsBlock from '../details-block/details-block.component';

import styles from './professional-experience.module.scss';

const ProfessionalExperience = (props) => {
  const {
    candidateFileds, onChange, watchMode, header
  } = props;

  const [candidateFiledsChange, setCandidateFiledsChange] = useState({});

  useEffect(() => {
    onChange(candidateFiledsChange);
  }, [candidateFiledsChange]);

  return (
    <DetailsBlock additionalClassName={styles.professional} header={header}>
      <div style={{ width: '100%' }}>
        <TextInput
          color="white"
          boundValue={candidateFileds.exp}
          disabled={watchMode}
          onChange={(value) => setCandidateFiledsChange({ ...candidateFiledsChange, exp: value })}
          placeholder="PROFESSIONAL_EXP"
          rows={5}
          maxRows={5}
        />
      </div>
      <div style={{ width: '100%', paddingTop: '1rem' }}>
        <TextInput
          color="white"
          boundValue={candidateFileds.freeText}
          disabled={watchMode}
          onChange={(value) => setCandidateFiledsChange({ ...candidateFiledsChange, freeText: value })}
          placeholder="EXPLAIN"
          rows={5}
          maxRows={5}
        />
      </div>
    </DetailsBlock>
  );
};

ProfessionalExperience.propTypes = {
  candidateFileds: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  watchMode: PropTypes.bool.isRequired,
  header: PropTypes.string
};

export default ProfessionalExperience;
