import {
  SET_HEADER_TEXT,
  SET_IS_INCLUDE_SEARCH_BAR,
  SET_PLACEHOLDER,
  SET_NAMESPACE
} from './header-properties.types';

export const setHeaderText = (headerText) => ({
  type: SET_HEADER_TEXT,
  payload: headerText
});

export const setIsIncludeSearchBar = (isIncludeSearchBar) => ({
  type: SET_IS_INCLUDE_SEARCH_BAR,
  payload: isIncludeSearchBar
});

export const setPlaceholder = (placeholder) => ({
  type: SET_PLACEHOLDER,
  payload: placeholder
});

export const setNamespace = (namespace) => ({
  type: SET_NAMESPACE,
  payload: namespace
});
