import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './select.styles.scss';

const Select = (props) => {
  const {
    header,
    options,
    selected,
    inverted,
    onChange,
    disabled,
    required,
    staticValue
  } = props;
  const { t } = useTranslation();

  const isSelected = (option) => (
    option.key === selected
  );

  return (
    <div className="select-container">
      { header && <span>{t(header)}</span> }
      <select
        disabled={disabled}
        required={required}
        className={`${inverted && 'inverted inverted-select'} ${disabled && 'disabled'}`}
        onChange={onChange}
        defaultValue={selected}
        value={disabled ? staticValue : null}
      >
        {options.map((option) => (
          <option
            key={option.key}
            label={t(option.value)}
            value={option.key}
            selected={isSelected(option)}
          />
        ))}
      </select>
    </div>
  );
};

Select.propTypes = {
  header: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  inverted: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  staticValue: PropTypes.string
};

Select.defaultProps = {
  header: '',
  inverted: false,
  required: false,
  disabled: false,
  staticValue: null
};

export default Select;
