import { HttpClient } from '../http.client';
import { config } from '../config';

const url = config.endpoints.positionCompositor;

class PositionsService {
  static async deleteSeason(id) {
    return HttpClient.delete(`${url}/delete-season/${id}`);
  }

  static async deleteJob(id) {
    return HttpClient.delete(`${url}/delete-job/${id}`);
  }
}

export default PositionsService;
