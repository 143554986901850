import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import './switch.styles.scss';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#00962A',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#00962A',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
));

const SwitchToggle = (props) => {
  const { t } = useTranslation();
  const {
    header,
    firstLabel,
    secondLabel,
    onChange,
    disabled,
    boundValue,
  } = props;

  const [value, setValue] = useState(boundValue);

  useEffect(() => {
    setValue(boundValue);
  }, [boundValue]);

  const handleChange = () => {
    setValue(!value);
    onChange(!value);
  };

  return (
    <div className="input-container">
      { header && <span className="text-input-header">{t(header)}</span>}
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item className="labels">{firstLabel}</Grid>
          <Grid item>
            <IOSSwitch disabled={disabled} checked={value} onChange={handleChange} />
          </Grid>
          <Grid item className="labels">{secondLabel}</Grid>
        </Grid>
      </Typography>
    </div>
  );
};

SwitchToggle.propTypes = {
  header: PropTypes.string,
  firstLabel: PropTypes.string,
  secondLabel: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  boundValue: PropTypes.bool,
};

SwitchToggle.defaultProps = {
  header: '',
  firstLabel: '',
  secondLabel: '',
  onChange: () => { },
  disabled: false,
  boundValue: false,
};
export default SwitchToggle;
