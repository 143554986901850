/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { PermissionType } from '@dashabim/users';
import { setDashboardSearchValue } from '../../../store/dashboard/dashboard.actions';
import TextInput from '../text-input/text-input.component';
import homePageButton from '../../../../assets/logos/home-page-button.svg';
import mashanLogo from '../../../../assets/logos/mashan-logo.svg';
import sapirLogo from '../../../../assets/logos/sapir-logo.svg';
import yesodotLogo from '../../../../assets/logos/yesodot.svg';

import './header.styles.scss';
import { setJobTitle } from '../../../store/filter/filter.actions';
import { config } from '../../../config';

const Header = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    namespace,
    isIncludeSearchBar,
    placeholder,
    setDispatchDashboardSearchValue,
    setDispatchJobTitle,
    permissions
  } = props;

  return (
    <AppBar position="fixed" color="inherit" className="app-bar">
      <Toolbar className="header" disableGutters>
        <div className="header-logo">
          <div className="logos-container">
            <img src={mashanLogo} alt="aman" />
            <img src={sapirLogo} alt="sapir" />
            <img src={yesodotLogo} className="yesodot" alt="yesodot" />
          </div>
          <h1>{t('APP_HEADER')}</h1>
        </div>
        <div className="header-inputs">
          {
            isIncludeSearchBar && permissions.some((permission) => permission !== PermissionType.Regular) && history.location.pathname === '/dashboard' ? (
              <TextInput
                initialValue=""
                color="#EBF0F0"
                isSearchInput
                onSearch={setDispatchDashboardSearchValue}
                placeholder={placeholder}
                namespace={namespace}
              />
            ) : isIncludeSearchBar && history.location.pathname !== '/dashboard' ? (
              <TextInput
                initialValue=""
                color="#EBF0F0"
                onChange={(value) => setDispatchJobTitle(namespace, value)}
                placeholder={placeholder}
                namespace={namespace}
              />
            ) : <></>
          }
          <a href={config.mainDashboardUrl}>
            <img src={homePageButton} alt="Home page" />
          </a>
        </div>
      </Toolbar>
    </AppBar>
  );
};
Header.propTypes = {
  namespace: PropTypes.string.isRequired,
  isIncludeSearchBar: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  setDispatchDashboardSearchValue: PropTypes.func.isRequired,
  setDispatchJobTitle: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  isIncludeSearchBar: state.headerProperties.isIncludeSearchBar,
  placeholder: state.headerProperties.placeholder,
  namespace: state.headerProperties.namespace,
  permissions: state.user.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  setDispatchDashboardSearchValue: (text) => dispatch(setDashboardSearchValue(text)),
  setDispatchJobTitle: (namespace, text) => dispatch(setJobTitle(namespace, text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
