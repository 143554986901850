/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Dialog from '../../dialog/dialog.component';
import Button from '../../button/button.component';
import Select from '../../select/select.component';
import TextInput from '../../text-input/text-input.component';
import { UsersService } from '../../../../services/users.service';
import './outer-user-dialog.styles.scss';
import { setMessageError } from '../../../../store/events/events.actions';
import { RankOptions } from '../../../../store/rank/rank-options';

const OuterUserDialog = (props) => {
  const {
    isOpen, setIsOpen
  } = props;
  const dispatch = useDispatch();
  const [user, setUser] = useState({});

  const changeUser = (newValue, property) => {
    setUser({ ...user, [property]: newValue });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // change
      await UsersService.addOuterUser(user);
    } catch (err) {
      dispatch(setMessageError(err));
    }
    setIsOpen(false);
    handleClose();
  };

  return (
    <Dialog isOpen={isOpen} handleClose={handleClose} header="ADD_OUTER_USERS">
      <form className="form-export-excel-by-season" onSubmit={(e) => handleSubmit(e)}>
        <div className="inputs">
          <TextInput required onChange={(value) => changeUser(value, 'name')} boundValue="" inverted header="FULL_NAME" />
          <TextInput required onChange={(value) => changeUser(value, 'armyId')} boundValue="" inverted header="ARMY_ID" />
          <Select required onChange={(e) => changeUser(e.target.value, 'rank')} inverted header="RANK" selected="" options={RankOptions} />
          <TextInput required onChange={(value) => changeUser(value, 'corp')} boundValue="" inverted header="CORP" />
        </div>
        <div className="buttons">
          <Button text="APPROVE" isSubmit />
        </div>
      </form>
    </Dialog>
  );
};

OuterUserDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default OuterUserDialog;
