/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import TextInput from '../../../../common/components/text-input/text-input.component';
import CheckboxList from '../checkbox-list/checkbox-list.component';
import './displayable-list.styles.scss';
import SnackbarSuccess from '../../../../common/components/snackbar-success/snackbar-success.component';

const DisplayableList = (props) => {
  const {
    title,
    searchTitle,
    withSearch,
    options,
    disabled,
    checked,
    onChange,
    isRadioMode
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const getKeyAndVal = (arr) => arr.map((item) => (typeof item === 'string' ? ({ key: item, value: item }) : item));
  const filterSearch = (arr) => arr.filter((item) => (item.includes(searchValue)));
  const debounceSearch = (arr, numOfElements = 50) => (searchValue.length >= 2 ? filterSearch(arr) : arr.slice(0, numOfElements));

  const handleChange = async (e) => {
    await onChange(e);
    setOpenSnackbar(true);
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 2500);
  };

  const getContent = () => (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid item xs={4}>
        <span className="title">{title}</span>
      </Grid>
      {withSearch && (
      <Grid item xs={8}>
        <TextInput
          className="text-input"
          isSearchInput
          placeholder={searchTitle}
          onChange={(value) => setSearchValue(value)}
        />
      </Grid>
      )}
      <Grid item xs={12}>
        <CheckboxList
          options={getKeyAndVal(debounceSearch(options))}
          disabled={disabled}
          checkedPermissions={checked}
          onChange={handleChange}
          isRadioMode={isRadioMode}
          isMaxHeight
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      {getContent()}
      {openSnackbar && <SnackbarSuccess textSuccess="SUCCESS.ACTION" />}
    </>
  );
};

CheckboxList.defaultProps = {
  withSearch: false,
  isRadioMode: false,
};

DisplayableList.propTypes = {
  title: PropTypes.string.isRequired,
  searchTitle: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabled: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  checked: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  withSearch: PropTypes.bool,
  isRadioMode: PropTypes.bool,
};

export default DisplayableList;
