/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addJob } from '../../../../store/desired-jobs/desired-jobs.actions';
import { changeDateFormat } from '../../../../utils';
import Button from '../../../../common/components/button/button.component';
import './job-info.styles.scss';
import {
  endLoading,
  setMessageError,
  startLoading,
} from '../../../../store/events/events.actions';
import SnackbarError from '../../../../common/components/snackbar-error/snackbar-error.component';

const JobInfo = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    job,
    addJobDispatch,
    seasonId,
    userId,
    disabled,
    currentUserRank,
    currentUserClassificationDiscussionDecision,
  } = props;

  const [snackbarText, setSnackbarText] = React.useState(null);
  useEffect(() => {
    if (snackbarText) {
      setTimeout(() => {
        setSnackbarText(null);
      }, 2000);
    }
  }, [snackbarText]);

  const statusOptions = [
    { key: 'openToApply', value: 'OPEN_TO_APPLY' },
    {
      key: 'closeToApply',
      value: 'CLOSE_TO_APPLY',
      secondaryValue: 'CLOSE_FOR_APPLY',
    },
    { key: 'closeTagabPassed', value: 'CLOSE_TAGAB_PASSED' },
    { key: 'closeManningWasAgreed', value: 'CLOSE_MANNING_WAS_AGREED' },
    { key: 'closeBunchWasAgreed', value: 'CLOSE_BUNCH_WAS_AGREED' },
    { key: 'distribution', value: 'DISTRIBUTION' },
  ];

  const classificationDiscussionDecisionUnapproved = [
    'לא מאושר להתמודדות',
    'יבחן להתמודדות בעונה הבאה',
  ];

  const handleJobClick = async () => {
    if (
      currentUserRank !== 'רסן'
      || !classificationDiscussionDecisionUnapproved.includes(
        currentUserClassificationDiscussionDecision
      )
    ) {
      dispatch(startLoading());
      try {
        await addJobDispatch(
          { jobTitle: job.jobTitle, id: job.id, mainUnit: job.mainUnit },
          seasonId,
          userId
        );
      } catch (err) {
        dispatch(setMessageError(err));
      } finally {
        dispatch(endLoading());
      }
    } else {
      setSnackbarText('לטובת הגשת מועמדות אנא פנה אל סגלי המשא"ן ביחידתך');
    }
  };

  const createJobInfoTextSection = () => (
    <div className="job-info-text">
      {job.isOptional ? <p>{`${t('JOB')} ${job.isOptional}`}</p> : ''}
      <p>
        <span>{`${t('APPROVER')}: ${job.currentOccupant}`}</span>
        <span className="tooltip-text">{job.currentOccupant}</span>
      </p>
      <p>{`${t('FINAL_APPLICATION_DATE')}:`}</p>
      <p>{`${changeDateFormat(job.finalApplicationDate)}`}</p>
      <p>{`${t('BUNCH_DATE')}:`}</p>
      <p>{`${changeDateFormat(job.bunchDate)}`}</p>
      <p>{`${t('DASHAB_DATE')}:`}</p>
      <p>{`${changeDateFormat(job.dashabDate)}`}</p>
      {!!job.dateOfApproval && (
        <>
          <p>{`${t('DATE_OF_APPROVAL')}:`}</p>
          <p>{`${job.dateOfApproval}`}</p>
        </>
      )}
    </div>
  );

  const getButtonText = () => {
    const statusOption = statusOptions.find(
      (option) => option.key === job.jobStatus
    );
    return t(statusOption.secondaryValue || statusOption.value);
  };

  return (
    <div className="job-info-card">
      <SnackbarError textError={snackbarText} />
      {createJobInfoTextSection()}
      <Button
        text={getButtonText()}
        disabled={disabled}
        onClick={handleJobClick}
      />
    </div>
  );
};

JobInfo.defaultProps = {
  userId: null,
};

JobInfo.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.string,
    jobTitle: PropTypes.string,
    jobStatus: PropTypes.string,
    isOptional: PropTypes.string,
    currentOccupant: PropTypes.string,
    finalApplicationDate: PropTypes.string,
    bunchDate: PropTypes.shape(),
    dashabDate: PropTypes.shape(),
    dateOfApproval: PropTypes.string,
    mainUnit: PropTypes.string,
  }).isRequired,
  addJobDispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  seasonId: PropTypes.string.isRequired,
  userId: PropTypes.string,
  currentUserRank: PropTypes.string,
  currentUserClassificationDiscussionDecision: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const myState = state[ownProps.namespace];
  return {
    seasonId: myState.filter.season?.seasonId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addJobDispatch: (job, seasonId, userId) => dispatch(addJob(ownProps.namespace, job, seasonId, userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobInfo);
