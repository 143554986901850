/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';

import AppPage from './pages/app.page';
import ErrorPage from './pages/error-page/error.page';
import './App.scss';

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <div className="App">
          <Switch>
            <Route path="/error" render={() => <ErrorPage />} />
            <Route path="/" render={() => <AppPage />} />
          </Switch>
        </div>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
