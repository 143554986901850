/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UsersService } from '../../services/users.service';
import JobsService from '../../services/jobs.service';
import { CandidaciesOfUsersService } from '../../services/candidacies-of-users.service';
import {
  setHeaderText,
  setIsIncludeSearchBar,
} from '../../store/header-properties/header-properties.actions';
import PersonalInfo from './components/personal-info/personal-info.component';
import Education from './components/education/education.component';
import ProfessionalExperience from './components/professional-experience/professional-experience.component';
import EducationalCourse from './components/educational-course/educational-course.component';
import ExcellenceAndCourses from './components/excellence-and-courses/excellence-and-courses.component';
import PreviousJobs from './components/previous-jobs/previous-jobs.component';
import Priority from './components/priority/priority.component';
import Button from '../../common/components/button/button.component';
import { ApplyCandidatesService } from '../../services/apply-candidates.service';
import DetailsBlock from './components/details-block/details-block.component';

// import SubmitPopup from './components/submit-popup/submit-popup.component';
import styles from './apply-candidacy.module.scss';
import {
  endLoading,
  setMessageError,
  setSuccessMessage,
  startLoading,
} from '../../store/events/events.actions';
import { login } from '../../store/user/user.actions';
import { updatePriorities } from '../../store/desired-jobs/desired-jobs.actions';

const defaultCandidateFileds = {
  firstDegree: '',
  secondDegree: '',
  freeText: '',
  exp: '',
  seniority: '',
  educationalCourse: [],
  excellenceAndAwards: [],
  previousJobs: [],
};

const ApplyCandidacyPage = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    jobs,
    setDispatchIsIncludeSearchBar,
    setDispatchHeaderText,
    watchMode,
    watchData,
    userId,
    seasonId,
    userFromToken,
    setDispatchUser,
    updatePrioritiesDispatch,
  } = props;
  const [user, setUser] = useState(null);
  const [candidateFileds, setCandidateFileds] = useState({});
  const [priorities, setPriorities] = useState({});
  const [watchPriorities, setWatchPriorities] = useState([]);
  const [isWatchMode, setIsWatchMode] = useState(watchMode);
  const [secondPriority, setSecondPriority] = useState(null);
  // const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    setDispatchIsIncludeSearchBar(false);
    setDispatchHeaderText('APPLY_CANDIDACY');
  }, []);

  useEffect(async () => {
    if (userFromToken) {
      setUser(userFromToken);
    } else if (watchMode && watchData && Object.keys(watchData)) {
      const data = await UsersService.getById(watchData.userId);
      setUser(data);
    } else {
      const data = await UsersService.getById(userId);
      setUser(data);
    }
  }, [userId, watchData]);

  useEffect(async () => {
    if (watchMode && watchData && Object.keys(watchData)) {
      try {
        const jobData = await JobsService.getById(watchData.jobId);
        const candidacies = await ApplyCandidatesService.getSummerizedCandidaciesOfUser(
          watchData.userId,
          jobData.seasonId
        );
        setWatchPriorities([...candidacies.jobs]);
        // console.log('watchData', watchData);
        // console.log('getSecondPriority userId', watchData.userId);

        const cads = await ApplyCandidatesService.getCandidaciesOfUsers(
          watchData.userId
        );
        // find the correct season:
        const candidacy = cads.filter(
          (cad) => cad.seasonId === jobData.seasonId
        )[0];
        // console.log('cad', candidacy);
        setSecondPriority(
          candidacy.secondPriority ? candidacy.secondPriority : null
        );
        // console.log('secondPriority secondPriority', secondPriority);
      } catch (err) {
        // console.log(err.message);
        dispatch(setMessageError(err));
      }
    }
  }, [watchData]);

  // eslint-disable-next-line consistent-return
  const handleSave = async () => {
    try {
      let id = userId;
      let sId = seasonId;
      if (watchData) {
        sId = watchData.seasonId;
        id = watchData.userId;
      }
      const updatedUser = await UsersService.updateById(id, {
        candidateFileds,
      });
      if (userFromToken) {
        setDispatchUser(updatedUser);
      }
      const candidaciesOfUser = await CandidaciesOfUsersService.getByFilter({
        userId: id,
        seasonId: sId,
      });
      await Promise.all([
        Object.keys(priorities).length
          ? ApplyCandidatesService.updatePriorities(
            candidaciesOfUser[0].id,
            priorities
          )
          : null,
      ]);
      if (!watchMode) {
        updatePrioritiesDispatch(sId, priorities);
      }
      dispatch(setSuccessMessage());
      return candidaciesOfUser[0].id;
    } catch (err) {
      console.log(err);
      dispatch(setMessageError(err));
    }
  };

  const getSecondPriority = () => (
    <DetailsBlock
      header="SECOND_PRIORITY"
      additionalClassName={styles.priority}
    >
      <p style={{ fontSize: '1.6rem', marginTop: '0.5em' }}>
        {secondPriority}
      </p>
    </DetailsBlock>
  );

  const handleIsWatchMode = () => {
    if (!isWatchMode) {
      handleSave();
    }
    setIsWatchMode((prev) => !prev);
  };

  const handleSend = async () => {
    try {
      dispatch(startLoading());
      const id = await handleSave();
      await ApplyCandidatesService.submit(id);
      dispatch(setSuccessMessage());
      window.location = window.origin;
    } catch (err) {
      dispatch(setMessageError(err));
    } finally {
      dispatch(endLoading());
    }
  };

  const canSend = () => {
    if (
      Object.keys(priorities).length === 0
      || Object.keys(priorities).length !== jobs[seasonId].length
    ) {
      dispatch(setMessageError(t('ERROR.PRIORITY')));
    } else {
      handleSend();
    }
  };

  return (
    <div>
      <div className={styles['apply-candidacy-page']}>
        {user && (
          <>
            {watchMode && (
              <Button
                text={isWatchMode ? 'EDIT' : 'FINISH_AND_SAVE'}
                isSmall
                onClick={() => handleIsWatchMode()}
              />
            )}

            <div className={styles['candidacy-details-container']}>
              <PersonalInfo user={user} />
              <div className={styles.info}>
                <div className={styles.section}>
                  <PreviousJobs
                    candidateFileds={
                      user.candidateFileds || defaultCandidateFileds
                    }
                    watchMode={isWatchMode}
                    onChange={(value) => setCandidateFileds({ ...candidateFileds, ...value })}
                  />
                  <div style={{ paddingTop: '0.9rem' }}>
                    <ExcellenceAndCourses
                      header="EXCELLENCE_AND_COURSE"
                      candidateFileds={
                        user.candidateFileds || defaultCandidateFileds
                      }
                      watchMode={isWatchMode}
                      onChange={(value) => setCandidateFileds({ ...candidateFileds, ...value })}
                    />
                  </div>
                </div>
                <div className={styles.section}>
                  <Education
                    candidateFileds={
                      user.candidateFileds || defaultCandidateFileds
                    }
                    watchMode={isWatchMode}
                    onChange={(value) => setCandidateFileds({ ...candidateFileds, ...value })}
                  />
                  <ProfessionalExperience
                    header="PROFESSIONAL_EXP_AND_FREETEXT"
                    candidateFileds={
                      user.candidateFileds || defaultCandidateFileds
                    }
                    watchMode={isWatchMode}
                    onChange={(value) => setCandidateFileds({ ...candidateFileds, ...value })}
                  />
                </div>
              </div>
              <p
                style={{ fontSize: '1.6rem', marginTop: '0.5em', color: 'red' }}
              >
                {!isWatchMode
                  && 'לתשומת ליבך, שקף המועמד יוצג בדיוני השיבוצים בראשות המפקד המאשר בהתאם לדרגת התקן. יש למלא את השקף בכובד ראש. (תקן סרן - מפקד בדרגת סא"ל ואל"ם, דרגת רס"ן - מפקד בדרגת אל"ם ותא"ל, דרגת סא"ל - ר\' אמ"ן וקמנ"ר). כמו כן, ידוע לי כי עליי לעדכן את מפקדיי טרם הגשת מועמדות זו וכי במידה ואסוכם אחד מן התפקידים אליהם אני מגיש/ה את מועמודתי ייתכן ואדרש להאריך את משך שירותי, במידה ואין לך כוונות להאריך שירות כפי שכתוב, יש להגיש את המועמדות דרך סגלי המשא"ן ביחידתך'}
              </p>
              <Priority
                jobs={jobs[seasonId]}
                watchMode={watchMode}
                watchData={watchPriorities.sort(
                  (jobA, jobB) => jobA.priority - jobB.priority
                )}
                onChange={(value) => setPriorities(() => ({ ...value }))}
              />
            </div>
          </>
        )}
        {isWatchMode && secondPriority && getSecondPriority()}
        {!watchMode && (
          <div className={styles.buttons}>
            <Button text="SAVE" onClick={() => handleSave()} />
            <Button
              text="SEND_CANDIDACY"
              disabled={
                Object.keys(priorities).length === 0
                || Object.keys(priorities).length !== jobs[seasonId].length
              }
              onClick={() => canSend()}
            />
          </div>
        )}
        {!Object.keys(jobs).length && (!watchMode || !watchData) && (
          <Redirect to="/" />
        )}
      </div>
    </div>
  );
};

ApplyCandidacyPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  jobs: PropTypes.any,
  watchData: PropTypes.shape({
    jobId: PropTypes.string,
    userId: PropTypes.string,
    armyId: PropTypes.string,
    seasonId: PropTypes.string,
  }),
  watchMode: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  userFromToken: PropTypes.shape(),
  seasonId: PropTypes.string.isRequired,
  setDispatchIsIncludeSearchBar: PropTypes.func.isRequired,
  setDispatchHeaderText: PropTypes.func.isRequired,
  setDispatchUser: PropTypes.func.isRequired,
  updatePrioritiesDispatch: PropTypes.func.isRequired,
};

ApplyCandidacyPage.defaultProps = {
  jobs: {},
  watchData: null,
  watchMode: false,
  userFromToken: null,
};

const mapStateToProps = (state, ownProps) => {
  const myState = state[ownProps.match.params.id];
  if (ownProps.match.params.id === 'watch') {
    return {
      watchData: ownProps.location.watchMode,
      watchMode: true,
    };
  }
  if (ownProps.match.params.mode === 'manager-submission') {
    return {
      userId: ownProps.location.managerSubmissionMode?.userId,
      managerSubmissionMode: true,
      jobs: myState.desiredJobs.jobs,
      seasonId: myState.filter.season.seasonId,
    };
  }
  return {
    jobs: myState.desiredJobs.jobs,
    seasonId: myState.filter.season.seasonId,
    userId: state.user.id,
    armyId: state.user.armyId,
    userFromToken: state.user,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setDispatchIsIncludeSearchBar: (text) => dispatch(setIsIncludeSearchBar(text)),
  setDispatchHeaderText: (text) => dispatch(setHeaderText(text)),
  setDispatchUser: (user) => dispatch(login(user)),
  updatePrioritiesDispatch: (seasonId, priorities) => dispatch(updatePriorities(ownProps.match.params.id, seasonId, priorities)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplyCandidacyPage);
