// eslint-disable-next-line import/prefer-default-export
export const columns = [
  {
    id: 'fullName',
    label: 'FULL_NAME',
  },
  {
    id: 'armyId',
    label: 'ARMY_ID',
  },
  {
    id: 'unit',
    label: 'UNIT',
  },
  {
    id: 'rank',
    label: 'RANK',
  },
  {
    id: 'permissions',
    label: 'PERMISSIONS',
  },
];

export const permissionsOptions = [
  { key: 'regular', value: 'REGULAR', isUnique: false },
  { key: 'receptionist', value: 'RECEPTIONIST', isUnique: false },
  { key: 'manager', value: 'MANAGER', isUnique: false },
  { key: 'watch', value: 'WATCH', isUnique: true },
  {
    key: 'professional-group-watch',
    value: 'PROFESSIONAL_GROUP_WATCH',
    isUnique: true,
  },
  {
    key: 'unit-admin',
    value: 'UNIT_ADMIN',
    isUnique: true,
  },
  {
    key: 'professional-group-admin',
    value: 'PROFESSIONAL_GROUP_ADMIN',
    isUnique: true,
  },
  {
    key: 'seren-group-admin',
    value: 'SEREN_GROUP_ADMIN',
    isUnique: true,
  },
  {
    key: 'seren-unit-admin',
    value: 'SEREN_UNIT_ADMIN',
    isUnique: true,
  },
];
