/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PermissionType } from '@dashabim/users';
import './dashboard.styles.scss';
import PersonalInfo from './components/personal-info/personal-info.component';
import MessageBlock from './components/message-block/message-block.component';
import FilesBlock from './components/files-block/files-block.component';
import CandidaciesStateBlock from './components/candidacies-state-block/candidacies-state-block.component';
import { UsersService } from '../../services/users.service';
import { setDashboardSearchValue } from '../../store/dashboard/dashboard.actions';
import { createQueryFromSearchingUser } from '../../utils';
import SelectUserPopup from '../../common/components/find-user-popup/select-user-popup.component';
import {
  endLoading,
  setMessageError,
  startLoading,
} from '../../store/events/events.actions';

const DashboardPage = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [user, setUser] = useState(null);
  const [usersOptions, setUsersOptions] = useState([]);
  const [isSelectUserDialogOpen, setIsSelectUserDialogOpen] = useState(false);

  const {
    dashboardSearchValue,
    setDispatchDashboardSearchValue,
    reduxUser,
    displayableProfessionalGroups,
    displayableUnits,
    permissions,
  } = props;

  useEffect(() => {
    UsersService.postVisit(); // record a new visit
  }, []);
  useEffect(() => {
    setUser(reduxUser);
  }, [reduxUser]);

  const cleanSearchValue = () => {
    setDispatchDashboardSearchValue('');
  };

  const selectUserByDialog = (users) => {
    setUsersOptions(users);
    setIsSelectUserDialogOpen(true);
  };

  const handleSelectedUser = (selectedUser) => {
    setUser(selectedUser);
    cleanSearchValue();
  };

  const getPermissionFilter = () => {
    let filter = {};
    if (
      permissions.includes(PermissionType.professionalGroupAdmin)
      || permissions.includes(PermissionType.professionalGroupWatch)
      || permissions.includes(PermissionType.serenGroupAdmin)
    ) {
      filter = { professionalGroups: displayableProfessionalGroups };
    } else if (
      permissions.includes(PermissionType.unitAdmin)
      || permissions.includes(PermissionType.serenUnitAdmin)
    ) {
      filter = { units: displayableUnits };
    } else if (permissions.includes(PermissionType.Watch)) {
      filter = { units: user.unit };
    }
    return filter;
  };

  const validateDisplayable = () => {
    if (
      (permissions.includes(PermissionType.professionalGroupAdmin)
      || permissions.includes(PermissionType.professionalGroupWatch)
      || permissions.includes(PermissionType.serenGroupAdmin))
        && !displayableProfessionalGroups
    ) {
      // is an professionalGroup user but has no displayableProfessionalGroups
      // this is a problem.
      throw new Error(t('ERROR.NO_DISPLAYABLE'));
    }
    if ((permissions.includes(PermissionType.unitAdmin) || permissions.includes(PermissionType.serenUnitAdmin)) && !displayableUnits) {
      // is an unitAdmin user but has no displayableUnits
      // this is a problem.
      throw new Error(t('ERROR.NO_DISPLAYABLE'));
    }
  };

  useEffect(async () => {
    if (dashboardSearchValue && permissions.length) {
      try {
        dispatch(startLoading());
        validateDisplayable();
        const query = createQueryFromSearchingUser(
          dashboardSearchValue,
          getPermissionFilter(),
        );
        if (
          permissions.includes(PermissionType.Manager)
          || permissions.includes(PermissionType.SystemAdmin)
        ) {
          let searchedUser;
          if (/^[0-9]*$/.test(dashboardSearchValue)) {
            searchedUser = await UsersService.createAndGet(
              dashboardSearchValue,
            );
          }
          if (searchedUser) {
            setUser(searchedUser);
          }
        }

        const users = await UsersService.getByFilter(query);
        if (!users?.length) {
          throw new Error(t('ERROR.USER_NOT_FOUND'));
        }
        if (users.length === 1) {
          setUser(users[0]);
        } else {
          selectUserByDialog(users, user.armyId);
        }
      } catch (e) {
        dispatch(setMessageError(e, t));
        cleanSearchValue();
      } finally {
        dispatch(endLoading());
      }
    }
  }, [dashboardSearchValue, permissions]);

  return (
    <div className="dashboard-page">
      <PersonalInfo user={user} updateUser={setUser} />
      <MessageBlock armyId={reduxUser?.armyId} />
      <CandidaciesStateBlock user={user} />
      <FilesBlock user={user} />
      <SelectUserPopup
        isOpen={isSelectUserDialogOpen}
        setIsOpen={setIsSelectUserDialogOpen}
        setSelectedUser={(selectedUser) => handleSelectedUser(selectedUser)}
        users={usersOptions}
        onPopupCancel={cleanSearchValue}
      />
    </div>
  );
};

DashboardPage.defaultProps = {
  reduxUser: null,
  permissions: [],
};

DashboardPage.propTypes = {
  dashboardSearchValue: PropTypes.string.isRequired,
  setDispatchDashboardSearchValue: PropTypes.func.isRequired,
  displayableProfessionalGroups: PropTypes.arrayOf(PropTypes.string),
  displayableUnits: PropTypes.arrayOf(PropTypes.string),
  reduxUser: PropTypes.shape(),
  permissions: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  dashboardSearchValue: state.dashboard.dashboardSearchValue,
  reduxUser: state.user,
  displayableProfessionalGroups: state.user.displayableProfessionalGroups,
  displayableUnits: state.user.displayableUnits,
  permissions: state.user.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  setDispatchDashboardSearchValue: (text) => dispatch(setDashboardSearchValue(text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
