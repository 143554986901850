/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as fileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import Dialog from '../dialog/dialog.component';
import Button from '../button/button.component';
import Select from '../select/select.component';
import SeasonsService from '../../../services/seasons.service';
import { produceExcelService } from '../../../services/produce-excel.service';
import './export-by-season-dialog.styles.scss';
import { setMessageError } from '../../../store/events/events.actions';
import {
  RankOptions,
  RankOptionsAdmins,
  RankOptionsMultiRank,
} from '../../../store/rank/rank-options';

const ExportBySeasonDialog = (props) => {
  const { t } = useTranslation();
  const {
    isOpen, setIsOpen, units, professionalGroups, isMultiRank
  } = props;
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [filter, setFilter] = useState({});

  const handleClose = () => {
    setIsOpen(false);
  };

  const calcFilter = () => {
    if (units) {
      setFilter({ ...filter, units });
    }
    if (professionalGroups) {
      setFilter({ ...filter, unitGroups: professionalGroups });
    }
  };

  const getRankoptions = () => {
    if (isMultiRank) {
      return RankOptionsMultiRank;
    }
    if (professionalGroups || units) {
      return RankOptionsAdmins;
    }
    return RankOptions;
  };

  useEffect(async () => {
    calcFilter();
  }, []);

  useEffect(async () => {
    try {
      let seasons = [];
      if (form.rank) {
        let seasonQuery = {};
        if (form.rank !== 'רבז') {
          seasonQuery = { rank: [form.rank] };
        } else {
          seasonQuery = { rbz: true };
        }
        seasons = await SeasonsService.getSeasonsByFilter(seasonQuery);
      }
      const updatedSeasonOptions = [{ key: '', value: '' }].concat(
        seasons.map((season) => ({ key: season.id, value: season.title }))
      );
      setSeasonOptions(updatedSeasonOptions);
    } catch (e) {
      dispatch(e, t);
    }
  }, [form]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const resData = await produceExcelService.getDashabResults({
        seasonId: form.season,
        ...filter,
      });
      fileSaver.saveAs(
        resData,
        `${
          seasonOptions.find((season) => season.key === form.season).value
        }.xlsx`
      );
    } catch (err) {
      dispatch(setMessageError(err));
    }
    setIsOpen(false);
  };

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      header="EXPORT_EXCEL_BY_SEASON"
    >
      <form
        className="form-export-excel-by-season"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="inputs">
          <Select
            required
            onChange={(e) => setForm({ ...form, rank: e.target.value })}
            inverted
            header="RANK"
            selected={form.rank || ''}
            options={getRankoptions()}
          />
          <Select
            required
            disabled={!form.rank}
            onChange={(e) => setForm({ ...form, season: e.target.value })}
            inverted
            header="SEASON"
            selected={form.season || ''}
            options={seasonOptions}
          />
        </div>
        <div className="buttons">
          <Button text="EXPORT" isSubmit />
        </div>
      </form>
    </Dialog>
  );
};

ExportBySeasonDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  units: PropTypes.string,
  professionalGroups: PropTypes.arrayOf(PropTypes.string),
  isMultiRank: PropTypes.bool,
};

export default ExportBySeasonDialog;
