/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PermissionType } from '@dashabim/users';
import { Grid } from '@material-ui/core';
import { FileService } from '../../../../services/file.service';
import ContainerHeader from '../container-header/container-header.component';
import { setMessageError } from '../../../../store/events/events.actions';
import Button from '../../../../common/components/button/button.component';
import { FILE_TYPES } from './file-types';
import FilePopup from '../file-popup/file-popup.component';
import './files-block.styles.scss';
import uploadIcon from "../../../../../assets/logos/upload.svg";
import downloadIcon from "../../../../../assets/logos/download.svg";
import wordIcon from "../../../../../assets/logos/word.svg";
import pdfIcon from "../../../../../assets/logos/pdf.svg";

const FilesBlock = (props) => {
  const { user, permissions } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [userToRender, setUserToRender] = useState();
  const [isFilePopupOpen, setIsFilePopupOpen] = useState(false);
  const canUpload = (permissions.includes(PermissionType.Manager) ||
  permissions.includes(PermissionType.SystemAdmin) ||
  permissions.includes(PermissionType.professionalGroupAdmin) ||
  permissions.includes(PermissionType.unitAdmin) ||
  permissions.includes(PermissionType.serenGroupAdmin) ||
  permissions.includes(PermissionType.serenUnitAdmin) ||
  permissions.includes(PermissionType.Watch) ||
  permissions.includes(PermissionType.professionalGroupWatch));

  useEffect(() => {
    setUserToRender(user);
  }, [user]);

  const downloadFile = async (id) => {
    try {
      await FileService.downloadFile(id, userToRender.armyId);
    } catch (err) {
      dispatch(setMessageError(t('ERROR.FILE_DOWNLOAD')));
    }
  };

  const uploadFile = async (fileData) => {
    try {
      const newFile = await FileService.uploadFile(fileData);
      setUserToRender({
        ...userToRender,
        files: [...userToRender.files, newFile]
      });
    } catch (err) {
      dispatch(setMessageError(t('ERROR.FILE_UPLOAD')));
    }
  };

  const getImage = (file) => {
    let image = downloadIcon;
    if (file.type === FILE_TYPES.DOC || file.type === FILE_TYPES.DOCX) {
      image = wordIcon;
    } else if (file.type === FILE_TYPES.PDF) {
      image = pdfIcon;
    }

    return image;
  };

  const formattedDate = (d) => {
    const date = new Date(d);
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    const year = String(date.getFullYear());

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return `${day}/${month}/${year}`;
  };

  const getFileButton = (file) => (
    <Button
      style={{ width: '19rem' }}
      image={getImage(file)}
      isHollow
      tooltip={`${t('DOWNLOAD_THIS_FILE')} ${file.title} - ${formattedDate(file.date)}`}
      text={`${file.title} - ${formattedDate(file.date)}`}
      onClick={() => downloadFile(file.id)}
    />
  );

  return (
    <>
      <div className="files-block">
        <ContainerHeader header="FILES" />
        <div className="files-container">
          <Grid
            container
            spacing={3}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            {!userToRender || !userToRender.files || !userToRender.files.length ? (
              <div className="no-files">{t('NO_FILES')}</div>
            ) : (
              <Grid item xs={canUpload ? 10 : 12}>
                <div className="files-segmant">
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    className="files-grid"
                  >
                    {userToRender.files.map((file) => (
                      <Grid item xs={3} key={file.id}>
                        {getFileButton(file)}
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            )}
            {canUpload && (
            <Grid item xs={2}>
              <Button
                image={uploadIcon}
                text={t('FILE_UPLOAD')}
                onClick={() => setIsFilePopupOpen(true)}
              />
            </Grid>
            )}
          </Grid>
        </div>
      </div>
      <FilePopup
        isOpen={isFilePopupOpen}
        setIsOpen={setIsFilePopupOpen}
        uploadFile={uploadFile}
        user={user}
      />
    </>
  );
};

FilesBlock.propTypes = {
  user: PropTypes.shape().isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

FilesBlock.defaultProps = {
  permissions: [],
};

const mapStateToProps = (state) => ({
  permissions: state.user.permissions,
});

export default connect(mapStateToProps)(FilesBlock);
