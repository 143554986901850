/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Select from '../../../../common/components/select/select.component';
import TextInput from '../../../../common/components/text-input/text-input.component';
import ConfigService from '../../../../services/config.service';

import './info-block.styles.scss';
import { setMessageError } from '../../../../store/events/events.actions';

const InfoBlock = (props) => {
  const {
    header,
    id,
    info,
    tooltip,
    isInEditMode,
    editType,
    options,
    setForm,
    isConfig,
    configRoute,
    isDisabled
  } = props;

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [configOptions, setConfigOptions] = useState([]);
  const [selectedConfigOption, setSelectedConfigOption] = useState({ key: '' });
  const [dateValue, setDateValue] = useState(null);
  const selectedOption = options.find((option) => option.key === info) || { key: '' };

  useEffect(() => {
    if (configRoute) {
      ConfigService.getOptions(configRoute).then((res) => {
        setConfigOptions([{ key: '', value: '' }, ...res.map((value) => ({ key: value, value }))]);
      }).catch(() => dispatch(setMessageError(t('ERROR.GENERAL'))));
    }
  }, [configRoute]);

  useEffect(() => {
    setSelectedConfigOption(configOptions.find((option) => option.key === info) || { key: '' });
  }, [configOptions]);

  useEffect(() => {
    if (editType.date) {
      setDateValue(new Date(info));
    }
  }, [info, editType]);

  const handleChange = (value) => {
    setForm({ [id]: value });
  };

  return (
    <div className="info-block">
      <div style={{ display: 'flex', height: '40px' }}>
        <span className={isInEditMode ? 'edit-span' : 'header-span'}>
          {`${t(header)} `}
        </span>
        {tooltip && !isInEditMode && (
          <Tooltip TransitionComponent={Zoom} title={<p style={{ fontSize: '16px', margin: '4px', lineHeight: '16px', }}>{tooltip}</p>} arrow>
            <InfoOutlinedIcon className="header-help-icon" />
          </Tooltip>
        )}
      </div>
      {isInEditMode && editType.input && (
        <TextInput
          disabled={isDisabled}
          boundValue={info === '-' ? '' : info}
          inverted
          onChange={(value) => handleChange(value)}
        />
      )}
      {isInEditMode && editType.date && (
        <TextInput
          disabled={isDisabled}
          boundValue={dateValue}
          inverted
          type="date"
          onChange={(value) => { handleChange(new Date(value)); setDateValue(new Date(value)); }}
        />
      )}
      {isInEditMode && editType.select && !isConfig && (
        <Select
          disabled={isDisabled}
          inverted
          options={options}
          selected={selectedOption.key}
          onChange={(e) => handleChange(e.target.value)}
        />
      )}
      {isInEditMode && editType.select && isConfig && (
        <Select
          disabled={isDisabled}
          inverted
          options={configOptions}
          selected={selectedConfigOption.key}
          onChange={(e) => handleChange(e.target.value)}
        />
      )}
      {!isInEditMode ? typeof info === 'boolean'
        ? <span className="info-span">{t(info.toString().toUpperCase())}</span>
        : <span className="info-span">{info}</span> : <></>}
    </div>
  );
};

InfoBlock.propTypes = {
  header: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  info: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  isInEditMode: PropTypes.bool,
  editType: PropTypes.shape(),
  options: PropTypes.arrayOf(PropTypes.shape()),
  isConfig: PropTypes.bool,
  configRoute: PropTypes.string,
  setForm: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  isDisabled: PropTypes.bool
};

InfoBlock.defaultProps = {
  isInEditMode: false,
  editType: {},
  options: [],
  isConfig: false,
  configRoute: '',
  isDisabled: false,
};

export default InfoBlock;
