/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { PermissionType } from '@dashabim/users';
import Button from '../../common/components/button/button.component';
import EditorTable from '../../common/components/editor-table/editor-table.component';
import FilterBarItem from '../jobs/components/filter-bar-item/filter-bar-item.component';
import { columns } from './data-for-table';
import './edit-season.styles.scss';
import AutoComplete from '../../common/components/auto-complete/auto-complete.component';
import AddSeasonDialog from './components/add-season-dialog/add-season-dialog.component';
import JobsService from '../../services/jobs.service';
import SeasonsService from '../../services/seasons.service';
import AllSeasonsDialog from './components/all-seasons-dialog/all-seasons-dialog.component';
import PositionsService from '../../services/positions.service';
import JobPopup from './components/job-popup/job-popup.component';
import { getSetupRows } from './set-up-rows';
import {
  endLoading,
  setMessageError,
  startLoading,
} from '../../store/events/events.actions';
import { POPUP_MODES } from './components/job-popup/popup-modes';

const EditSeasonPage = (props) => {
  const { permissions, displayableProfessionalGroups, displayableUnits } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //
  const [fields, setFields] = useState(['SEREN', 'RASAN', 'SAAL', 'RBZ', 'ALAM', 'NAGADIM']);
  const [currentRank, setCurrentRank] = useState(fields[0]);
  const [rows, setRows] = useState([]);
  const [allSeasons, setAllSeasons] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState();
  const [isAddSeasonDialogOpen, setIsAddSeasonDialogOpen] = useState(false);
  const [isAllSeasonsDialogOpen, setIsAllSeasonsDialogOpen] = useState(false);
  const [isJobDialogOpen, setIsJobDialogOpen] = useState(false);
  const [defaultValue, setValue] = useState({});
  const [wantedJob, setWantedJob] = useState({});
  const [rowsEditingState, setRowsEditingState] = useState([]);
  const [shouldRefetch, refetch] = useState({});
  const [popupMode, setPopupMode] = useState(POPUP_MODES.VIEW);
  const [jobsInSeason, setJobsInSeason] = useState([]);
  //
  const prevRowsRef = useRef();
  const prevRowsStateRef = useRef();
  const prevJobsRef = useRef([]);
  const isGroupAdminMode = permissions.includes(PermissionType.professionalGroupAdmin)
  && !(permissions.includes(PermissionType.Manager)
  || permissions.includes(PermissionType.SystemAdmin)) && displayableProfessionalGroups;
  const isUnitAdminMode = permissions.includes(PermissionType.unitAdmin)
  && !(permissions.includes(PermissionType.Manager)
  || permissions.includes(PermissionType.SystemAdmin)) && displayableUnits;
  const isSerenGroupAdminMode = permissions.includes(PermissionType.serenGroupAdmin)
  && !(permissions.includes(PermissionType.Manager)
  || permissions.includes(PermissionType.SystemAdmin)) && displayableProfessionalGroups;
  const isSerenUnitAdminMode = permissions.includes(PermissionType.serenUnitAdmin)
  && !(permissions.includes(PermissionType.Manager)
  || permissions.includes(PermissionType.SystemAdmin)) && displayableUnits;

  const setUpRows = getSetupRows(setRowsEditingState, setRows, (value) => t(value));

  useEffect(async () => {
    let permittedFields = fields;
    if (isGroupAdminMode || isUnitAdminMode) {
      permittedFields = permittedFields.filter((field) => field === 'RASAN' || field === 'SEREN');
    } else if (isSerenGroupAdminMode || isSerenUnitAdminMode) {
      permittedFields = permittedFields.filter((field) => field === 'SEREN');
    }
    setFields(permittedFields);
  }, []);

  const createFields = () => fields.map((field) => (
    <FilterBarItem
      category={t(field)}
      onClickFunc={() => setCurrentRank(field)}
      selected={currentRank === field}
      key={`filter-bar--${field}`}
    />
  ));

  useEffect(async () => {
    setCurrentRank(fields[0]);
  }, [fields]);

  const filterSeasons = () => {
    let seasons;
    if (currentRank === 'RBZ') {
      seasons = allSeasons.filter((season) => season.rbz);
    } else {
      const rankFilter = t(currentRank).replace('"', '');
      seasons = allSeasons.filter((season) => season.rank === rankFilter);
    }
    const sortedSeasons = seasons.sort(
      (a, b) => new Date(b.startDate) - new Date(a.startDate),
    );
    const seasonOptions = sortedSeasons.map((season) => ({
      seasonId: season.id,
      seasonName: season.title,
    }));
    setOptions(seasonOptions);
    if (seasonOptions.length > 0) {
      setValue(seasonOptions[0]);
      setSelectedSeason(seasonOptions[0].seasonId);
    } else {
      setValue({});
      setSelectedSeason();
    }
  };

  useEffect(async () => {
    try {
      dispatch(startLoading());
      // eslint-disable-next-line no-nested-ternary
      const seasons = await SeasonsService.getSeasonsByFilter(isGroupAdminMode || isUnitAdminMode ? { rank: ['רסן', 'סרן'] } : (isSerenUnitAdminMode || isSerenGroupAdminMode ? { rank: ['סרן'] } : {}));
      setAllSeasons(() => seasons);
    } catch (e) {
      dispatch(setMessageError(e, t));
    } finally {
      dispatch(endLoading());
    }
  }, [shouldRefetch]);

  useEffect(async () => {
    filterSeasons();
  }, [currentRank, allSeasons]);

  useEffect(async () => {
    if (selectedSeason) {
      try {
        dispatch(startLoading());
        let jobsFilter = {
          seasonId: selectedSeason,
        };
        if (isGroupAdminMode || isSerenGroupAdminMode) {
          jobsFilter = { ...jobsFilter, unitGroups: displayableProfessionalGroups };
        } else if (isUnitAdminMode || isSerenUnitAdminMode) {
          jobsFilter = { ...jobsFilter, units: displayableUnits };
        }
        const jobs = await JobsService.getByFilter(jobsFilter);
        setJobsInSeason(jobs);
        setUpRows(
          jobs,
          prevJobsRef.current.length,
          undefined,
          prevRowsStateRef,
          rows,
        );
      } catch (e) {
        dispatch(setMessageError(e, t));
      } finally {
        dispatch(endLoading());
      }
    } else {
      setJobsInSeason([]);
      setUpRows(
        [],
        prevJobsRef.current.length,
        undefined,
        prevRowsStateRef,
        rows,
      );
    }
  }, [selectedSeason]);

  const isRowInvalid = (newJobData, rowName) => !newJobData
  || newJobData[rowName] === ''
  || newJobData[rowName] === undefined;

  const validateJobData = (newJobData) => {
    const newJobsDataRowsName = Object.keys(newJobData);
    if (!newJobsDataRowsName.length) {
      return false;
    }
    let areAllRowsValid = true;
    areAllRowsValid = newJobsDataRowsName.every((row) => !isRowInvalid(newJobData, row));
    return areAllRowsValid;
  };

  useEffect(() => {
    prevRowsRef.current = rows;
    prevJobsRef.current = jobsInSeason;
    prevRowsStateRef.current = rowsEditingState;
  });

  const onChangeAutocomplete = (newValue) => {
    setValue(newValue);
    setSelectedSeason(newValue.seasonId);
  };

  const convertJobData = async (jobData) => {
    const allAcceptedKeys = Object.keys(jobData).filter((key) => columns.some((col) => (col.id === key || key === 'id')));
    const allConvertedDates = await Promise.all(allAcceptedKeys.map((key) => {
      if (key === 'bunchDate'
        || key === 'finalApplicationDate'
        || key === 'dashabDate') {
        const d = new Date(jobData[key]);
        const month = String(d.getMonth() + 1);
        const day = String(d.getDate());
        const year = String(d.getFullYear());
        return { [key]: `${(day.length < 2) ? `0${day}` : day}/${(month.length < 2) ? `0${month}` : month}/${year}` };
      }
      return { [key]: jobData[key] };
    }));
    const convertedJobData = {};
    await Promise.all(allConvertedDates.map((obj) => Object.assign(convertedJobData, obj)));
    return convertedJobData;
  };

  const handleOnDelete = async (index, doOnSuccsess = () => {}) => {
    try {
      const prevJobs = [...prevJobsRef.current];
      const jobId = prevJobs[index].id;
      await PositionsService.deleteJob(jobId);
      prevJobs.splice(index, 1);
      setUpRows(
        prevJobs,
        prevJobs.length,
        undefined,
        prevRowsStateRef,
        rows,
      );
      setJobsInSeason(prevJobs);
      doOnSuccsess();
    } catch (e) {
      dispatch(setMessageError(e, t));
    }
  };

  const handleOnAdd = async (newJobData, doOnSuccsess = () => {}) => {
    try {
      if (validateJobData(newJobData)) {
        const convertedJob = await convertJobData(newJobData);
        const prevJobs = [...prevJobsRef.current];
        const newJob = await JobsService.createJob(convertedJob, selectedSeason);
        setUpRows(
          [...prevJobs, newJob],
          prevJobs.length,
          undefined,
          prevRowsStateRef,
          rows,
        );
        setJobsInSeason([...prevJobs, newJob]);
        doOnSuccsess();
      } else {
        throw new Error(t('ERROR.VALIDATION'));
      }
    } catch (e) {
      dispatch(setMessageError(e, t));
    }
  };

  const handleOnEdit = async (index, dataToUpdate, doOnSuccsess = () => {}) => {
    try {
      if (validateJobData(dataToUpdate)) {
        const convertedJob = await convertJobData(dataToUpdate);
        const prevJobs = [...prevJobsRef.current];
        const newJob = await JobsService.updateJob(convertedJob);
        prevJobs.splice(index, 1);
        setUpRows(
          [...prevJobs, newJob],
          prevJobs.length,
          undefined,
          prevRowsStateRef,
          rows,
        );
        setJobsInSeason([...prevJobs, newJob]);
        doOnSuccsess();
      } else {
        throw new Error(t('ERROR.VALIDATION'));
      }
    } catch (e) {
      dispatch(setMessageError(e, t));
    }
  };

  const onRowClick = (clickedIndex) => {
    setWantedJob(jobsInSeason[clickedIndex]);
    setPopupMode(POPUP_MODES.VIEW);
    setIsJobDialogOpen(true);
  };

  return (
    <div className="edit-season-page">
      <div className="table-options">
        <div className="filter">
          {createFields()}
        </div>
        <div className="left-side-options">
          <AutoComplete
            value={defaultValue}
            onChange={(newValue) => onChangeAutocomplete(newValue)}
            options={options}
          />
          {
            !(isGroupAdminMode || isUnitAdminMode || isSerenGroupAdminMode || isSerenUnitAdminMode)
            && (
            <>
              <Button
                text="ADD_SEASON"
                onClick={() => setIsAddSeasonDialogOpen(true)}
              />
              <Button
                text="ALL_SEASONS"
                onClick={() => setIsAllSeasonsDialogOpen(true)}
              />
            </>
            )
            }
        </div>
      </div>
      <EditorTable rows={rows} columns={columns.filter((col) => !col.hide)} onRowClick={onRowClick} />
      <div className="edit-season-page__buttons">
        <Button
          text="ADD_JOB"
          disabled={!selectedSeason}
          onClick={() => {
            setWantedJob({});
            setPopupMode(POPUP_MODES.ADD);
            setIsJobDialogOpen(true);
          }}
        />
        {(permissions.includes(PermissionType.Manager)
        || permissions.includes(PermissionType.SystemAdmin)
        || isGroupAdminMode
        || isUnitAdminMode
        || isSerenGroupAdminMode
        || isSerenUnitAdminMode
        ) ? <></> : (<Redirect to="/" />)}
      </div>

      <AddSeasonDialog
        refetch={() => refetch({})}
        isOpen={isAddSeasonDialogOpen}
        setIsOpen={setIsAddSeasonDialogOpen}
      />
      <AllSeasonsDialog
        seasons={allSeasons}
        refetchPage={() => refetch({})}
        isOpen={isAllSeasonsDialogOpen}
        setIsOpen={setIsAllSeasonsDialogOpen}
      />
      <JobPopup
        isOpen={isJobDialogOpen}
        setIsOpen={setIsJobDialogOpen}
        mode={popupMode}
        setMode={setPopupMode}
        submitFunctions={{
          onDelete: handleOnDelete,
          onEdit: handleOnEdit,
          onAdd: handleOnAdd,
        }}
        indexOfJob={jobsInSeason.indexOf(wantedJob)}
        job={wantedJob}
        displayableProfessionalGroups={isGroupAdminMode || isUnitAdminMode || isSerenGroupAdminMode || isSerenUnitAdminMode
          ? displayableProfessionalGroups
          : undefined}
        displayableUnits={isUnitAdminMode || isSerenUnitAdminMode
          ? displayableUnits
          : undefined}
      />
    </div>
  );
};

EditSeasonPage.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  displayableProfessionalGroups: PropTypes.arrayOf(PropTypes.string),
  displayableUnits: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  permissions: state.user.permissions,
  displayableProfessionalGroups: state.user.displayableProfessionalGroups,
  displayableUnits: state.user.displayableUnits,
});

export default connect(mapStateToProps)(EditSeasonPage);
