/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { PermissionType } from '@dashabim/users';
import Dialog from '../../../../common/components/dialog/dialog.component';
import { POPUP_MODES, DISPLAYABLE_MODES } from './popup-modes';
import { UsersService } from '../../../../services/users.service';
import { setMessageError } from '../../../../store/events/events.actions';
import DisplayableList from '../displayable-list/displayable-list.component';

const DisplayablePopup = (props) => {
  const {
    isOpen,
    setIsOpen,
    mode,
    displayableMode,
    user,
    updateUserInState,
    professionalGroups,
    units
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getHeader = () => {
    let title = '';
    let role = '';

    if (user.permissions) {
      if (user.permissions.includes(PermissionType.professionalGroupAdmin)) role = 'PROFESSIONAL_GROUP_ADMIN';
      else if (user.permissions.includes(PermissionType.professionalGroupWatch)) role = 'PROFESSIONAL_GROUP_WATCH';
      else if (user.permissions.includes(PermissionType.unitAdmin)) role = 'UNIT_ADMIN';
      else if (user.permissions.includes(PermissionType.serenGroupAdmin)) role = 'SEREN_GROUP_ADMIN';
      else if (user.permissions.includes(PermissionType.serenUnitAdmin)) role = 'SEREN_UNIT_ADMIN';
    }

    switch (mode) {
      case POPUP_MODES.EDIT:
        title = 'EDIT_PERMISSION';
        break;
      case POPUP_MODES.VIEW:
        title = 'VIEW_PERMISSION';
        break;
      default:
        title = 'VIEW_PERMISSION';
    }

    return `${t(title)} - ${user.firstName} ${user.lastName} - ${t(role)}`;
  };

  const getDisabled = (fullArr, userArr) => {
    switch (mode) {
      case POPUP_MODES.EDIT:
        return userArr && userArr.length === 1 ? userArr[0] : [];
      case POPUP_MODES.VIEW:
        return fullArr;
      default:
        return fullArr;
    }
  };

  const getOptions = (listMode) => {
    switch (mode) {
      case POPUP_MODES.EDIT:
        return listMode === DISPLAYABLE_MODES.UNIT ? units : professionalGroups;
      case POPUP_MODES.VIEW:
        return listMode === DISPLAYABLE_MODES.UNIT
          ? user.displayableUnits
          : user.displayableProfessionalGroups;
      default:
        return listMode === DISPLAYABLE_MODES.UNIT
          ? user.displayableUnits
          : user.displayableProfessionalGroups;
    }
  };

  const handleChangeDisplayable = async (
    changedDisplayable,
    displayableProperty
  ) => {
    try {
      await UsersService.updateById(user.id, {
        [displayableProperty]: changedDisplayable,
      });
      updateUserInState({
        ...user,
        [displayableProperty]: changedDisplayable,
      });
    } catch (err) {
      dispatch(setMessageError(err));
    }
  };

  const getNewProfessionalGroups = (changedProfessionalGroups) => {
    const multiProfessionalGroups = user.displayableProfessionalGroups;

    if (changedProfessionalGroups === '') {
      return multiProfessionalGroups;
    }
    if (user.displayableProfessionalGroups.includes(changedProfessionalGroups)) {
      return user.displayableProfessionalGroups.filter((item) => item !== changedProfessionalGroups);
    }
    if (professionalGroups.includes(changedProfessionalGroups)) {
      return [...multiProfessionalGroups, changedProfessionalGroups];
    }
    return multiProfessionalGroups;
  };

  const getNewUnits = (changedUnits) => {
    const multiUnits = user.displayableUnits;

    if (changedUnits === '') {
      return multiUnits;
    }
    if (user.displayableUnits.includes(changedUnits)) {
      return user.displayableUnits.filter((item) => item !== changedUnits);
    }
    if (units.includes(changedUnits)) {
      return [...multiUnits, changedUnits];
    }

    return multiUnits;
  };

  const getDialogContent = () => (
    <Grid
      container
      spacing={5}
      direction="row"
      justify="center"
      alignItems="flex-start"
    >
      {(user.displayableProfessionalGroups
       && user.displayableProfessionalGroups.length
       && (displayableMode === DISPLAYABLE_MODES.UNIT || displayableMode === DISPLAYABLE_MODES.PROFESSIONAL_GROUP))
        ? (
          <Grid item xs={displayableMode === DISPLAYABLE_MODES.PROFESSIONAL_GROUP ? 8 : 4}>
            <DisplayableList
              title={`${t('SELECT')} ${t(displayableMode === DISPLAYABLE_MODES.UNIT ? 'UNITGROUP' : 'UNITGROUPS')}:`}
              searchTitle={t('SEARCH_PROFESSIONAL_GROUPS')}
              withSearch={mode === POPUP_MODES.EDIT}
              options={getOptions('professionalGroup')}
              disabled={getDisabled(getOptions('professionalGroup'), user.displayableProfessionalGroups)}
              checked={user.displayableProfessionalGroups}
              onChange={(changedProfessionalGroups) => {
                handleChangeDisplayable(
                  displayableMode === DISPLAYABLE_MODES.UNIT
                    ? [changedProfessionalGroups]
                    : getNewProfessionalGroups(changedProfessionalGroups),
                  'displayableProfessionalGroups'
                );
              }}
              isRadioMode={displayableMode === DISPLAYABLE_MODES.UNIT}
            />
          </Grid>
        )
        : <> </>}
      {(user.displayableUnits
       && user.displayableUnits.length
       && displayableMode === DISPLAYABLE_MODES.UNIT)
        ? (
          <Grid item xs={4}>
            <DisplayableList
              title={`${t('SELECT')} ${t('UNITS')}:`}
              searchTitle={t('SEARCH_UNITS')}
              withSearch={mode === POPUP_MODES.EDIT}
              options={getOptions('unit')}
              disabled={getDisabled(getOptions('unit'), user.displayableUnits)}
              checked={user.displayableUnits}
              onChange={(changedUnits) => {
                handleChangeDisplayable(
                  getNewUnits(changedUnits),
                  'displayableUnits'
                );
              }}
            />
          </Grid>
        )
        : <> </>}
    </Grid>
  );

  return (
    <>
      <Dialog
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        header={`${getHeader()} `}
        jobDialog
      >
        {getDialogContent()}
      </Dialog>
    </>
  );
};

DisplayablePopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-typos
  mode: PropTypes.number.isRequired,
  displayableMode: PropTypes.number.isRequired,
  user: PropTypes.shape(),
  updateUserInState: PropTypes.func.isRequired,
  professionalGroups: PropTypes.arrayOf(PropTypes.string),
  units: PropTypes.arrayOf(PropTypes.string)
};

export default DisplayablePopup;
