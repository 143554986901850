/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './filter-bar-item.module.scss';

const FilterBarItem = (props) => {
  const { category, onClickFunc, selected } = props;
  return (
    <div
      className={cx(styles.filter__item, {
        [styles['filter__item--selected']]: selected,
      })}
      onClick={() => onClickFunc(category)}
    >
      {category || 'error'}
    </div>
  );
};

FilterBarItem.propTypes = {
  category: PropTypes.string.isRequired,
  onClickFunc: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
};

export default FilterBarItem;
