/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import {
  withStyles,
} from '@material-ui/core/styles';
import './job-content-card.styles.scss';
import { addJob } from '../../../../store/desired-jobs/desired-jobs.actions';
import Button from '../../../../common/components/button/button.component';
import { endLoading, setMessageError, startLoading } from '../../../../store/events/events.actions';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#00962A',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#00962A',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00962A',
      },
      '&:hover fieldset': {
        borderColor: '#00962A',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00962A',
      },
    },
  },
})(TextField);

const createJobContentCardText = (text, disabled, onChange) => (
  <div className="card__text">
    {!disabled
      ? (
        <CssTextField
          id="standard-textarea"
          defaultValue={text}
          multiline
          rows={7}
          onChange={onChange}
          inputProps={{ style: { fontSize: '1.7rem', lineHeight: 1.1 } }}
        />
      )
      : <ReactMarkdown>{text}</ReactMarkdown> }
  </div>
);

const JobContentCard = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    header,
    text,
    type,
    id,
    jobTitle,
    addJobDispatch,
    seasonId,
    userId,
    disabled,
    jobStatus,
    onChange
  } = props;

  const handleJobClick = async () => {
    dispatch(startLoading());
    try {
      await addJobDispatch({ jobTitle, id }, seasonId, userId);
    } catch (err) {
      dispatch(setMessageError(err));
    } finally {
      dispatch(endLoading());
    }
  };

  const getCardClass = () => {
    switch (type) {
      case 'text':
        return 'card-text';
      case 'popup':
        return 'card-text-shadow';
      case 'submit':
        return '';
      default:
        return '';
    }
  };

  const getContentClass = () => {
    switch (type) {
      case 'text':
        return 'job-content-section-big';
      case 'popup':
        return 'job-content-section-small';
      case 'submit':
        return 'job-content-section-big';
      default:
        return '';
    }
  };

  return (
    <div className={getContentClass()}>
      {header && <span className="job-content-card-header">{t(header)}</span>}
      <div className={`card ${getCardClass()}`}>
        {createJobContentCardText(text, disabled, onChange)}
        {type === 'submit'
          && (
          <div className="button--bottom">
            <Button
              text={jobStatus === 'openToApply' ? `+${t('SUBMIT_CANDIDACIES')}` : `${t('CLOSE_FOR_APPLY')}`}
              disabled={disabled}
              onClick={handleJobClick}
            />
          </div>
          )}
      </div>
    </div>
  );
};

JobContentCard.defaultProps = {
  header: '',
  text: '',
  type: 'text',
  id: '',
  jobTitle: '',
  userId: null,
  disabled: true,
};

JobContentCard.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  jobTitle: PropTypes.string,
  addJobDispatch: PropTypes.func.isRequired,
  seasonId: PropTypes.string.isRequired,
  userId: PropTypes.string,
  disabled: PropTypes.bool,
  jobStatus: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const myState = state[ownProps.namespace];
  return {
    seasonId: myState.filter.season?.seasonId
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addJobDispatch: (job, seasonId, userId) => dispatch(addJob(ownProps.namespace, job, seasonId, userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobContentCard);
