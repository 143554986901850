import { Base64 } from 'js-base64';
import cookies from 'js-cookie';
import { config } from '../config';

const loginUrl = config.endpoints.authenticator;

class AuthenticationService {
  static login() {
    window.location = loginUrl;
  }

  static getToken() {
    return cookies.get(config.token);
  }

  static getCurrentUser() {
    const token = AuthenticationService.getToken();
    if (token) {
      const decodedUser = AuthenticationService.parseJwt(token);
      return JSON.parse(decodedUser);
    }

    return null;
  }

  static parseJwt(token) {
    const base64Data = token.split('.')[1];
    return Base64.decode(base64Data);
  }
}

export default AuthenticationService;
