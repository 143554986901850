/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect } from 'react';
import { PermissionType } from '@dashabim/users';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import './edit-content.styles.scss';
import { UsersService } from '../../services/users.service';
import ShowMessage from './show-message/show-message.component';
import NewMessage from './new-message/new-message.component';
import NewQuestion from './new-question/new-question.component';
import ShowQuestion from './show-question/show-question.component';
import FilterBarItem from '../jobs/components/filter-bar-item/filter-bar-item.component';
import { setMessageError } from '../../store/events/events.actions';

const createFields = (fields, t, setCategoryOnClick, currCategory) => (
  fields.map((field) => <FilterBarItem category={t(field)} onClickFunc={() => setCategoryOnClick(field)} selected={currCategory === field} key={`filter-bar--${field}`} />)
);

const fields = ['CHANGING_MESSAGES', 'Q&A'];

const EditContent = (props) => {
  const { permission, armyId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentCategory, setCurrentCategory] = useState(fields[0]);
  const [user, setUser] = useState(null);
  const [shouldRefetch, refetch] = useState({});

  useEffect(async () => {
    try {
      const data = await UsersService.getByFilter({ armyId });
      setUser(data[0]);
    } catch (err) {
      dispatch(setMessageError(err));
    }
  }, [armyId, shouldRefetch]);

  return (
    <div className="edit-content-page">
      <div className="filter">
        {createFields(fields, t, setCurrentCategory, currentCategory)}
      </div>
      {currentCategory === fields[0]
        ? (
          <div className="container-blocks">
            <ShowMessage user={user} shouldRefetch={shouldRefetch} />
            <NewMessage refetch={refetch} />
          </div>
        ) : (
          <div className="container-blocks">
            <ShowQuestion shouldRefetch={shouldRefetch} refetch={refetch} />
            <NewQuestion refetch={refetch} />
          </div>
        )}
      {(permission.includes(PermissionType.Manager) || permission.includes(PermissionType.SystemAdmin)) ? <></> : (<Redirect to="/" />)}
    </div>
  );
};

EditContent.propTypes = {
  permission: PropTypes.arrayOf(PropTypes.string).isRequired,
  armyId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  permission: state.user.permissions,
  armyId: state.user.armyId,
});

export default connect(mapStateToProps)(EditContent);
