import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import xButton from '../../../../assets/logos/x-button.svg';

import './snackbar-success.styles.scss';
import { setSuccessMessage } from '../../../store/events/events.actions';

const SnackbarSuccess = (props) => {
  const { textSuccess } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      dispatch(setSuccessMessage(null));
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={textSuccess}
      autoHideDuration={3000}
      onClose={handleClose}
      className="snackbar"
    >
      <div className="snackbar-success-content">
        <span>{t(textSuccess)}</span>
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <img src={xButton} alt="search" />
        </IconButton>
      </div>
    </Snackbar>
  );
};

SnackbarSuccess.propTypes = {
  textSuccess: PropTypes.string.isRequired,
};

export default SnackbarSuccess;
