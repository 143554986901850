/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import MessageBanner from '../../dashboard/components/message-banner/message-banner.component';
import { BannerService } from '../../../services/banner.service';

import './show-message.styles.scss';
import { setMessageError } from '../../../store/events/events.actions';

const ShowMessage = (props) => {
  const { user, shouldRefetch } = props;

  const dispatch = useDispatch();
  const [messageBanners, setMessageBanners] = useState([]);

  useEffect(async () => {
    if (user) {
      try {
        const usersMessageBanners = await BannerService.getByUserArmyId(user.armyId);
        setMessageBanners(usersMessageBanners);
      } catch (err) {
        dispatch(setMessageError(err));
      }
    }
  }, [user, shouldRefetch]);

  const createMessageBanners = () => messageBanners.map((messageBanner, index) => (
    <div key={messageBanner.id}>
      <div className="show-container">
        <MessageBanner messageBanner={messageBanner} />
      </div>
      {index !== messageBanners.length - 1 ? <Divider /> : undefined}
    </div>
  ));

  return (
    <div className="show-message-block">
      <div className="message-container">{createMessageBanners()}</div>
    </div>
  );
};

ShowMessage.propTypes = {
  user: PropTypes.shape({
    armyId: PropTypes.string.isRequired
  }),
  shouldRefetch: PropTypes.bool.isRequired,
};

ShowMessage.defaultProps = {
  user: null
};

export default ShowMessage;
