import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionsAnswersService from '../../services/questions-answers.service';
import QuestionBlock from './components/question-block/question-block.component';

import './questions-and-answers.styles.scss';

const createQuestionBlock = (handleClick, question, selectedId) => (
  <QuestionBlock onClick={(id) => handleClick(id)} question={question} selectedId={selectedId} />
);

const QuestionsAndAnswersPage = () => {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const { t } = useTranslation();

  useEffect(async () => {
    setQuestions(await QuestionsAnswersService.getAll());
  }, []);

  useEffect(async () => {
    if (questions.length) {
      setSelectedQuestion(questions[0]);
    }
  }, [questions]);

  return (
    <div className="qa-page">
      {questions.length === 0 ? (
        <div className="not-found">
          {`${t('QUESTIONS_ANSWERS.NOT_FOUND')}...`}
        </div>
      ) : (
        <>
          <div className="questions-block">
            <span>{t('QUESTIONS')}</span>
            <div className="questions">
              {questions.map((qa) => (
                createQuestionBlock(setSelectedQuestion, qa, selectedQuestion?.id)
              ))}
            </div>
          </div>
          <div className="questions-block">
            <span>{t('ANSWERS')}</span>
            <div className="answers">
              <div className="answer-content">
                <span>{selectedQuestion?.answer}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QuestionsAndAnswersPage;
