/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import questionImg from '../../../../assets/logos/question-mark.svg';
import trash from '../../../../assets/logos/trash.svg';
import QuestionAnswerService from '../../../services/questions-answers.service';

import './show-question.styles.scss';
import { setMessageError } from '../../../store/events/events.actions';

const ShowQuestion = (props) => {
  const { shouldRefetch, refetch } = props;
  const [questionsAnswers, setQuestionsAnswers] = useState([]);
  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      const Questions = await QuestionAnswerService.getAll();
      setQuestionsAnswers(Questions);
    } catch (err) {
      dispatch(setMessageError(err));
    }
  }, [shouldRefetch]);

  const deleteQuestion = async (question) => {
    await QuestionAnswerService.delete(question.id);
    refetch({});
  };

  const createQnA = () => questionsAnswers.map((question, index) => (
    <div key={question.id} className="question-answer-item">
      <div className="question-answer">
        <div className="header-container">
          <div className="question-mark">
            <img alt="question-mark-icon" src={questionImg} />
          </div>
          <div className="question">
            <span>{question.question}</span>
          </div>
        </div>
        <div className="answer">{question.answer}</div>
      </div>
      <button className="trash" onClick={() => deleteQuestion(question)}>
        <img alt="trash" src={trash} />
      </button>
      {index !== questionsAnswers.length - 1 ? <Divider /> : undefined}
    </div>
  ));

  return (
    <div className="show-message-block">
      <div className="message-container">{createQnA()}</div>
    </div>
  );
};

ShowQuestion.propTypes = {
  refetch: PropTypes.func.isRequired,
  shouldRefetch: PropTypes.bool.isRequired,
};

export default ShowQuestion;
