import { HttpClient } from '../http.client';
import { config } from '../config';

const url = config.endpoints.candidaciesForJobsApi;

class CandidaciesForJobsService {
  static async updateCandidacyById(id, body) {
    return HttpClient.put(`${url}/${id}`, body);
  }

  static async getById(id) {
    return HttpClient.get(`${url}/${id}`);
  }
}

// eslint-disable-next-line import/prefer-default-export
export { CandidaciesForJobsService };
