/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../../../../common/components/text-input/text-input.component';
import DetailsBlock from '../details-block/details-block.component';

import styles from './education.module.scss';

const Education = (props) => {
  const { candidateFileds, onChange, watchMode } = props;

  const [candidateFiledsChange, setCandidateFiledsChange] = useState({});

  useEffect(() => {
    onChange(candidateFiledsChange);
  }, [candidateFiledsChange]);

  return (
    <DetailsBlock header="EDUCATION" additionalClassName={styles.education}>
      <TextInput
        color="white"
        boundValue={candidateFileds.firstDegree}
        disabled={watchMode}
        onChange={(value) => setCandidateFiledsChange({ ...candidateFiledsChange, firstDegree: value })}
        header="FIRST_DEGREE"
      />
      <TextInput
        color="white"
        boundValue={candidateFileds.secondDegree}
        disabled={watchMode}
        onChange={(value) => setCandidateFiledsChange({ ...candidateFiledsChange, secondDegree: value })}
        header="SECOND_DEGREE"
      />
    </DetailsBlock>
  );
};

Education.propTypes = {
  candidateFileds: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  watchMode: PropTypes.bool.isRequired
};

export default Education;
