import { SET_SEASON, SET_CATEGORY, SET_JOB_TITLE } from './filter.types';

const initialState = {
  season: {
    seasonName: '',
    seasonId: ''
  },
  category: '',
  jobTitle: ''
};

const filterReducer = (namespace) => (state = initialState, action) => {
  switch (action.type) {
    case `${namespace}/${SET_SEASON}`:
      return {
        ...state,
        season: action.payload
      };
    case `${namespace}/${SET_CATEGORY}`:
      return {
        ...state,
        category: action.payload
      };
    case `${namespace}/${SET_JOB_TITLE}`:
      return {
        ...state,
        jobTitle: action.payload
      };
    default:
      return state;
  }
};

export default filterReducer;
