/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '../../../../common/components/dialog/dialog.component';
import Button from '../../../../common/components/button/button.component';
import { POPUP_MODES } from './popup-modes';
import DeleteJobPopup from '../delete-job-popup/delete-job-popup.component';
import Select from '../../../../common/components/select/select.component';
import TextInput from '../../../../common/components/text-input/text-input.component';
import SwitchToggle from '../../../../common/components/switch/switch.component';
import ConfigService from '../../../../services/config.service';
import JobContentCard from '../../../jobs/components/job-content-card/job-content-card.component';
import {
  endLoading,
  setMessageError,
  startLoading,
} from '../../../../store/events/events.actions';
import SnackbarSuccess from '../../../../common/components/snackbar-success/snackbar-success.component';
import './job-popup.styles.scss';

const JobPopup = (props) => {
  const {
    isOpen,
    setIsOpen,
    mode,
    setMode,
    job,
    submitFunctions,
    indexOfJob,
    displayableProfessionalGroups,
    displayableUnits,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDeleteJobDialogOpen, setIsDeleteJobDialogOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [unitgroups, setUnitgroups] = useState([]);
  const [units, setUnits] = useState([]);
  const [newJob, setNewJob] = useState(job);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const disabled = mode === POPUP_MODES.VIEW;
  const DashsabOptional = {
    Certain: 'ודאי',
    Optional: 'אופציונלי',
  };
  const JobStatusOptions = [
    { key: 'openToApply', value: 'OPEN_TO_APPLY' },
    { key: 'closeToApply', value: 'CLOSE_TO_APPLY' },
    { key: 'closeTagabPassed', value: 'CLOSE_TAGAB_PASSED' },
    { key: 'closeManningWasAgreed', value: 'CLOSE_MANNING_WAS_AGREED' },
    { key: 'closeBunchWasAgreed', value: 'CLOSE_BUNCH_WAS_AGREED' },
    { key: 'distribution', value: 'DISTRIBUTION' },
  ];

  useEffect(async () => {
    try {
      dispatch(startLoading());
      const fetchedCategories = await ConfigService.getAllCategories();
      setCategories(() => fetchedCategories);
      const fetchedUnitGroups = await ConfigService.getAllProfessionalGroups();
      setUnitgroups(() => fetchedUnitGroups);
      const fetchedUnits = await ConfigService.getAllUnits();
      setUnits(() => fetchedUnits);
    } catch (e) {
      dispatch(setMessageError(e, t));
    } finally {
      dispatch(endLoading());
    }
  }, []);

  const convertJobDates = async (jobData) => {
    const allConvertedDates = await Promise.all(
      Object.keys(jobData).map((key) => {
        if (
          key === 'bunchDate'
          || key === 'finalApplicationDate'
          || key === 'dashabDate'
        ) {
          const d = new Date(jobData[key]);
          const month = String(d.getMonth() + 1);
          const day = String(d.getDate());
          const year = String(d.getFullYear());
          return {
            [key]: `${year}-${month.length < 2 ? `0${month}` : month}-${
              day.length < 2 ? `0${day}` : day
            }`,
          };
        }
        return { [key]: jobData[key] };
      }),
    );
    const convertedJobData = {};
    await Promise.all(
      allConvertedDates.map((obj) => Object.assign(convertedJobData, obj)),
    );
    return convertedJobData;
  };

  const convertIsOptional = (isOptional) => (isOptional ? DashsabOptional.Certain : DashsabOptional.Optional);

  useEffect(async () => {
    setNewJob(await convertJobDates(job));
  }, [isOpen]);

  const onSubmit = () => {
    setNewJob({});
    setOpenSuccessSnackbar(true);
    setTimeout(() => {
      setOpenSuccessSnackbar(false);
    }, 2500);
    setIsOpen(false);
  };

  const handleDelete = (index) => {
    submitFunctions.onDelete(index, onSubmit);
  };

  const handleEdit = (index) => {
    submitFunctions.onEdit(
      index,
      {
        ...newJob,
        isOptional: newJob.isOptional || convertIsOptional(false),
        isCore: newJob.isCore || false,
        isForOfficers: newJob.isForOfficers || false,
        isForNagadim: newJob.isForNagadim || false,
      },
      onSubmit,
    );
  };

  const handleAdd = () => {
    submitFunctions.onAdd(
      {
        ...newJob,
        isOptional: newJob.isOptional || convertIsOptional(false),
        isCore: newJob.isCore || false,
        isForOfficers: newJob.isForOfficers !== undefined ? newJob.isForOfficers : true,
        isForNagadim: newJob.isForNagadim || false,
      },
      onSubmit,
    );
  };

  const onDeleteButtonClick = () => {
    setIsDeleteJobDialogOpen(true);
  };

  const onEditButtonClick = () => {
    setMode(POPUP_MODES.EDIT);
  };

  const onAddButtonClick = () => {
    handleAdd();
  };

  const getHeader = () => {
    switch (mode) {
      case POPUP_MODES.ADD:
        return t('ADD_JOB');
      case POPUP_MODES.EDIT:
        return t('EDIT_JOB');
      case POPUP_MODES.VIEW:
        return t('VIEW_JOB');
      default:
        return t('VIEW_JOB');
    }
  };

  const getButtons = () => {
    switch (mode) {
      case POPUP_MODES.ADD:
        return (
          <div className="buttons">
            <Button text="SAVE" onClick={onAddButtonClick} />
          </div>
        );
      case POPUP_MODES.EDIT:
        return (
          <div className="buttons">
            <Button text="APPROVE" onClick={() => handleEdit(indexOfJob)} />
          </div>
        );
      case POPUP_MODES.VIEW:
        return (
          <div className="buttons">
            <Grid
              container
              spacing={4}
              direction="row"
              justify="center"
              alignItems="flex-end"
              className="grid-contanier"
            >
              <Grid item xs={6}>
                <Button text="EDIT" onClick={onEditButtonClick} />
              </Grid>
              <Grid item xs={6}>
                <Button text="DELETE" onClick={onDeleteButtonClick} />
              </Grid>
            </Grid>
          </div>
        );
      default:
        return t('VIEW_JOB');
    }
  };

  const getSelect = (
    header,
    property,
    options,
    areOptionsKeyAndVal = false,
    disabledPermissions = false,
  ) => {
    const optionsWithKeyAdnVal = areOptionsKeyAndVal
      ? options
      : options.map((option) => ({ key: option, value: option }));
    return (
      <Select
        header={header}
        onChange={(e) => e.target.value
          && setNewJob({
            ...newJob,
            [property]: optionsWithKeyAdnVal.find(
              (option) => option.key === e.target.value,
            ).key,
          })}
        required
        inverted
        disabled={disabled || (disabledPermissions && mode !== POPUP_MODES.ADD)}
        selected={newJob[property] || ''}
        options={[{ key: '', value: '' }, ...optionsWithKeyAdnVal]}
      />
    );
  };

  const getAutoComplete = (header, property, options) => (
    <div className="select-container">
      {header && <span>{t(header)}</span>}
      <Autocomplete
        style={{ borderRadius: '1.3rem' }}
        inverted
        disablePortal
        disabled={disabled}
        value={newJob[property] || ''}
        onChange={(event, newValue) => setNewJob({
          ...newJob,
          [property]: options.find((option) => option === newValue),
        })}
        popupIcon={<SearchIcon />}
        noOptionsText="אין תוצאות"
        options={[...options]}
        classes={{
          root: `autocomplete select ${disabled && 'disabled'}`,
          input: 'autocomplete',
          listbox: 'listbox-autocomplete',
        }}
        disableClearable
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            variant="standard"
            classes={{ root: 'autocomplete' }}
          />
        )}
      />
    </div>
  );

  const getTextInput = (
    header,
    property,
    isDate = false,
    min = '',
    max = '',
  ) => (
    <TextInput
      header={header}
      onChange={(value) => setNewJob({ ...newJob, [property]: value })}
      type={isDate ? 'date' : 'text'}
      min={min}
      max={max}
      disabled={disabled}
      required
      inverted
      boundValue={job[property] || ''}
    />
  );

  const getSwitch = (header, firstLabel, secondLabel, boundValue, onChange) => (
    <SwitchToggle
      disabled={disabled}
      onChange={onChange}
      header={header}
      firstLabel={firstLabel}
      secondLabel={secondLabel}
      boundValue={boundValue}
    />
  );

  const getJobContentCard = (header, property) => (
    <JobContentCard
      header={header}
      onChange={(e) => setNewJob({ ...newJob, [property]: e.target.value })}
      text={job[property]}
      namespace="dashabRasan"
      disabled={disabled}
      type="popup"
    />
  );

  const getDialogContent = () => (
    <Grid
      container
      spacing={5}
      direction="row"
      justify="center"
      alignItems="flex-end"
    >
      <Grid item xs={3}>
        {getTextInput('JOB_TITLE', 'jobTitle')}
      </Grid>
      <Grid item xs={3}>
        {getSelect(
          'PROFESSIONAL_UNIT',
          'unitGroup',
          displayableProfessionalGroups || unitgroups,
          false,
          !!displayableUnits,
        )}
      </Grid>
      <Grid item xs={3}>
        {getAutoComplete('UNITS', 'mainUnit', displayableUnits || units)}
      </Grid>
      <Grid item xs={3}>
        {getSelect('CATEGORY', 'category', categories)}
      </Grid>

      <Grid item xs={4}>
        {getJobContentCard('JOB_DESCRIPTION', 'jobDescription')}
      </Grid>
      <Grid item xs={4}>
        {getJobContentCard('NECESSARY_CONDITIONS', 'necessaryConditions')}
      </Grid>
      <Grid item xs={4}>
        {getJobContentCard('JOB_SKILLS_TITLE', 'qualificationAndSkills')}
      </Grid>

      <Grid item xs={3}>
        {getTextInput('APPROVED_BY', 'currentOccupant')}
      </Grid>
      <Grid item xs={3}>
        {getTextInput(
          'FINAL_APPLICATION_DATE',
          'finalApplicationDate',
          true,
          '',
          newJob.bunchDate || '',
        )}
      </Grid>
      <Grid item xs={3}>
        {getTextInput(
          'BUNCH_DATE',
          'bunchDate',
          true,
          newJob.finalApplicationDate || '',
          newJob.dashabDate || '',
        )}
      </Grid>
      <Grid item xs={3}>
        {getTextInput(
          'DASHAB_DATE',
          'dashabDate',
          true,
          newJob.bunchDate || '',
          '',
        )}
      </Grid>

      <Grid item xs={6}>
        {getTextInput('DATE_OF_APPROVAL', 'dateOfApproval')}
      </Grid>
      <Grid item xs={3}>
        {getSwitch(
          'IS_DASHAB_FOR_OFFICERS',
          t('OFFICER_JOB'),
          t('NOT_OFFICER_JOB'),
          newJob.isForOfficers !== undefined ? newJob.isForOfficers : true,
          (value) => setNewJob({ ...newJob, isForOfficers: value }),
        )}
      </Grid>
      <Grid item xs={3}>
        {getSwitch(
          'IS_DASHAB_FOR_NAGADIM',
          t('NAGADIM_JOB'),
          t('NOT_NAGADIM_JOB'),
          !!newJob.isForNagadim,
          (value) => setNewJob({ ...newJob, isForNagadim: value }),
        )}
      </Grid>

      <Grid item xs={3}>
        {getSwitch(
          'IS_DASHAB_OPTIONAL',
          DashsabOptional.Certain,
          DashsabOptional.Optional,
          (newJob.isOptional || DashsabOptional.Optional)
            === DashsabOptional.Optional,
          (value) => setNewJob({
            ...newJob,
            isOptional: convertIsOptional(value),
          }),
        )}
      </Grid>
      <Grid item xs={3}>
        {getSwitch(
          'IS_DASHAB_CORE',
          t('CORE'),
          t('NOT_CORE'),
          newJob.isCore || false,
          (value) => setNewJob({ ...newJob, isCore: value }),
        )}
      </Grid>
      <Grid item xs={3}>
        {getSelect('JOB_STATUS', 'jobStatus', JobStatusOptions, true)}
      </Grid>
      <Grid item xs={3}>
        {getButtons()}
      </Grid>
    </Grid>
  );

  return (
    <>
      <Dialog
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        header={`${getHeader()} ${job.jobTitle || ''}`}
        jobDialog
      >
        {getDialogContent()}
      </Dialog>
      <DeleteJobPopup
        isOpen={isDeleteJobDialogOpen}
        setIsOpen={setIsDeleteJobDialogOpen}
        deleteJob={(index) => handleDelete(index)}
        index={indexOfJob}
      />
      {openSuccessSnackbar && <SnackbarSuccess textSuccess="SUCCESS.ACTION" />}
    </>
  );
};

JobPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-typos
  mode: PropTypes.number.isRequired,
  setMode: PropTypes.func.isRequired,
  job: PropTypes.shape(),
  submitFunctions: PropTypes.shape({
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onAdd: PropTypes.func,
  }).isRequired,
  indexOfJob: PropTypes.number,
  displayableProfessionalGroups: PropTypes.arrayOf(PropTypes.string),
  displayableUnits: PropTypes.arrayOf(PropTypes.string),
};

export default JobPopup;
