/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import './candidacy-progress-stepper.styles.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../common/components/button/button.component';
import {
  endLoading,
  setMessageError,
  startLoading,
} from '../../../../../store/events/events.actions';
import { ApplyCandidatesService } from '../../../../../services/apply-candidates.service';

const ddMMFormat = (date) =>
  `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(-2)}`;

const CandidacyProgressStepper = (props) => {
  const { candidacy, disabled } = props;
  const { t } = useTranslation();
  const createSteps = () => {
    const steps = new Array(5);
    steps.fill(undefined);

    if (!candidacy.wasSubmitted) return steps;

    steps[0] = {
      wasFailed: false,
    };

    steps[1] = {
      wasFailed: false,
    };

    if (candidacy.didEnterBunch === undefined) return steps;
    if (!candidacy.didEnterBunch) {
      steps[2] = {
        wasFailed: true,
        date: candidacy.bunchDate,
      };

      return steps;
    }

    steps[2] = {
      wasFailed: false,
      date: candidacy.bunchDate,
    };

    steps[3] = {
      wasFailed: false,
    };

    if (candidacy.didPassDashab === undefined) return steps;
    if (!candidacy.didPassDashab) {
      steps[4] = {
        wasFailed: true,
        date: candidacy.bunchDate,
      };

      return steps;
    }

    steps[4] = {
      wasFailed: false,
      date: candidacy.dashabDate,
    };

    return steps;
  };
  const useDisabled = (steps) => {
    const newSteps = [...steps];
    const filledCount = steps.filter((step) => step).length;
    if (disabled && filledCount) {
      newSteps[filledCount - 1].disabled = true;
    }
    return newSteps;
  };

  const steps = useDisabled(createSteps());
  const reachedSteps = steps.filter((step) => step);

  const dispatch = useDispatch();

  const handleCancelCandidacy = async () => {
    dispatch(startLoading());
    try {
      await ApplyCandidatesService.deleteCandidacyForJob(candidacy.id);
      window.location = window.origin;
    } catch (err) {
      dispatch(setMessageError(err));
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <div className="candidacy-progress-item">
      <p
        style={{ marginLeft: '7rem' }}
      >
        {`${candidacy.jobTitle} - (${candidacy.mainUnit})`}

      </p>
      <div className="stepper-container">
        {steps.map((step, index) => (
          <>
            <div
              className={`step-circle${
                step
                  ? step.disabled
                    ? '--disabled'
                    : step.wasFailed
                    ? '--failure'
                    : '--success'
                  : ''
              }`}
              style={{ right: `calc(${index * 25}% - 1.25rem)` }}
            />
            {step && step.date ? (
              <p
                className="step-date"
                style={{ right: `calc(${index * 25}% - 3rem)` }}
              >
                {ddMMFormat(new Date(step.date))}
              </p>
            ) : null}
          </>
        ))}
        <div
          className={`step-connector${
            reachedSteps.length &&
            (reachedSteps[reachedSteps.length - 1].disabled
              ? '--disabled'
              : reachedSteps[reachedSteps.length - 1].wasFailed
              ? '--failure'
              : '--success')
          }`}
          style={{
            width: `${(reachedSteps.length - !!reachedSteps.length) * 25}%`,
          }}
        />
      </div>
      <div
        style={{
          marginRight: '5rem',
          width: '23rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!candidacy.didPassDashab && disabled && (
          <p style={{ fontSize: 14, maxWidth: 'none' }}>
            התמודדות בוטלה -
            <br />
            סוכמ/ה לתפקיד
          </p>
        )}
        {/*
          REMOVE THIS COMMENT FOR CANCEL CADIDANCY BUTTON ON DASHBOARD!!!
        {!candidacy.didPassDashab &&
          (disabled ? (
            <p style={{ fontSize: 14, maxWidth: 'none' }}>
              התמודדות בוטלה -
              <br />
              סוכמ/ה לתפקיד
            </p>
          ) : (
            new Date() < new Date(candidacy.finalApplicationDate) && (
              <Button
                style={{ height: "3rem" }}
                isSmall
                text={t("CANCEL_CANDIDACY")}
                onClick={() => {
                  handleCancelCandidacy();
                }}
              />
            )
          ))} */}
      </div>
    </div>
  );
};

CandidacyProgressStepper.propTypes = {
  disabled: PropTypes.bool.isRequired,
  candidacy: PropTypes.shape({
    id: PropTypes.string,
    jobTitle: PropTypes.string.isRequired,
    mainUnit: PropTypes.string.isRequired,
    wasSubmitted: PropTypes.bool.isRequired,
    didEnterBunch: PropTypes.bool.isRequired,
    didPassDashab: PropTypes.bool.isRequired,
    bunchDate: PropTypes.instanceOf(Date),
    dashabDate: PropTypes.instanceOf(Date),
    finalApplicationDate: PropTypes.string,
    seasonId: PropTypes.string,
    jobId: PropTypes.string,
  }).isRequired,
};

export default CandidacyProgressStepper;
