/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import * as fileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import { PermissionType } from '@dashabim/users';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import * as _ from 'lodash';
import { ApplyCandidatesService } from '../../../../services/apply-candidates.service';
import { produceExcelService } from '../../../../services/produce-excel.service';
import ContainerHeader from '../container-header/container-header.component';
import './candidacies-state-block.styles.scss';
import Button from '../../../../common/components/button/button.component';
import CandidacyProgressStepper from './candidacy-progress-stepper/candidacy-progress-stepper.component';
import { setMessageError } from '../../../../store/events/events.actions';
import excelIcon from '../../../../../assets/logos/excel.svg';
import editIcon from '../../../../../assets/logos/edit.svg';
import SecondPriorityPopup from './second-priority-popup/second-priority-popup.component';

const headlineTooltips = {
  APPLIED: 'מועמדותך לתפקיד הוגשה והתקבלה במערכת',
  AWAITING_CLUSTER: 'מועמדותך לתפקיד הועברה לטיפול הגורמים המטפלים',
  CLUSTER_RESPONSE: 'ההחלטה אשר התקבלה באשר לסיכומך לאשכול התפקיד',
  AWAITING_PLACEMENT_DISCUSSION: 'סוכמת לאשכול התפקיד וכעת ממתינים לדיון האיוש',
  PLACEMENT_DISCUSSION: 'דיון האיוש אשר מטרתו לסכם את המאייש העתידי לתפקיד',
};

const CandidaciesStateBlock = (props) => {
  const { user, permissions } = props;

  const dispatch = useDispatch();

  // array of season object that saves: seasonId, seasonName, isDisabled, candidaciesOfSeason[];
  const [seasons, setSeasons] = useState([]);

  // second priority:
  const [allowSecondPriority, setAllowSecondPriority] = useState(false);
  const [showSecondPriorityDialog, setShowSecondPriorityDialog] =
  useState(false);
  const [serenCandidacies, setSerenCandidacies] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [userCandidacies, setUserCandidacies] = useState([]);

  const [candidacies, setCandidacies] = useState([]);
  const { t } = useTranslation();

  const updateSeasonArray = (fetchedCandidacies) => {
    const newSeasons = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const candidacy of fetchedCandidacies) {
      if (!newSeasons.some((season, index) => {
        if (season.seasonId === candidacy.seasonId) {
          if (candidacy.didPassDashab) newSeasons[index].isDisabled = true;
          newSeasons[index].candidaciesOfSeason.push(candidacy);
          return true;
        }
        return false;
      })) {
        newSeasons.push({
          seasonId: candidacy.seasonId,
          seasonName: candidacy.seasonName,
          isDisabled: Boolean(candidacy.didPassDashab),
          candidaciesOfSeason: [candidacy],
        });
      }
    }
    setSeasons(newSeasons);
  };

  const produceExcelByPerson = async () => {
    const resData = await produceExcelService.getByPerson(user.id);
    fileSaver.saveAs(resData, `${user.armyId}.xlsx`);
  };

  const getSerenCandidaceis = (cads) => {
    const serenCandidencies = cads.filter((cad) => cad.seasonRank === 'סרן');

    // console.log(serenCandidacies);
    return serenCandidencies;
  };

  useEffect(() => {
    const sCads = getSerenCandidaceis(candidacies);
    setSerenCandidacies(sCads); // set the seren cads so we can use on popup
    setAllowSecondPriority(!!sCads.length); // is button enabled ?
  }, [candidacies]);

  const createSetOfArrayBySeasonId = (arr) => {
    // create a set of seasons.
    const x = _.uniqWith(arr, (currObject, otherObject) => {
      if (currObject.seasonId === otherObject.seasonId) {
        return true;
      }
      return false;
    });
    return x;
  };

  const getUserCadsForSecondPriority = async () => {
    if (user?.id) {
      try {
        const fetchedCandidacies = await ApplyCandidatesService.candidateStatus(
          { userId: user.id }
        );
        updateSeasonArray(fetchedCandidacies);
        const serenCads = getSerenCandidaceis(fetchedCandidacies);
        const setOfCandidacies = createSetOfArrayBySeasonId(serenCads);
        const seasonsOfCandidacies = setOfCandidacies.map(
          (cad) => cad.seasonId
        );
        const fetchedUserCandidacies =
          await ApplyCandidatesService.getCandidaciesOfUsers(user.id);
        const finalUserCandidacies = fetchedUserCandidacies.filter((cad) => seasonsOfCandidacies.includes(cad.seasonId));
        // console.log('finalUserCandidacies', finalUserCandidacies);
        setUserCandidacies(finalUserCandidacies);
        setCandidacies(fetchedCandidacies);
      } catch (err) {
        dispatch(setMessageError(err));
      }
    }
  };

  useEffect(async () => {
    getUserCadsForSecondPriority();
  }, [user]);

  return (
    <div className="candidacies-state-block">
      <ContainerHeader header="CURRENT_CANDIDACIES" />
      <div className="candidacies-state-container">
        {!seasons.length ? (
          <div className="no-candidacies">{t('NO_CANDIDACIES')}</div>
        ) : (
          <div className="candidacies-progress-headline">
            {Object.keys(headlineTooltips).map((headlineKey, index) => (
              <Tooltip TransitionComponent={Zoom} title={<p style={{ fontSize: "16px", margin: "4px", lineHeight: "16px" }}>{headlineTooltips[headlineKey]}</p>} arrow>
                <p style={{ right: `calc(${index * 25}% - 5rem)` }}>
                  {t(`CANDIDACY_PROGRESS.${headlineKey}`)}
                  <InfoOutlinedIcon style={{ marginRight: '4px' }} />
                </p>
              </Tooltip>
            ))}
          </div>
        )}
        {seasons.map((season) => (
          season.candidaciesOfSeason.map((candidacy) => (
            <CandidacyProgressStepper
              candidacy={candidacy}
              disabled={(season.isDisabled && !candidacy.didPassDashab)}
            />
          ))
        ))}
      </div>
      <div className="candidacies-state-buttons">
        {(permissions.includes(PermissionType.Manager) ||
          permissions.includes(PermissionType.SystemAdmin)) && (
          <Button
            image={excelIcon}
            isHollow
            text="EXPORT_EXCEL"
            onClick={() => {
              produceExcelByPerson();
            }}
          />
        )}
        <Button
          image={editIcon}
          text="SECOND_PRIORITY"
          disabled={!allowSecondPriority}
          onClick={() => {
            getUserCadsForSecondPriority();
            getSerenCandidaceis(candidacies);
            setShowSecondPriorityDialog(true);
          }}
          tooltip={allowSecondPriority ? null : t('SECOND_PRIORITY_TOOLTIP')}
        />
      </div>
      <SecondPriorityPopup
        candidacies={createSetOfArrayBySeasonId(serenCandidacies)}
        isOpen={showSecondPriorityDialog}
        userCandidacies={userCandidacies}
        setIsOpen={setShowSecondPriorityDialog}
      />
    </div>
  );
};

CandidaciesStateBlock.propTypes = {
  user: PropTypes.shape(),
  permissions: PropTypes.arrayOf(PropTypes.string),
};

CandidaciesStateBlock.defaultProps = {
  user: null,
  permissions: [],
};

const mapStateToProps = (state) => ({
  permissions: state.user.permissions,
});

export default connect(mapStateToProps)(CandidaciesStateBlock);
