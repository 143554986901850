import React from 'react';
import './table-filters.styles.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { filterFields } from './filter-fields.consts';

const TableFilters = (props) => {
  const { t } = useTranslation();
  const { tableData, filterObj, changeFilter } = props;

  const onFilterField = (field, newValue) => {
    changeFilter(
      !newValue || newValue === t(field.allOptionsLabel)
        ? field.removeFilter(filterObj)
        : field.addFilter(filterObj, newValue)
    );
  };

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      justify="flex-end"
      className="grid-contanier"
    >
      <Grid item xs={1} className="grid-item">
        <div className="title">{`${t('FILTER_BY')}:`}</div>
      </Grid>
      {filterFields.map((field) => (
        <Grid item xs={2} className="grid-item">
          <Autocomplete
            value={filterObj[field.propertyName] || ''}
            onChange={(newValue) => onFilterField(field, newValue.target.innerHTML)}
            options={['', ...field.getOptions(tableData)].map((option) => t(option))}
            classes={{
              input: 'autocomplete',
              root: 'root-autocomplete',
              listbox: 'listbox-autocomplete',
            }}
            disableClearable
            getOptionLabel={(option) => (option ? option.toString() : t(field.allOptionsLabel))}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                variant="standard"
                classes={{ root: 'autocomplete' }}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

TableFilters.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      seasonTitle: PropTypes.string,
      jobTitle: PropTypes.string,
      unitGroup: PropTypes.string,
      mainUnit: PropTypes.string,
      categoryName: PropTypes.string,
      candidaciesForJob: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string, userId: PropTypes.string })
      ),
      agreed: PropTypes.string,
    })
  ).isRequired,
  filterObj: PropTypes.shape({
    seasonTitle: PropTypes.string,
    unitGroup: PropTypes.string,
    categoryName: PropTypes.string,
  }),
  changeFilter: PropTypes.func,
};

export default TableFilters;
