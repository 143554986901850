/* eslint-disable quotes */
// eslint-disable-next-line import/prefer-default-export
export const fields = [
  { label: 'ARMY_ID', id: 'armyId', info: '' },
  { label: 'CIVIL_ID', id: 'civilId', info: '' },
  { label: 'RANK', id: 'rank', info: '' },
  { label: 'FULL_NAME', id: 'name', info: '' },
  { label: 'GENDER', id: 'gender', info: '' },
  {
    label: 'BIRTH_DATE',
    id: 'birthDate',
    date: true,
    info: '',
  },
  { label: 'FAMILY_STATUS', id: 'familyStatus', info: '' },
  { label: 'PHONE_NUMBER', id: 'phoneNumber', info: '' },
  {
    label: 'CIVIL_EMAIL',
    id: 'civilEmail',
    isManagerField: true,
    editable: { input: true },
    info: '',
  },
  { label: 'CITY', id: 'city', info: '' },
  { label: 'UNIT', id: 'unit', info: '' },
  { label: 'CORP', id: 'corp', info: '' },
  { label: 'HEALTH_PROFILE', id: 'healthProfile', info: '' },
  { label: 'EDUCATION', id: 'education', info: '' },
  {
    label: 'KEVA_ENTRY_DATE',
    date: true,
    id: 'kevaEntryDate',
    info: '',
  },
  { label: 'KEVA_SENIORITY', id: 'kevaSeniority', info: '' },
  {
    label: 'DISCHARGE_DATE',
    date: true,
    id: 'dischargeDate',
    info: '',
  },
  {
    label: 'RETIREMENT_DATE',
    date: true,
    id: 'retirementDate',
    info: '',
    hideEmpty: true,
  },
  { label: 'CURRENT_JOB', id: 'currentJob', info: '' },
  {
    label: 'CURRENT_JOB_START_DATE',
    date: true,
    id: 'currentJobStartDate',
    info: '',
  },
  {
    label: 'OCCUPATION_NAME',
    id: 'occupationName',
    editable: { input: true },
    isManagerField: true,
    info: '',
  },
  { label: 'OCCUPATION_ID', id: 'occupationId', info: '' },
  { label: 'SERVICE_MODEL', id: 'serviceModel', info: '' },
  {
    label: 'CURRENT_RANK_REASON',
    id: 'currentRankReason',
    info: '',
    hideEmpty: true,
  },
  { label: 'SECURITY_CLEARANCE', id: 'securityClearance', info: '' },
  { label: 'REVIEW', id: 'HVD', info: '' },
  { label: 'COPR_RATING', id: 'corpRating', info: '' },
  {
    label: 'COMMITTED_TO_PROMOTION',
    id: 'committedToPromotion',
    editable: { select: true },
    isManagerField: true,
    options: [
      { key: '', value: '' },
      { key: true, value: 'BUNCH_PASS' },
      { key: false, value: 'BUNCH_NOT_PASSED' },
    ],
    info: '',
    hideEmpty: true,
  },
  {
    label: 'HAD_POM_TRAINING',
    id: 'performedPOMTraining',
    info: '',
    hideEmpty: true,
  },
  {
    label: 'HAD_SAL_EVALUATION',
    id: 'hadSALEvaluationCenter',
    info: '',
    hideEmpty: true,
  },
  {
    label: 'SAL_EVALUATION_YEAR',
    date: true,
    id: 'SALEvaluationCenterDate',
    info: '',
    hideEmpty: true,
  },
  {
    label: 'FIRST_ORGANIZATION_GATE',
    id: 'firstOrganizationalGate',
    date: true,
    editable: { date: true },
    isManagerField: true,
    hideEmpty: true,
    info: '',
  },
  {
    label: 'CORP_RANKING',
    id: 'corpRanking',
    date: false,
    isManagerField: true,
    hideEmpty: true,
    info: '',
  },
  {
    label: 'SECOND_ORGANIZATION_GATE',
    id: 'secondOrganizationalGate',
    date: true,
    editable: { date: true },
    isManagerField: true,
    hideEmpty: true,
    info: '',
  },
  {
    label: 'PROFESSIONAL_UNIT',
    id: 'professionalGroup',
    editable: { select: true },
    isManagerField: true,
    isConfig: true,
    configRoute: 'professional-groups',
    info: '',
    hideEmpty: true,
  },
  {
    label: 'RASAN_TOP_COMMITTEE_DECISION',
    id: 'RASANTOPDiscussionDecision',
    editable: { select: true },
    isManagerField: true,
    isConfig: true,
    configRoute: 'rasan-top-discussion-decisions',
    info: '',
    hideEmpty: true,
  },
  {
    label: 'POTENTIAL_COMMITTEE_DECISION',
    id: 'potentialCommitteeDecision',
    isManagerField: true,
    editable: { select: true },
    isConfig: true,
    configRoute: 'potential-committee-decisions',
    info: '',
    hideEmpty: true,
  },
  {
    label: 'CLASSIFICATION_COMMITTEE_DECISION',
    id: 'classificationsDiscussionDecision',
    isManagerField: true,
    editable: { select: true },
    isConfig: true,
    configRoute: 'classification-discussion-decisions',
    info: '',
    hideEmpty: true,
  },
  {
    label: 'ORGANIZATION_DISCIPLINE',
    id: 'organizationalDiscipline',
    isManagerField: true,
    editable: { select: true },
    isConfig: true,
    configRoute: 'organizational-disciplines',
    info: '',
    hideEmpty: true,
  },
  {
    label: 'MOVEMENT',
    id: 'movement',
    isManagerField: true,
    editable: { select: true },
    isConfig: true,
    configRoute: 'movement',
    info: '',
    hideEmpty: true,
    onlyManager: true,
  },
  {
    label: 'NEW_MOVEMENT',
    id: 'new_movement',
    isManagerField: true,
    editable: { select: true },
    isConfig: true,
    configRoute: 'new_movement',
    info: '',
    hideEmpty: true,
    onlyManager: true,
  },
  {
    label: 'ALAM_EVALUATION_CENTER',
    date: true,
    id: 'alamEvaluationCenter',
    info: '',
    hideEmpty: true,
  },
  {
    label: 'COMMITTED_TO_MANGER',
    id: 'committedToManager',
    isManagerField: true,
    editable: { select: true },
    isConfig: true,
    configRoute: 'committedToManager',
    info: '',
    hideEmpty: true,
    onlyManager: true,
  },
  {
    label: 'CRIMINAL_INVESTIGATION_DIVISION_INDICATION',
    id: 'militaryPoliceIndication',
    info: '',
    hideEmpty: true,
  },
  {
    label: 'COMMENT',
    id: 'comment',
    editable: { input: true },
    isManagerField: true,
    info: '',
    hideEmpty: true,
    onlyManager: true,
  },
];
