/* eslint-disable max-len */
/* eslint-disable no-new */
/* eslint-disable import/prefer-default-export */

export const filterFields = [
  {
    label: 'SEASON',
    allOptionsLabel: 'ALL_SEASONS',
    propertyName: 'seasonTitle',
    getOptions: (tableData) => Array.from(new Set(tableData.map((data) => data.seasonTitle))).filter((option) => option !== undefined),
    addFilter: (filterObj, filterValue) => ({
      ...filterObj,
      seasonTitle: filterValue,
    }),
    removeFilter: (filterObj) => {
      const { seasonTitle, ...newObj } = filterObj;
      return newObj;
    },
  },
  {
    label: 'UNITGROUP',
    allOptionsLabel: 'ALL_UNITGROUPS',
    propertyName: 'unitGroup',
    getOptions: (tableData) => Array.from(new Set(tableData.map((data) => data.unitGroup))).filter((option) => option !== undefined),
    addFilter: (filterObj, filterValue) => ({
      ...filterObj,
      unitGroup: filterValue,
    }),
    removeFilter: (filterObj) => {
      const { unitGroup, ...newObj } = filterObj;
      return newObj;
    },
  },
  {
    label: 'CATEGORY',
    allOptionsLabel: 'ALL_CATEGORIES',
    propertyName: 'categoryName',
    getOptions: (tableData) => Array.from(new Set(tableData.map((data) => data.categoryName))).filter((option) => option !== undefined),
    addFilter: (filterObj, filterValue) => ({
      ...filterObj,
      categoryName: filterValue,
    }),
    removeFilter: (filterObj) => {
      const { categoryName, ...newObj } = filterObj;
      return newObj;
    },
  },
  {
    label: 'STATUS',
    allOptionsLabel: 'ALL_STATUSES',
    propertyName: 'jobStatus',
    getOptions: (tableData) => Array.from(new Set(tableData.map((data) => {
      let option;
      if (data.agreed !== null) {
        option = 'AGREED';
      } else if (data.candidaciesForJob && data.candidaciesForJob.length !== 0) {
        option = 'SUMMARY_CANDIDACIES';
      }
      return option;
    }))).filter((option) => option !== undefined),
    addFilter: (filterObj, filterValue) => ({
      ...filterObj,
      jobStatus: filterValue,
    }),
    removeFilter: (filterObj) => {
      const { jobStatus, ...newObj } = filterObj;
      return newObj;
    },
  },
];

export const getDefaultFilter = (allTableData) => {
  let filterObj = {};
  filterFields.forEach((f) => {
    filterObj = f.addFilter(filterObj, f.getOptions(allTableData)[0]);
  });
  return filterObj;
};
