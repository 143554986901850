import React from 'react';
import PropTypes from 'prop-types';
import ArrayTextInputs from '../array-text-inputs/array-text-inputs.component';

const PreviousJobs = (props) => {
  const { watchMode, candidateFileds, onChange } = props;

  return (
    <ArrayTextInputs
      initialValues={candidateFileds.previousJobs || []}
      placeholders={[
        'CURRENT_JOB_PLACEHOLDER',
        'PREVIOUS_JOB_PLACEHOLDER',
        'PREVIOUS_JOB_PLACEHOLDER',
      ]}
      seniority
      watchMode={watchMode}
      onChange={onChange}
      fieldName="previousJobs"
      header="PREVIOUS_JOBS"
    />
  );
};

PreviousJobs.propTypes = {
  onChange: PropTypes.func.isRequired,
  candidateFileds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  watchMode: PropTypes.bool.isRequired
};

export default PreviousJobs;
