import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChatIcon from '@material-ui/icons/Chat';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useTranslation } from 'react-i18next';
import ConfigService from '../../../services/config.service';
import phoneIcon from '../../../../assets/logos/phone.svg';
import mailIcon from '../../../../assets/logos/mail.svg';
import './footer.styles.scss';

const Footer = (props) => {
  const { mail, phone, chatClick } = props;
  const { t } = useTranslation();

  const [accessTime, setAccessTime] = useState('');

  useEffect(async () => {
    try {
      const fetchedAccessTime = await ConfigService.getAccessTime();
      setAccessTime(fetchedAccessTime);
    } catch {
      setAccessTime(t('ERROR.NOT_AVAILABLE'));
    }
  }, []);

  return (
    <div className="footer-container">
      <div className="contact-container">
        <span className="contact">{t('CONTACT_US')}</span>
        <a href={`mailto:${mail}`} style={{ textDecoration: 'none' }}>
          <div className="info">
            <img src={mailIcon} alt={mail} />
            <span>{t('MAIL')}</span>
          </div>
        </a>
        <div className="info">
          <img src={phoneIcon} alt={phone} />
          <span>{phone}</span>
        </div>
        <div className="info">
          <ChatIcon className="chat-icon" onClick={chatClick} />
          <span>{t('CHAT.FOOTER_TITLE')}</span>
        </div>
        <div className="info">
          <AccessTimeIcon className="access-time-icon" />
          <span>{accessTime}</span>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  mail: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  chatClick: PropTypes.func.isRequired,
};

export default Footer;
