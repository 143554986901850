import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './details-block.styles.scss';

const DetailsBlock = (props) => {
  const { t } = useTranslation();
  const { children, header, additionalClassName } = props;
  return (
    <div className="details-block">
      <span className="details-block-span">{t(header)}</span>
      <div className={`details-block__container ${additionalClassName}`}>
        {children}
      </div>
    </div>
  );
};

DetailsBlock.propTypes = {
  children: PropTypes.node.isRequired,
  additionalClassName: PropTypes.string,
  header: PropTypes.string.isRequired
};

DetailsBlock.defaultProps = {
  additionalClassName: ''
};

export default DetailsBlock;
