/* eslint-disable import/prefer-default-export */

export const displayFields = [
  {
    label: 'CATEGORY',
    propertyName: 'category',
  },
  {
    label: 'UNITGROUP',
    propertyName: 'unitGroup',
  },
  {
    label: 'UNIT',
    propertyName: 'mainUnit',
  },
];
