import { HttpClient } from '../http.client';
import { config } from '../config';

const url = config.endpoints.questionsAnswers;

class QuestionsAnswersService {
  static create(body) {
    return HttpClient.post(url, body);
  }

  static getAll() {
    return HttpClient.get(url, null);
  }

  static delete(id) {
    return HttpClient.delete(`${url}/${id}`, null);
  }
}

export default QuestionsAnswersService;
