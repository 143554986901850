/* eslint-disable max-len */
import { HttpClient } from '../http.client';
import { config } from '../config';

const url = config.endpoints.jobsApi;

const allCategories = 'הכל';

class JobsService {
  static async getJobsBySeasonAndCategoryAndJobTitle(seasonId, category, jobTitle, startIndex, endIndex) {
    const query = { seasonId, startIndex, endIndex };
    if (category && category !== allCategories) {
      query.category = category;
    }
    if (jobTitle) {
      query.jobTitle = jobTitle;
    }
    return HttpClient.get(url, query);
  }

  static async getByFilter(filter) {
    return HttpClient.get(url, filter);
  }

  static async getById(id) {
    return HttpClient.get(`${url}/${id}`);
  }

  static async updateMany(body) {
    const keys = Object.keys(body);
    return Promise.all(keys.map((key) => HttpClient.put(`${url}/${key}`, body[key])));
  }

  static async updateJob(job) {
    const { id, ...restOfJob } = job;
    return HttpClient.put(`${url}/${id}`, restOfJob);
  }

  static async createJob(job, seasonId) {
    return HttpClient.post(`${url}`, { ...job, seasonId });
  }

  static async create(body, id) {
    const keys = Object.keys(body);
    return Promise.all(keys.map((key) => HttpClient.post(`${url}`, { ...body[key], seasonId: id })));
  }
}

export default JobsService;
