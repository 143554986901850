/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Typography } from '@material-ui/core';
import SearchIcon from '../../../../assets/logos/search.svg';
import './text-input.styles.scss';

const ENTER_CHAR_CODE = 13;
const getDateString = (date) => {
  if (date) {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = `0${dt}`;
    }
    if (month < 10) {
      month = `0${month}`;
    }

    return `${year}-${month}-${dt}`;
  }

  return null;
};
const TextInput = (props) => {
  const { t } = useTranslation();
  const {
    header,
    placeholder,
    isSearchInput,
    rows,
    maxRows,
    inverted,
    onChange,
    onSearch,
    disabled,
    initialValue,
    required,
    type,
    color,
    min,
    max,
    boundValue,
    seniorityText,
    extraStyles,
    blackText,
    namespace,
  } = props;

  const [value, setValue] = useState(boundValue);

  useEffect(() => {
    setValue(initialValue);
  }, [isSearchInput, namespace]);

  useEffect(() => {
    setValue(boundValue);
  }, [boundValue]);

  const handleOnKeyPress = (e) => {
    if (e.keyCode === ENTER_CHAR_CODE) {
      onSearch(e.target.value);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  const getEndAdorment = () => {
    if (isSearchInput) {
      return (
        <InputAdornment>
          <IconButton disabled={!value} onClick={() => onSearch(value)}>
            <img src={SearchIcon} alt="search" />
          </IconButton>
        </InputAdornment>
      );
    }
    if (seniorityText) {
      return (
        <Typography style={{ fontSize: '1.2rem' }}>{seniorityText}</Typography>
      );
    }
    return <></>;
  };
  return (
    <div className="input-container">
      {header && <span className="text-input-header">{t(header)}</span>}
      <Input
        endAdornment={getEndAdorment()}
        variant="outlined"
        className={`text-input ${inverted && 'inverted'} ${
          disabled && 'disabled'
        }`}
        classes={{ input: 'font' }}
        multiline={rows > 1 || maxRows > 1}
        rows={rows}
        rowsMax={maxRows}
        placeholder={t(placeholder)}
        onKeyDown={(e) => handleOnKeyPress(e)}
        onChange={(e) => handleChange(e)}
        value={type === 'date' ? getDateString(new Date(value)) : value}
        required={required}
        type={type}
        disabled={disabled}
        style={{
          marginTop: 5,
          backgroundColor: color,
          height: '100%',
          ...(blackText && { color: '#000' }),
          ...extraStyles,
        }}
        disableUnderline
        inputProps={{ min, max }}
      />
    </div>
  );
};

TextInput.propTypes = {
  header: PropTypes.string,
  placeholder: PropTypes.string,
  isSearchInput: PropTypes.bool,
  rows: PropTypes.number,
  maxRows: PropTypes.number,
  onChange: PropTypes.func,
  inverted: PropTypes.bool,
  onSearch: PropTypes.func,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  boundValue: PropTypes.string,
  seniorityText: PropTypes.string,
  extraStyles: PropTypes.object,
  blackText: PropTypes.bool,
  namespace: PropTypes.string,
};

TextInput.defaultProps = {
  header: '',
  placeholder: '',
  isSearchInput: false,
  rows: 1,
  maxRows: 1,
  onSearch: () => {},
  onChange: () => {},
  inverted: false,
  disabled: false,
  initialValue: '',
  required: false,
  type: 'text',
  color: 'primary',
  min: null,
  max: null,
  boundValue: '',
  seniorityText: null,
  extraStyles: {},
  blackText: true,
};
export default TextInput;
