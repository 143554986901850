import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';

import styles from './loading-backdrop.module.scss';

const LoadingBackdrop = ({ open }) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Backdrop id={styles.backdrop} open={open}>
      <CircularProgress className={styles.progress} />
      {location.pathname === '/dashab-summary' ? (
        <Typography className={styles.loadingText}>
          {t('LOADING_TEXT')}
        </Typography>
      ) : ''}
    </Backdrop>
  );
};

LoadingBackdrop.propTypes = {
  open: PropTypes.bool.isRequired
};

export default LoadingBackdrop;
