/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-children-prop */
import React, { createElement } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../common/components/button/button.component';
import Select from '../../common/components/select/select.component';
import infoLogo from '../../../assets/logos/info.svg';
import uploadIcon from '../../../assets/logos/upload.svg';
import styles from './edit-dashab-status.module.scss';
import excelIcon from '../../../assets/logos/excel.svg';
import downloadIcon from '../../../assets/logos/download.svg';

export const getSetupRows = (
  handleChange,
  handleDeleteCandidacy,
  t,
  produceExcel,
  isDisabled,
  uploadFile,
  downloadFile
) => (statuses) => statuses.map((status) => ({
  ...status,
  didEnterBunch: createElement(Select, {
    options: [
      { key: '', value: '' },
      { key: 'BUNCH_PASS', value: 'BUNCH_PASS' },
      { key: 'BUNCH_NOT_PASSED', value: 'BUNCH_NOT_PASSED' },
    ],
    selected: status.didEnterBunch
      ? 'BUNCH_PASS'
      : status.didEnterBunch === undefined
        ? ''
        : 'BUNCH_NOT_PASSED',
    onChange: (e) => handleChange(e, status.id, 'didEnterBunch'),
  }),
  didPassDashab: createElement(Select, {
    options: [
      { key: '', value: '' },
      { key: 'DASHAB_PASS', value: 'DASHAB_PASS' },
      { key: 'DASHAB_NOT_PASSED', value: 'DASHAB_NOT_PASSED' },
    ],
    disabled: isDisabled(status),
    staticValue: '',
    selected: status.didPassDashab
      ? 'DASHAB_PASS'
      : status.didPassDashab === undefined || isDisabled(status)
        ? ''
        : 'DASHAB_NOT_PASSED',
    onChange: (e) => handleChange(e, status.id, 'didPassDashab'),
  }),
  checkSeason: createElement(NavLink, {
    to: {
      pathname: '/apply-candidacy/watch',
      watchMode: {
        jobId: status.jobId,
        userId: status.userId,
        armyId: status.armyId,
      },
    },
    children: (
      <img src={infoLogo} alt="info" className={styles['info-logo']} />
    ),
  }),
  delete: (
    <Button
      style={{ height: '3rem' }}
      isSmall
      text={t('CANCEL_CANDIDACY')}
      onClick={() => {
        handleDeleteCandidacy(status);
      }}
    />
  ),
  exportExcel: createElement(Button, {
    image: excelIcon,
    text: 'EXPORT_EXCEL',
    onClick: () => produceExcel(status.jobId, status.jobTitle),
  }),
  fullName: `${status.firstName} ${status.lastName}`,
  upload: (
    <Button
      isUpload
      tooltip={status.file && t('ERROR.FILE_WARNING')}
      image={uploadIcon}
      text={t('FILE_UPLOAD')}
      onClick={(e) => {
        uploadFile(
          {
            armyId: status.armyId,
            file: e.target.files[0],
            date: new Date(),
            title: `${t('DID_PASS_DASHAB')} - ${status.jobTitle}`,
            candidacyId: status.id
          }
        );
      }}
    />
  ),
  download: (
    <Button
      tooltip={status.file && `${t('DOWNLOAD_THIS_FILE')} ${status.file.title}`}
      image={downloadIcon}
      disabled={!status.file}
      text={t('FILE_DOWNLOAD')}
      onClick={() => {
        downloadFile(status.file.id, status.armyId);
      }}
    />
  ),
}));
