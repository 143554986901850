/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { PermissionType } from '@dashabim/users';
import { useDispatch } from 'react-redux';
import CheckboxList from '../checkbox-list/checkbox-list.component';
import Select from '../../../../common/components/select/select.component';
import { permissionsOptions } from '../../data-for-table';
import Button from '../../../../common/components/button/button.component';
import { UsersService } from '../../../../services/users.service';
import { DISPLAYABLE_MODES, POPUP_MODES } from '../displayable-popup/popup-modes';
import { setMessageError } from '../../../../store/events/events.actions';
import DisplayablePopup from '../displayable-popup/displayable-popup.component';

const RowByUser = (props) => {
  const {
    updateUserInState, userOfRow, professionalGroups, units
  } = props;
  const dispatch = useDispatch();
  const [displayableMode, setDisplayableMode] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [mode, setMode] = useState(POPUP_MODES.VIEW);
  const selectablePermissions = permissionsOptions.filter((option) => option.isUnique).map((option) => option.key);
  const isSinglePermission = (user, isOnlyUnique = false) => (user.permissions.length === 1
    ? (isOnlyUnique ? permissionsOptions.filter((option) => option.isUnique).map((option) => option.key).includes(user.permissions[0]) : true)
    : false);

  useEffect(() => {
    let m = null;
    if (userOfRow.permissions.includes(PermissionType.professionalGroupAdmin)
      || userOfRow.permissions.includes(PermissionType.professionalGroupWatch)
      || userOfRow.permissions.includes(PermissionType.serenGroupAdmin)) {
      m = DISPLAYABLE_MODES.PROFESSIONAL_GROUP;
    } else if (userOfRow.permissions.includes(PermissionType.unitAdmin) || userOfRow.permissions.includes(PermissionType.serenUnitAdmin)) {
      m = DISPLAYABLE_MODES.UNIT;
    }
    setDisplayableMode(m);
  }, []);

  const onClickView = () => {
    setMode(POPUP_MODES.VIEW);
    setOpenPopup(true);
  };

  const onClickEdit = () => {
    setMode(POPUP_MODES.EDIT);
    setOpenPopup(true);
  };

  const getNewPermissions = (user, changedPermission) => {
    if (changedPermission === 'nochange') return user.permissions;
    const multiOptionPermissions = user.permissions.filter(
      (permission) => !selectablePermissions.includes(permission)
    );

    if (changedPermission === '') {
      return multiOptionPermissions;
    }
    if (selectablePermissions.includes(changedPermission)) {
      return [...multiOptionPermissions, changedPermission];
    }
    if (user.permissions.includes(changedPermission)) {
      return user.permissions.filter((item) => item !== changedPermission);
    }
    return [...user.permissions, changedPermission];
  };

  const handleChangePermission = async (user, changedPermission) => {
    try {
      const newPermissions = getNewPermissions(user, changedPermission);
      const newUser = {
        ...user,
        permissions: newPermissions,
      };
      let professionalGroupsChange = {
        displayableProfessionalGroups: (user.displayableProfessionalGroups && user.displayableProfessionalGroups.length > 0
          ? user.displayableProfessionalGroups
          : [user.fieldsManager.professionalGroup]) || []
      };
      let unitChange = {
        displayableUnits: (user.displayableUnits && user.displayableUnits.length > 0
          ? user.displayableUnits
          : [user.unit]) || []
      };

      if (changedPermission === PermissionType.professionalGroupWatch) {
        unitChange = {
          displayableUnits: []
        };
        await UsersService.updateById(user.id, {
          permissions: newPermissions,
          ...unitChange,
          ...professionalGroupsChange
        }).then(() => {
          setDisplayableMode(DISPLAYABLE_MODES.PROFESSIONAL_GROUP);
        });
      } else if (changedPermission === PermissionType.professionalGroupAdmin || changedPermission === PermissionType.serenGroupAdmin) {
        unitChange = {
          displayableUnits: []
        };
        await UsersService.updateById(user.id, {
          permissions: newPermissions,
          ...unitChange,
          ...professionalGroupsChange
        }).then(() => {
          setDisplayableMode(DISPLAYABLE_MODES.PROFESSIONAL_GROUP);
        });
      } else if (changedPermission === PermissionType.unitAdmin || changedPermission === PermissionType.serenUnitAdmin) {
        professionalGroupsChange = {
          displayableProfessionalGroups: user.fieldsManager.professionalGroup ? [user.fieldsManager.professionalGroup] : [],
        };
        await UsersService.updateById(user.id, {
          permissions: newPermissions,
          ...unitChange,
          ...professionalGroupsChange
        }).then(() => {
          setDisplayableMode(DISPLAYABLE_MODES.UNIT);
        });
      } else {
        if (user.displayableProfessionalGroups && user.displayableProfessionalGroups.length > 0
            && !newPermissions.includes(PermissionType.professionalGroupAdmin)
            && !newPermissions.includes(PermissionType.unitAdmin)
            && !newPermissions.includes(PermissionType.serenGroupAdmin)
            && !newPermissions.includes(PermissionType.serenUnitAdmin)) {
          setDisplayableMode(null);
          professionalGroupsChange = {
            displayableProfessionalGroup: '',
            displayableProfessionalGroups: [],
          };
          await UsersService.updateById(user.id, {
            permissions: newPermissions,
            ...professionalGroupsChange,
          });
        }
        if (user.displayableUnits && user.displayableUnits.length > 0
            && !newPermissions.includes(PermissionType.unitAdmin)
            && !newPermissions.includes(PermissionType.serenUnitAdmin)) {
          setDisplayableMode(null);
          unitChange = {
            displayableUnits: []
          };
          await UsersService.updateById(user.id, {
            permissions: newPermissions,
            ...unitChange,
          });
        } else {
          await UsersService.updateById(user.id, {
            permissions: newPermissions,
          });
        }
      }
      updateUserInState({ ...newUser, ...professionalGroupsChange, ...unitChange });
    } catch (err) {
      dispatch(setMessageError(err));
    }
  };

  const getRowByUser = (user) => (
    <>
      <CheckboxList
        options={permissionsOptions.filter((option) => !option.isUnique)}
        disabled={[...[isSinglePermission(user) ? user.permissions[0] : []], 'regular']}
        checkedPermissions={user.permissions}
        onChange={(changedPermission) => handleChangePermission(user, changedPermission)}
      />
      <div className="select_container">
        <Select
          options={
                isSinglePermission(user, true)
                  ? [...permissionsOptions.filter((option) => option.isUnique)]
                  : [{ key: '', value: 'NO_WATCH_PERMISSIONS' }, ...permissionsOptions.filter((option) => option.isUnique)]
              }
          selected={
                selectablePermissions.find((permission) => user.permissions.includes(permission))
                  ? selectablePermissions.find((permission) => user.permissions.includes(permission))
                  : ''
              }
          onChange={(e) => {
            handleChangePermission(user, e.target.value);
          }}
        />
        {!!displayableMode && (
          <Grid
            className="grid"
            container
            spacing={0}
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <Grid item xs={4} style={{ display: 'grid' }}>
              <Button
                text="VIEW"
                onClick={onClickView}
                isSmall
              />
            </Grid>
            <Grid item xs={4} style={{ display: 'grid' }}>
              <Button
                text="EDIT"
                onClick={onClickEdit}
                isSmall
              />
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );

  return (
    <>
      {getRowByUser(userOfRow)}
      <DisplayablePopup
        professionalGroups={professionalGroups}
        units={units}
        isOpen={openPopup}
        setIsOpen={setOpenPopup}
        mode={mode}
        displayableMode={displayableMode}
        user={userOfRow}
        updateUserInState={updateUserInState}
      />
    </>
  );
};

RowByUser.propTypes = {
  userOfRow: PropTypes.shape().isRequired,
  updateUserInState: PropTypes.func.isRequired,
  professionalGroups: PropTypes.arrayOf(PropTypes.string),
  units: PropTypes.arrayOf(PropTypes.string)
};

export default RowByUser;
