import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { login } from '../store/user/user.actions';
import Header from '../common/components/header/header.component';
import Sidebar from '../common/components/sidebar/sidebar.component';
import Footer from '../common/components/footer/footer.component';
import Chat from '../common/components/chat/chat.component';
import LoadingBackdrop from '../common/components/loading-backdrop/loading-backdrop.component';
import Loader from '../../assets/logos/yesodot-animated.svg';
import './app.styles.scss';
import AuthenticationService from '../services/authentication.service';
import AppRouter from './app.router';
import { items, managerItems } from './sidebar-items';
import { UsersService } from '../services/users.service';
import SnackbarSuccess from '../common/components/snackbar-success/snackbar-success.component';
import SnackbarError from '../common/components/snackbar-error/snackbar-error.component';

const AppPage = (props) => {
  const { t } = useTranslation();
  const {
    setDispatchLogin,
    headerText,
    successMassage,
    isLoading,
    errorMessage
  } = props;
  const [currentUserFromToken, setCurrentUserFromToken] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const userFromToken = AuthenticationService.getCurrentUser();
      if (!userFromToken) {
        AuthenticationService.login();
      }
      setCurrentUserFromToken(userFromToken);
    }, 1500);
  }, []);

  useEffect(async () => {
    if (currentUserFromToken) {
      const currentUser = await UsersService.getById(currentUserFromToken.userId);
      setDispatchLogin(currentUser);
    }
  }, [currentUserFromToken]);

  const handleOpenChat = () => {
    setIsChatOpen(true);
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };

  return currentUserFromToken ? (
    <>
      <Header />
      <SnackbarError textError={errorMessage} />
      <SnackbarSuccess textSuccess={successMassage} />
      <LoadingBackdrop open={isLoading} />
      <Chat isOpen={isChatOpen} handleClose={handleCloseChat} />
      <div className="page">
        <Sidebar items={items} managerItems={managerItems} />
        <div className="page-content">
          <h1>{t(headerText)}</h1>
          <AppRouter />
        </div>
      </div>
      <Footer mail="Srv_MokedSegelAman@services.idf" phone="3664*" chatClick={handleOpenChat} />
    </>
  ) : (
    <img src={Loader} alt="loader" className="logo-loader" />
  );
};

AppPage.propTypes = {
  setDispatchLogin: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  successMassage: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  headerText: state.headerProperties.headerText,
  successMassage: state.events.successMessage,
  isLoading: state.events.isLoading,
  errorMessage: state.events.errorMessage
});

const mapDispatchToProps = (dispatch) => ({
  setDispatchLogin: (user) => dispatch(login(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppPage);
