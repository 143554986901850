// eslint-disable-next-line no-unused-vars
const DashsabOptional = {
  Certain: 'ודאי',
  Optional: 'אופציונלי',
};

export const DashabIsCore = {
  core: { key: true, value: 'ליבה' },
  notCore: { key: false, value: 'לא ליבה' },
};

export const columns = [
  {
    id: 'mainUnit',
    label: 'UNIT',
  },
  {
    id: 'unitGroup',
    label: 'UNITGROUP',
  },
  {
    id: 'jobTitle',
    label: 'JOB_TITLE',
  },
  {
    id: 'category',
    label: 'CATEGORY',
    options: [
      { key: '', value: '' },
      { key: 'openToApply', value: 'TECHNOLOGICAL' },
      { key: 'closeToApply', value: 'SERVICE' },
      { key: 'closeTagabPassed', value: 'HEADQUARTERS' },
    ],
  },
  {
    id: 'jobDescription',
    label: 'JOB_DESCRIPTION',
    rows: 8,
    hide: true,
  },
  {
    id: 'necessaryConditions',
    label: 'NECESSARY_CONDITIONS',
    rows: 8,
    hide: true,
  },
  {
    id: 'qualificationAndSkills',
    label: 'JOB_SKILLS_TITLE',
    rows: 8,
    hide: true,
  },
  {
    id: 'currentOccupant',
    label: 'APPROVED_BY',
    hide: true,
  },
  {
    id: 'finalApplicationDate',
    label: 'FINAL_APPLICATION_DATE',
    type: 'date',
  },
  {
    id: 'bunchDate',
    label: 'BUNCH_DATE',
    type: 'date',
  },
  {
    id: 'dashabDate',
    label: 'DASHAB_DATE',
    type: 'date',
  },
  {
    id: 'isCore',
    label: 'CORE',
    hide: true,
  },
  {
    id: 'isForOfficers',
    label: 'IS_DASHAB_FOR_OFFICERS',
    hide: true,
  },
  {
    id: 'isForNagadim',
    label: 'IS_DASHAB_FOR_NAGADIM',
    hide: true,
  },
  {
    id: 'dateOfApproval',
    label: 'DATE_OF_APPROVAL',
    hide: true,
  },
  {
    id: 'isOptional',
    label: 'IS_DASHAB_OPTIONAL',
    options: [
      { key: '', value: '' },
      { key: DashsabOptional.Certain, value: DashsabOptional.Certain },
      { key: DashsabOptional.Optional, value: DashsabOptional.Optional },
    ],
    hide: true,
  },
  {
    id: 'jobStatus',
    label: 'JOB_STATUS',
    options: [
      { key: '', value: '' },
      { key: 'openToApply', value: 'OPEN_TO_APPLY' },
      { key: 'closeToApply', value: 'CLOSE_TO_APPLY' },
      { key: 'closeTagabPassed', value: 'CLOSE_TAGAB_PASSED' },
      { key: 'closeManningWasAgreed', value: 'CLOSE_MANNING_WAS_AGREED' },
      { key: 'closeBunchWasAgreed', value: 'CLOSE_BUNCH_WAS_AGREED' },
      { key: 'distribution', value: 'DISTRIBUTION' },
    ],
  },
];
