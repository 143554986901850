import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DialogMaterialUi from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

import closeButton from '../../../../assets/logos/close-button.svg';
import './dialog.styles.scss';

const Dialog = (props) => {
  const { t } = useTranslation();
  const {
    isOpen,
    children,
    handleClose,
    header,
    avatar,
    tableDialog,
    jobDialog,
    applyCandidacyDialog,
    showCloseButton
  } = props;

  return (
    <div>
      <DialogMaterialUi
        classes={{ root: `dialog-root ${tableDialog ? 'dialog-root--table' : ''} ${jobDialog ? 'dialog-root--job' : ''} ${applyCandidacyDialog ? 'dialog-root--apply-candidacy' : ''}` }}
        open={isOpen}
        onClose={handleClose}
      >
        <div className="dialog-header">
          <div className="dialog-header__content">
            {avatar}
            <span className={avatar ? 'avatar-dialog-title' : 'dialog-title'}>{t(header)}</span>
          </div>
          {showCloseButton && (
          <IconButton
            edge="start"
            className="close-button"
            onClick={handleClose}
          >
            <img src={closeButton} alt="close" />
          </IconButton>
          )}
        </div>
        <div className="dialog-content">
          {children}
        </div>
      </DialogMaterialUi>
    </div>
  );
};

Dialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  header: PropTypes.string.isRequired,
  tableDialog: PropTypes.bool,
  jobDialog: PropTypes.bool,
  applyCandidacyDialog: PropTypes.bool,
  avatar: PropTypes.node,
  showCloseButton: PropTypes.bool
};

Dialog.defaultProps = {
  tableDialog: false,
  jobDialog: false,
  applyCandidacyDialog: false,
  showCloseButton: true
};

export default Dialog;
