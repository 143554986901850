import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import './auto-complete.styles.scss';

const AutoComplete = (props) => {
  const { t } = useTranslation();
  const {
    placeholder, onChange, options, value
  } = props;

  return (
    <Autocomplete
      id="combo-box-demo"
      classes={{
        input: 'autocomplete',
        root: 'root-autocomplete',
        listbox: 'listbox-autocomplete'
      }}
      value={value}
      onChange={(event, newValue) => { onChange(newValue); }}
      options={options}
      disableClearable
      getOptionLabel={(option) => option.seasonName}
      renderInput={(params) => (
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          placeholder={t(placeholder)}
          variant="standard"
          classes={{ root: 'autocomplete' }}
        />
      )}
    />
  );
};

AutoComplete.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  value: PropTypes.string.isRequired,
};
export default AutoComplete;
