/* eslint-disable max-len */
import { HttpClient } from '../http.client';
import { config } from '../config';

const url = config.endpoints.applyCandidates;

// eslint-disable-next-line import/prefer-default-export
export class ApplyCandidatesService {
  static candidateStatus(query, isPost = false) {
    if (isPost) {
      return HttpClient.post(`${url}/candidate-status`, query);
    }
    return HttpClient.get(`${url}/candidate-status`, query);
  }

  static getCandidaciesOfUsers(userId) {
    return HttpClient.get(`${url}/candidacies-of-users/${userId}`);
  }

  static getDashabResult() {
    return HttpClient.get(`${url}/dashab-result`, null);
  }

  static updateSecondPriority(candidacieOfUserId, secondPriority) {
    const body = { secondPriority };
    return HttpClient.put(`${url}/second-priority/${candidacieOfUserId}`, body);
  }

  static updatePriorities(candidaciesOfUserId, priorities) {
    let body = {};
    const items = Object.values(priorities).map((obj) => ({
      [obj.candidacyForJobId]: obj.priority,
    }));
    items.forEach((item) => {
      body = { ...body, ...item };
    });
    return HttpClient.put(`${url}/priority/${candidaciesOfUserId}`, body);
  }

  static submit(candidaciesOfUserId) {
    return HttpClient.put(`${url}/submit/${candidaciesOfUserId}`, null);
  }

  static checkExpirations(candidaciesOfUserId) {
    return HttpClient.get(
      `${url}/check-expiration/${candidaciesOfUserId}`,
      null
    );
  }

  static getSummerizedCandidaciesOfUser(userId, seasonId) {
    return HttpClient.get(`${url}/summarized-candidacies-of-user`, {
      userId,
      seasonId,
    });
  }

  static createCandidacy(userId, jobId) {
    return HttpClient.post(`${url}/create-candidacy`, { jobId, userId });
  }

  static deleteCandidacyForJob(candidacyForJobId) {
    return HttpClient.delete(
      `${url}/delete-candidacy-job/${candidacyForJobId}`
    );
  }
}
