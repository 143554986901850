/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import questionImg from '../../../../assets/logos/question-mark.svg';
import QuestionAnswerService from '../../../services/questions-answers.service';
import NewContentContainer from '../new-content-container/new-content-container.component';

import './new-question.styles.scss';
import { setMessageError, setSuccessMessage } from '../../../store/events/events.actions';

const NewQuestion = (props) => {
  const { refetch } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  const createQuestionAnswer = (async () => {
    try {
      await QuestionAnswerService.create({ question, answer });
      refetch({});
      dispatch(setSuccessMessage());
      setQuestion('');
      setAnswer('');
    } catch (err) {
      dispatch(setMessageError(err));
    }
  });

  return (
    <NewContentContainer onSave={createQuestionAnswer}>
      <div className="header-container">
        <div className="question-mark">
          <img alt="question" src={questionImg} />
        </div>
        <div className="new-question-header">
          <span>{t('NEW_QUESTION')}</span>
        </div>
      </div>
      <textarea
        placeholder={t('TYPE_HERE')}
        className="input"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />
      <div className="answer-header">
        <span>{t('ANSWER')}</span>
      </div>
      <textarea
        placeholder={t('TYPE_HERE')}
        className="input"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
      />
    </NewContentContainer>
  );
};

NewQuestion.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default NewQuestion;
