import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';

import {
  setHeaderText,
  setIsIncludeSearchBar,
  setNamespace,
  setPlaceholder
} from '../../../../store/header-properties/header-properties.actions';
import './sidebar-item.styles.scss';

const SidebarItem = (props) => {
  const {
    sidebarHeader,
    pageHeader,
    logo,
    isCurrentPage,
    isIncludeSearchBar,
    placeholderForSearchBar,
    namespaceForHeader,
    setDispatchHeaderText,
    setDispatchIsIncludeSearchBar,
    setDispatchPlaceholder,
    setDispatchNamespace,
    dropDownOptions,
    onClick,
  } = props;

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isCurrentPage) {
      setDispatchHeaderText(pageHeader);
      setDispatchIsIncludeSearchBar(isIncludeSearchBar);
      setDispatchPlaceholder(placeholderForSearchBar);
      setDispatchNamespace(namespaceForHeader);
    }
  }, [isCurrentPage]);

  const toggleIsOpen = () => (
    setIsOpen(!isOpen)
  );

  const getDropDownItems = () => (
    dropDownOptions.map((option) => (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <a key={Symbol(sidebarHeader).toString()} className="nav-link" rel="noreferrer" target="_blank" href={option.path}>
        <ListItem classes={{ root: 'sidebar-item' }} button key={option.label}>
          <ListItemText disableTypography classes={{ root: 'text' }} primary={t(option.label)} />
        </ListItem>
      </a>
    ))
  );

  return (
    <>
      <ListItem
        classes={isCurrentPage ? { root: 'selected-item' } : { root: 'sidebar-item' }}
        button
        key={sidebarHeader}
        onClick={() => (onClick ? onClick() : dropDownOptions && toggleIsOpen())}
      >
        <ListItemIcon classes={{ root: 'sidebar-icon' }}><img alt={sidebarHeader} src={logo} /></ListItemIcon>
        <ListItemText disableTypography classes={{ root: 'text' }} primary={t(sidebarHeader)} />
        {dropDownOptions && (
        <Icon
          className="button"
        >
          {isOpen ? <KeyboardArrowUpIcon fontSize="meduim" /> : <KeyboardArrowDownIcon fontSize="meduim" />}
        </Icon>
        )}
      </ListItem>
      {isOpen && (
      <div className="manager-sidebar">
        <List>
          {getDropDownItems()}
        </List>
      </div>
      )}
    </>
  );
};

SidebarItem.propTypes = {
  pageHeader: PropTypes.string,
  sidebarHeader: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  isCurrentPage: PropTypes.bool.isRequired,
  isIncludeSearchBar: PropTypes.bool,
  placeholderForSearchBar: PropTypes.string,
  namespaceForHeader: PropTypes.string,
  setDispatchHeaderText: PropTypes.func.isRequired,
  setDispatchIsIncludeSearchBar: PropTypes.func.isRequired,
  setDispatchPlaceholder: PropTypes.func.isRequired,
  setDispatchNamespace: PropTypes.func.isRequired,
  dropDownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};

SidebarItem.defaultProps = {
  placeholderForSearchBar: '',
  namespaceForHeader: '',
  isIncludeSearchBar: false,
  pageHeader: '',
};

const mapDispatchToProps = (dispatch) => ({
  setDispatchHeaderText: (text) => dispatch(setHeaderText(text)),
  setDispatchIsIncludeSearchBar: (bool) => dispatch(setIsIncludeSearchBar(bool)),
  setDispatchPlaceholder: (text) => dispatch(setPlaceholder(text)),
  setDispatchNamespace: (text) => dispatch(setNamespace(text)),
});

export default connect(null, mapDispatchToProps)(SidebarItem);
