/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { PermissionType } from '@dashabim/users';
import { Redirect } from 'react-router-dom';
import Button from '../../common/components/button/button.component';
import EditorTable from '../../common/components/editor-table/editor-table.component';
import TextInput from '../../common/components/text-input/text-input.component';
import FilterBarItem from '../jobs/components/filter-bar-item/filter-bar-item.component';
import columns from './data-for-table';
import { ApplyCandidatesService } from '../../services/apply-candidates.service';
import { UsersService } from '../../services/users.service';
import ExportBySeasonDialog from '../../common/components/export-by-season-dialog/export-by-season-dialog';
import SelectUserPopup from '../../common/components/find-user-popup/select-user-popup.component';
import { createQueryFromSearchingUser } from '../../utils';
import AutoComplete from '../../common/components/auto-complete/auto-complete.component';
import SeasonsService from '../../services/seasons.service';
import {
  endLoading,
  setMessageError,
  startLoading,
} from '../../store/events/events.actions';
import { getSetupRows } from './setup-rows';
import styles from '../edit-dashab-status/edit-dashab-status.module.scss';
import { RankOptions } from '../../store/rank/rank-options';
import excelIcon from '../../../assets/logos/excel.svg';

const onCategoryChange = (field, setCategoryOnClick) => {
  setCategoryOnClick(field);
};

const createFields = (fields, t, setCategoryOnClick, currCategory) => fields.map((field) => (
  <FilterBarItem
    category={t(field)}
    onClickFunc={() => onCategoryChange(field, setCategoryOnClick)}
    selected={currCategory === field}
    key={`filter-bar--${field}`}
  />
));

const fields = ['JOB', 'CANDIDATE'];

const MyOfficerRequests = (props) => {
  // eslint-disable-next-line no-unused-vars
  const {
    permissions, unit, displayableProfessionalGroups, displayableUnits
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentCategory, setCurrentCategory] = useState(fields[0]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredRowsForTable, setFilteredRowsForTable] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [isExportDialogOpen, setExportDialog] = useState(false);
  const [usersOptions, setUsersOptions] = useState([]);
  const [isSelectUserDialogOpen, setIsSelectUserDialogOpen] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState('');
  const [seasons, setSeasons] = useState([]);
  const [myOfficers, setMyOfficers] = useState([]);
  const [wereUsersLoaded, setWereUsersLoaded] = useState(false);
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(8);
  const rowsPerPage = 8;
  const hasSearched = searchValue.length > 0;
  const isProfesionalGroupMode = (permissions.includes(PermissionType.professionalGroupWatch)
  || permissions.includes(PermissionType.professionalGroupAdmin) || permissions.includes(PermissionType.serenGroupAdmin)) && displayableProfessionalGroups;
  const isUnitMode = (permissions.includes(PermissionType.unitAdmin) || permissions.includes(PermissionType.serenUnitAdmin)) && displayableUnits;
  const rankFilter = {
    rank: RankOptions.slice(1, 3).map((rank) => rank.key),
  };
  const paginationFilter = {
    startIndex: page * rowsPerPage,
    endIndex: page * rowsPerPage + rowsPerPage,
  };
  const setupRows = getSetupRows();

  const calcFilter = () => {
    let filter = { unit };
    if (isProfesionalGroupMode) filter = { professionalGroups: displayableProfessionalGroups };
    if (isUnitMode) filter = { units: displayableUnits };

    return filter;
  };
  const permmisionFilter = calcFilter();

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getExcelDialog = () => {
    let dialog = (
      <ExportBySeasonDialog
        isOpen={isExportDialogOpen}
        setIsOpen={setExportDialog}
        unit={unit}
      />
    );
    if (isProfesionalGroupMode) {
      dialog = (
        <ExportBySeasonDialog
          isOpen={isExportDialogOpen}
          setIsOpen={setExportDialog}
          professionalGroups={displayableProfessionalGroups}
          isMultiRank
        />
      );
    }
    if (isUnitMode) {
      dialog = (
        <ExportBySeasonDialog
          isOpen={isExportDialogOpen}
          setIsOpen={setExportDialog}
          units={displayableUnits}
          isMultiRank
        />
      );
    }

    return dialog;
  };

  const filterBySeason = (candidate) => {
    if (!selectedSeason || selectedSeason.seasonId === null) {
      return true;
    }
    return candidate.seasonName === selectedSeason.seasonName;
  };

  const sortBySeason = (a, b) => (a.seasonId > b.seasonId ? 1 : -1);

  const addResultsToState = (result, isSeason = false) => {
    const sortedResult = result.sort(sortBySeason);
    setAllRows(sortedResult);
    let resultFilteredBySeason;
    if (!isSeason) {
      resultFilteredBySeason = sortedResult.filter(filterBySeason);
    }
    setFilteredRowsForTable(setupRows(resultFilteredBySeason ?? sortedResult));
  };

  const findCandidateStatusByUserId = async (idOrIds) => {
    const isMultiIds = idOrIds instanceof Array;
    const idsFilter = !isMultiIds ? { userId: idOrIds } : { userIds: idOrIds };
    try {
      dispatch(startLoading());
      const query = {
        ...idsFilter,
        wasSubmitted: true,
      };
      const result = await ApplyCandidatesService.candidateStatus({
        ...query,
        ...paginationFilter,
      }, isMultiIds);
      if (!result.length) throw new Error('לא נמצאו מועמדויות');
      addResultsToState(result);

      // set pagination count
      setCount(
        await ApplyCandidatesService.candidateStatus({
          ...query,
          isAmount: true,
        }, isMultiIds)
      );
    } catch (err) {
      dispatch(setMessageError(err));
    } finally {
      dispatch(endLoading());
    }
  };

  const setUpInitialUsers = async () => {
    try {
      dispatch(startLoading());
      // set users
      const wantedUsers = await UsersService.getByFilter({
        ...permmisionFilter,
        ...rankFilter,
      });
      if (!wantedUsers.length) {
        throw new Error(t('ERROR.USER_NOT_FOUND'));
      }
      setMyOfficers(wantedUsers);
      setWereUsersLoaded(true);
    } catch (err) {
      dispatch(setMessageError(err));
    } finally {
      dispatch(endLoading());
    }
  };

  const setUpInitialCandidates = async () => {
    await findCandidateStatusByUserId(
      myOfficers.filter((user) => !!user.id).map((user) => user.id)
    );
  };

  useEffect(async () => {
    // init seasons
    dispatch(startLoading());

    const allSeasons = await SeasonsService.getSeasonsByFilter({});
    const sortedSeasons = allSeasons.sort(
      (firstSeason, secondSeason) => new Date(secondSeason.startDate) - new Date(firstSeason.startDate)
    );
    const seasonsTitle = [{ seasonId: null, seasonName: t('ALL_SEASONS') }];
    sortedSeasons.forEach((season) => seasonsTitle.push({ seasonId: season.id, seasonName: season.title }));
    setSeasons(seasonsTitle);
    if (seasonsTitle.length > 0) {
      setSelectedSeason(seasonsTitle[0]);
    }

    // init users
    await setUpInitialUsers();
    dispatch(endLoading());
  }, []);

  useEffect(async () => {
    if (myOfficers.length) {
      await setUpInitialCandidates();
    }
  }, [myOfficers]);

  const setUpSelectUserPopup = (wantedUsers) => {
    setUsersOptions(wantedUsers);
    setIsSelectUserDialogOpen(true);
  };

  const onSearchByCandidate = async (shouldResetPage) => {
    if (!hasSearched) {
      await setUpInitialCandidates();
      return;
    }
    try {
      let wantedUsers;
      const query = createQueryFromSearchingUser(searchValue);
      if (!query) {
        wantedUsers = await UsersService.getByFilter({
          armyId: searchValue,
          ...permmisionFilter,
          ...rankFilter,
        });
      } else {
        wantedUsers = await UsersService.getByFilter({
          ...query,
          ...permmisionFilter,
          ...rankFilter,
        });
      }
      if (!wantedUsers.length) {
        setSearchValue('');
        throw new Error(t('ERROR.USER_NOT_FOUND'));
      } else if (wantedUsers.length === 1) {
        findCandidateStatusByUserId(wantedUsers[0].id);
      } else setUpSelectUserPopup(wantedUsers);

      if (shouldResetPage) {
        setPage(0);
      }
    } catch (err) {
      dispatch(endLoading());
      dispatch(setMessageError(err));
    }
  };

  const onSearchByJob = async (shouldResetPage) => {
    if (!hasSearched) {
      await setUpInitialCandidates();
      return;
    }
    try {
      const query = {
        jobTitle: searchValue,
        wasSubmitted: true,
        ...permmisionFilter,
      };
      const result = await ApplyCandidatesService.candidateStatus({
        ...query,
        ...{ startIndex: 0, endIndex: rowsPerPage },
      });
      if (!result.length) {
        setSearchValue('');
        throw new Error(t('ERROR.JOB_NOT_FOUND'));
      }
      addResultsToState(result);

      // set pagination count
      setCount(
        await ApplyCandidatesService.candidateStatus({
          ...query,
          isAmount: true,
        })
      );

      if (shouldResetPage) {
        setPage(0);
      }
    } catch (err) {
      dispatch(endLoading());
      dispatch(setMessageError(err));
    }
  };

  const openExportDialog = () => {
    setExportDialog(!isExportDialogOpen);
  };

  useEffect(() => {
    setupRows(allRows.filter(filterBySeason));
    setFilteredRowsForTable(setupRows(allRows.filter(filterBySeason)));
  }, [selectedSeason]);

  useEffect(async () => {
    setSearchValue('');
    setPage(0);
  }, [currentCategory]);

  const searchCandidacies = async (shouldResetPage = true) => {
    dispatch(startLoading());
    if (currentCategory === 'CANDIDATE') {
      onSearchByCandidate(shouldResetPage);
    } else {
      onSearchByJob(shouldResetPage);
    }
    dispatch(endLoading());
  };

  useEffect(async () => {
    if (wereUsersLoaded) {
      await setUpInitialCandidates();
    }
  }, [currentCategory]);

  useEffect(async () => {
    if (wereUsersLoaded) {
      searchCandidacies(false);
    }
  }, [page]);

  const onChangeAutocomplete = (season) => {
    setSelectedSeason(season);
  };

  return (
    <div className={styles.page}>
      <div className={styles.page__filter}>
        <div className={styles.page__categories}>
          {createFields(fields, t, setCurrentCategory, currentCategory)}
          <AutoComplete
            value={selectedSeason}
            onChange={(newValue) => onChangeAutocomplete(newValue)}
            options={seasons}
          />
        </div>
        <TextInput
          color="white"
          isSearchInput
          onSearch={() => searchCandidacies()}
          placeholder={`${t('SEARCH')} ${t(currentCategory)}`}
          onChange={(value) => setSearchValue(value)}
          boundValue={searchValue}
        />
      </div>
      <EditorTable
        rows={[...filteredRowsForTable]}
        columns={columns}
        pagination={{
          page,
          count,
          rowsPerPage,
          onChangePage,
        }}
      />
      <div className={styles.page__buttons}>
        <div className={styles['export-buttons']}>
          <Button
            text="EXPORT_EXCEL_BY_SEASON"
            image={excelIcon}
            isHollow
            onClick={openExportDialog}
          />
        </div>
      </div>
      <SelectUserPopup
        isOpen={isSelectUserDialogOpen}
        setIsOpen={setIsSelectUserDialogOpen}
        users={usersOptions}
        onPopupCancel={() => {}}
        setSelectedUser={(user) => findCandidateStatusByUserId(user.id)}
      />
      <div>
        {getExcelDialog()}
        {permissions.includes(PermissionType.Manager)
      || permissions.includes(PermissionType.SystemAdmin)
      || permissions.includes(PermissionType.Watch)
      || permissions.includes(PermissionType.professionalGroupWatch)
      || permissions.includes(PermissionType.professionalGroupAdmin)
      || permissions.includes(PermissionType.unitAdmin)
      || permissions.includes(PermissionType.serenGroupAdmin)
      || permissions.includes(PermissionType.serenUnitAdmin) ? (
        <></>
          ) : (
            <Redirect to="/" />
          )}
      </div>
    </div>
  );
};

MyOfficerRequests.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  permissions: [],
};

MyOfficerRequests.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  unit: PropTypes.string,
  displayableProfessionalGroups: PropTypes.arrayOf(PropTypes.string),
  displayableUnits: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  permissions: state.user.permissions,
  unit: state.user.unit,
  displayableProfessionalGroups: state.user.displayableProfessionalGroups,
  displayableUnits: state.user.displayableUnits,
});

export default connect(mapStateToProps)(MyOfficerRequests);
