/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import DetailsBlock from '../details-block/details-block.component';
import Select from '../../../../common/components/select/select.component';

import styles from './priority.module.scss';

const Priority = (props) => {
  const {
    jobs, onChange, watchMode, watchData
  } = props;
  const [selectValues, setSelectValues] = useState(jobs);
  const [selectedJobs, setSelectedJobs] = useState({});
  const [watchModeJobs, setWatchModeJobs] = useState([]);

  useEffect(() => {
    onChange(selectedJobs);
  }, [selectedJobs]);

  useEffect(async () => {
    if (watchMode && watchData.length) {
      setWatchModeJobs(watchData.filter((job) => job !== null));
    }
  }, [watchData]);

  const handleChange = (candidacyForJobId, index) => {
    if (!candidacyForJobId) {
      setSelectValues([...selectValues, selectedJobs[index]]);
      const { [index]: _, ...newSelected } = selectedJobs;
      setSelectedJobs(newSelected);
    } else if (typeof selectedJobs[index] !== 'undefined') {
      const newSelectValues = [...selectValues];
      const selectedObject = newSelectValues.splice(
        newSelectValues.findIndex(
          (element) => element.candidacyForJobId === candidacyForJobId
        ),
        1
      )[0];
      setSelectValues([...newSelectValues, selectedJobs[index]]);
      setSelectedJobs({
        ...selectedJobs,
        [index]: { ...selectedObject, priority: index + 1 },
      });
    } else {
      const newSelectValues = [...selectValues];
      const selectedObject = newSelectValues.splice(
        newSelectValues.findIndex(
          (element) => element.candidacyForJobId === candidacyForJobId
        ),
        1
      )[0];
      setSelectValues(newSelectValues);
      setSelectedJobs({
        ...selectedJobs,
        [index]: { ...selectedObject, priority: index + 1 },
      });
    }
  };

  useEffect(() => {
    let selected = {};
    const unselected = [];
    selectValues.forEach((value) => {
      if (value.priority) {
        selected = {
          ...selected,
          [value.priority - 1]: {
            priority: value.priority,
            candidacyForJobId: value.candidacyForJobId,
            jobTitle: value.jobTitle,
            id: value.id,
          },
        };
      } else {
        unselected.push({
          candidacyForJobId: value.candidacyForJobId,
          jobTitle: value.jobTitle,
          id: value.id,
        });
      }
    });
    if (Object.keys(selected).length) {
      setSelectedJobs(selected);
      setSelectValues(unselected);
    }
  }, []);

  if (!watchMode) {
    return (
      <DetailsBlock header="PRIORITY" additionalClassName={styles.priority}>
        <>
          {jobs.map((_job, index) => {
            if (typeof selectedJobs[index] !== 'undefined') {
              return (
                <div key={selectedJobs[index].candidacyForJobId}>
                  <span>{index + 1}</span>
                  <Select
                    options={[
                      { key: '', value: '' },
                      ...selectValues.map((job) => ({
                        key: job.candidacyForJobId,
                        value: job.jobTitle,
                      })),
                      {
                        key: selectedJobs[index].candidacyForJobId,
                        value: selectedJobs[index].jobTitle,
                      },
                    ]}
                    selected={selectedJobs[index].candidacyForJobId}
                    onChange={(e) => handleChange(e.target.value, index)}
                  />
                </div>
              );
            }
            return (
              <div key={uuid()}>
                <span>{index + 1}</span>
                <Select
                  options={[
                    { key: '', value: '' },
                    ...selectValues.map((job) => ({
                      key: job.candidacyForJobId,
                      value: job.jobTitle,
                    })),
                  ]}
                  selected=""
                  onChange={(e) => handleChange(e.target.value, index)}
                />
              </div>
            );
          })}
        </>
      </DetailsBlock>
    );
  }
  if (watchData && watchModeJobs.length) {
    return (
      <DetailsBlock header="PRIORITY" additionalClassName={styles.priority}>
        <>
          {watchModeJobs.map((candidacy) => (
            <div key={candidacy.priority}>
              <span>{candidacy.priority}</span>
              <Select
                disabled
                options={[
                  { key: candidacy.priority, value: candidacy.jobTitle },
                ]}
                selected={candidacy.priority}
              />
            </div>
          ))}
        </>
      </DetailsBlock>
    );
  }
  return <></>;
};

Priority.propTypes = {
  jobs: PropTypes.arrayOf(PropTypes.shape()),
  watchMode: PropTypes.bool,
  watchData: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
};

Priority.defaultProps = {
  watchMode: false,
  watchData: [],
  jobs: [],
};

export default Priority;
