import React from 'react';
import PropTypes from 'prop-types';

import questionMark from '../../../../../assets/logos/question-mark.svg';
import leftArrow from '../../../../../assets/logos/left-arrow.svg';
import './question-block.styles.scss';

const QuestionBlock = (props) => {
  const { question, onClick, selectedId } = props;
  return (
    <div className={`question-block${selectedId === question.id ? '-selected' : ''}`}>
      <button type="button" onClick={() => onClick(question)}>
        <img src={questionMark} className="question-mark-logo" alt="question" />
        <span className="question-content">{question.question}</span>
        <img src={leftArrow} className="left-arrow-logo" alt="question" />
      </button>
    </div>
  );
};

QuestionBlock.propTypes = {
  question: PropTypes.shape({
    answer: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedId: PropTypes.string.isRequired
};

export default QuestionBlock;
