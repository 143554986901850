/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as fileSaver from 'file-saver';
import { PermissionType } from '@dashabim/users';
import { produceExcelService } from '../../services/produce-excel.service';
import Button from '../../common/components/button/button.component';
import EditorTable from '../../common/components/editor-table/editor-table.component';
import TextInput from '../../common/components/text-input/text-input.component';
import FilterBarItem from '../jobs/components/filter-bar-item/filter-bar-item.component';
import columns from './data-for-table';
import SnackbarSuccess from '../../common/components/snackbar-success/snackbar-success.component';
import { ApplyCandidatesService } from '../../services/apply-candidates.service';
import { UsersService } from '../../services/users.service';
import { CandidaciesForJobsService } from '../../services/candidacies-for-jobs.service';
import ExportBySeasonDialog from '../../common/components/export-by-season-dialog/export-by-season-dialog';
import SelectUserPopup from '../../common/components/find-user-popup/select-user-popup.component';
import { createQueryFromSearchingUser } from '../../utils';
import AutoComplete from '../../common/components/auto-complete/auto-complete.component';
import SeasonsService from '../../services/seasons.service';
import {
  endLoading,
  setMessageError,
  setSuccessMessage,
  startLoading,
} from '../../store/events/events.actions';
import { getSetupRows } from './setup-rows';
import styles from './edit-dashab-status.module.scss';
import { FileService } from '../../services/file.service';
import excelIcon from '../../../assets/logos/excel.svg';

const onCategoryChange = (field, setCategoryOnClick, setDisplayExcelButton) => {
  setCategoryOnClick(field);
  setDisplayExcelButton(false);
};

const createFields = (
  fields,
  t,
  setCategoryOnClick,
  currCategory,
  setDisplayExcelButton
) => fields.map((field) => (
  <FilterBarItem
    category={t(field)}
    onClickFunc={() => onCategoryChange(field, setCategoryOnClick, setDisplayExcelButton)}
    selected={currCategory === field}
    key={`filter-bar--${field}`}
  />
));

const fields = ['JOB', 'CANDIDATE'];

const EditDashabStatusPage = (props) => {
  const {
    displayableProfessionalGroups,
    displayableUnits,
    permissions,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentCategory, setCurrentCategory] = useState(fields[0]);
  const [searchValue, setSearchValue] = useState('');
  const [statusText, setStatusText] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filteredRowsForTable, setFilteredRowsForTable] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [dataToUpdate, setDataToUpdate] = useState({});
  const [displayExcelButton, setDisplayExcelButton] = useState(false);
  const [isExportDialogOpen, setExportDialog] = useState(false);
  const [usersOptions, setUsersOptions] = useState([]);
  const [isSelectUserDialogOpen, setIsSelectUserDialogOpen] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState('');
  const [seasons, setSeasons] = useState([]);
  const hasSearched = searchValue.length > 1;
  const isProfessionalGroupAdminMode = (permissions.includes(PermissionType.professionalGroupAdmin) || permissions.includes(PermissionType.serenGroupAdmin))
    && displayableProfessionalGroups
    && displayableProfessionalGroups.length > 0;
  const isUnitAdminMode = (permissions.includes(PermissionType.unitAdmin) || permissions.includes(PermissionType.serenUnitAdmin))
    && displayableUnits
    && displayableUnits.length > 0;

  const produceExcelByJob = async (jobId, jobName) => {
    const resData = await produceExcelService.getByJob(jobId);
    fileSaver.saveAs(resData, `${jobName}.xlsx`);
  };

  const downloadFile = async (id, armyId) => {
    try {
      // eslint-disable-next-line no-unused-vars
      await FileService.downloadFile(id, armyId);
    } catch (err) {
      dispatch(setMessageError(err)); // change error message
    }
  };

  // eslint-disable-next-line no-unused-vars
  const uploadFile = async (fileData) => {
    try {
      const uploadedFile = await FileService.uploadFile(fileData);
      if (uploadedFile) {
        setStatusText('SUCCESS.ACTION');
        setTimeout(() => {
          setStatusText(null);
        }, 2500);
      }
      dispatch(startLoading());
      // eslint-disable-next-line no-use-before-define
      await searchCandidacies();
      dispatch(endLoading());
    } catch (err) {
      dispatch(setMessageError(err)); // change error message
    } finally {
      dispatch(endLoading());
    }
  };

  const handleDeleteCandidacy = async (candidacy) => {
    dispatch(startLoading());
    try {
      await ApplyCandidatesService.deleteCandidacyForJob(candidacy.id);
      // window.location = window.origin;
      setFilteredRowsForTable((rows) => rows.filter((row) => row.id !== candidacy.id));
      setStatusText('SUCCESS.ACTION');
      setTimeout(() => {
        setStatusText(null);
      }, 2500);
    } catch (err) {
      dispatch(setMessageError(err));
    } finally {
      dispatch(endLoading());
    }
  };

  const produceExcelByPerson = async () => {
    const users = await UsersService.getByFilter({ armyId: allRows[0].armyId });
    const resData = await produceExcelService.getByPerson(users[0].id);
    fileSaver.saveAs(resData, `${users[0].armyId}.xlsx`);
  };

  const produceExcel = async (jobId, jobName) => {
    if (currentCategory === 'CANDIDATE' && allRows.length) {
      await produceExcelByPerson();
    } else {
      await produceExcelByJob(jobId, jobName);
    }
  };

  const getExcelDialog = () => {
    let dialog = (
      <ExportBySeasonDialog
        isOpen={isExportDialogOpen}
        setIsOpen={setExportDialog}
      />
    );
    if (isProfessionalGroupAdminMode) {
      dialog = (
        <ExportBySeasonDialog
          isOpen={isExportDialogOpen}
          setIsOpen={setExportDialog}
          professionalGroups={displayableProfessionalGroups}
          isMultiRank
        />
      );
    }
    if (isUnitAdminMode) {
      dialog = (
        <ExportBySeasonDialog
          isOpen={isExportDialogOpen}
          setIsOpen={setExportDialog}
          units={displayableUnits}
          isMultiRank
        />
      );
    }

    return dialog;
  };

  const handleChange = (e, candidacyForJobId, key) => {
    const toUpdate = {
      [candidacyForJobId]: { ...dataToUpdate[candidacyForJobId] },
    };
    if (e.target.value === 'DASHAB_PASS' || e.target.value === 'BUNCH_PASS') {
      toUpdate[candidacyForJobId][key] = true;
    }
    if (
      e.target.value === 'DASHAB_NOT_PASSED'
      || e.target.value === 'BUNCH_NOT_PASSED'
    ) {
      toUpdate[candidacyForJobId][key] = false;
    }
    if (e.target.value === '') {
      toUpdate[candidacyForJobId][key] = undefined;
    }
    if (key === 'didEnterBunch' && !toUpdate[candidacyForJobId][key]) {
      toUpdate[candidacyForJobId].didPassDashab = undefined;
    }
    setDataToUpdate((prevState) => ({ ...prevState, ...toUpdate }));
  };

  const isDisabled = (status) => {
    if (dataToUpdate[status.id]?.didEnterBunch) return false;
    if (
      status.didEnterBunch
      // eslint-disable-next-line no-prototype-builtins
      && !dataToUpdate[status.id]?.hasOwnProperty('didEnterBunch')
    ) {
      return false;
    }
    return true;
  };

  const setupRows = getSetupRows(
    handleChange,
    handleDeleteCandidacy,
    t,
    produceExcel,
    isDisabled,
    uploadFile,
    downloadFile
  );

  useEffect(() => {
    setFilteredRowsForTable(setupRows(filteredRows));
  }, [dataToUpdate]);

  const filterBySeason = (candidate) => {
    if (!selectedSeason || selectedSeason.seasonId === null) {
      return true;
    }
    return candidate.seasonName === selectedSeason.seasonName;
  };

  const sortBySeason = (a, b) => (a.seasonId > b.seasonId ? 1 : -1);

  const addResultsToState = (result, isSeason = false) => {
    const sortedResult = result.sort(sortBySeason);
    setAllRows(sortedResult);
    let resultFilteredBySeason;
    if (!isSeason) {
      resultFilteredBySeason = sortedResult.filter(filterBySeason);
    }
    setFilteredRows(resultFilteredBySeason ?? sortedResult);
    setFilteredRowsForTable(setupRows(resultFilteredBySeason ?? sortedResult));
  };

  const findCandidateStatusByUserId = async (userId) => {
    if (!hasSearched) return;
    try {
      const query = {
        userId,
        wasSubmitted: true,
      };
      if (isProfessionalGroupAdminMode) {
        query.unitGroups = displayableProfessionalGroups;
      }
      if (isUnitAdminMode) {
        query.units = displayableUnits;
      }
      const result = await ApplyCandidatesService.candidateStatus(query);
      if (!result.length) {
        throw new Error('לא נמצאו מועמדויות');
      }
      addResultsToState(result);
    } catch (err) {
      dispatch(setMessageError(err));
    }
  };

  const setUpSelectUserPopup = (users) => {
    setUsersOptions(users);
    setIsSelectUserDialogOpen(true);
  };

  const onSearchByCandidate = async () => {
    if (!hasSearched) return;
    try {
      const query = createQueryFromSearchingUser(searchValue);
      if (isProfessionalGroupAdminMode) {
        query.professionalGroups = displayableProfessionalGroups;
      }
      if (isUnitAdminMode) {
        query.units = displayableUnits;
      }
      const users = await UsersService.getByFilter(query);
      if (!users.length) {
        throw new Error(t('ERROR.USER_NOT_FOUND'));
      } else if (users.length === 1) findCandidateStatusByUserId(users[0].id);
      else setUpSelectUserPopup(users);
    } catch (err) {
      dispatch(endLoading());
      dispatch(setMessageError(err));
    }
  };

  const onSearchByJob = async () => {
    if (!hasSearched) return;
    const query = {
      jobTitle: searchValue,
      wasSubmitted: true,
    };
    if (isProfessionalGroupAdminMode) {
      query.unitGroups = displayableProfessionalGroups;
    }
    if (isUnitAdminMode) {
      query.units = displayableUnits;
    }
    try {
      const result = await ApplyCandidatesService.candidateStatus(query);
      if (!result.length) throw new Error(t('ERROR.JOB_NOT_FOUND'));
      addResultsToState(result);
    } catch (err) {
      dispatch(endLoading());
      dispatch(setMessageError(err));
    }
  };

  const getUpdatedResultsArray = (updatedCandidacies) => {
    const oldCandidacies = allRows.filter((row) => updatedCandidacies.map((candidacy) => candidacy.id).includes(row.id));
    const updatedPopulatedCandidacies = updatedCandidacies.map((candidacy) => ({
      ...oldCandidacies.find(
        (oldCandidacy) => oldCandidacy.id === candidacy.id
      ),
      ...candidacy,
    }));
    return [
      ...allRows.filter(
        (row) => !updatedCandidacies.map((candidacy) => candidacy.id).includes(row.id)
      ),
      ...updatedPopulatedCandidacies,
    ];
  };

  const submitUpdate = async () => {
    try {
      dispatch(startLoading());
      const updatedCandidacies = await Promise.all(
        Object.keys(dataToUpdate).map(async (key) => CandidaciesForJobsService.updateCandidacyById(key, dataToUpdate[key]))
      );
      const updatedResults = getUpdatedResultsArray(updatedCandidacies);
      addResultsToState(updatedResults);
      dispatch(setSuccessMessage());
      setDataToUpdate({});
    } catch (err) {
      dispatch(setMessageError(err));
    } finally {
      dispatch(endLoading());
    }
  };

  const openExportDialog = () => {
    setExportDialog(!isExportDialogOpen);
  };

  useEffect(async () => {
    const allSeasons = await SeasonsService.getSeasonsByFilter({});
    const sortedSeasons = allSeasons.sort(
      (firstSeason, secondSeason) => new Date(secondSeason.startDate) - new Date(firstSeason.startDate)
    );
    const seasonsTitle = [{ seasonId: null, seasonName: t('ALL_SEASONS') }];
    sortedSeasons.forEach((season) => seasonsTitle.push({ seasonId: season.id, seasonName: season.title }));
    setSeasons(seasonsTitle);
    if (seasonsTitle.length > 0) {
      setSelectedSeason(seasonsTitle[0]);
    }
  }, []);

  useEffect(() => {
    dispatch(startLoading());
    setupRows(allRows.filter(filterBySeason));
    dispatch(endLoading());
    setFilteredRowsForTable(setupRows(allRows.filter(filterBySeason)));
    dispatch(endLoading());
  }, [selectedSeason]);

  useEffect(() => {
    setFilteredRowsForTable([]);
    setSearchValue('');
  }, [currentCategory]);

  const searchCandidacies = async () => {
    dispatch(startLoading());
    if (currentCategory === 'CANDIDATE') {
      onSearchByCandidate();
    } else {
      onSearchByJob();
    }
    dispatch(endLoading());
  };

  const onChangeAutocomplete = (season) => {
    setSelectedSeason(season);
  };
  return (
    <div className={styles.page}>
      {statusText && <SnackbarSuccess textSuccess="SUCCESS.ACTION" />}

      <div className={styles.page__filter}>
        <div className={styles.page__categories}>
          {createFields(
            fields,
            t,
            setCurrentCategory,
            currentCategory,
            setDisplayExcelButton
          )}
          <AutoComplete
            value={selectedSeason}
            onChange={(newValue) => onChangeAutocomplete(newValue)}
            options={seasons}
          />
        </div>
        <TextInput
          color="white"
          isSearchInput
          onSearch={() => searchCandidacies()}
          placeholder={`${t('SEARCH')} ${t(currentCategory)}`}
          onChange={(value) => setSearchValue(value)}
          boundValue={searchValue}
        />
      </div>
      <EditorTable
        rows={[...filteredRowsForTable]}
        columns={columns}
      />
      <div className={styles.page__buttons}>
        <Button text="UPDATE" onClick={submitUpdate} />
        <div className={styles['export-buttons']}>
          <Button
            text="EXPORT_EXCEL_BY_SEASON"
            image={excelIcon}
            isHollow
            onClick={openExportDialog}
          />
          {displayExcelButton && (
            <Button
              text="EXPORT_EXCEL"
              onClick={produceExcel}
              image={excelIcon}
              isHollow
            />
          )}
        </div>
      </div>
      <SelectUserPopup
        isOpen={isSelectUserDialogOpen}
        setIsOpen={setIsSelectUserDialogOpen}
        users={usersOptions}
        onPopupCancel={() => {}}
        setSelectedUser={(user) => findCandidateStatusByUserId(user.id)}
      />
      {permissions.includes(PermissionType.Manager)
      || permissions.includes(PermissionType.SystemAdmin)
      || isProfessionalGroupAdminMode
      || isUnitAdminMode ? (
        <></>
        ) : (
          <Redirect to="/" />
        )}
      <div>{getExcelDialog()}</div>
    </div>
  );
};

EditDashabStatusPage.defaultProps = {
  permissions: [],
};

EditDashabStatusPage.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  displayableProfessionalGroups: PropTypes.arrayOf(PropTypes.string),
  displayableUnits: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  permissions: state.user.permissions,
  userId: state.user.id,
  displayableProfessionalGroups: state.user.displayableProfessionalGroups,
  displayableUnits: state.user.displayableUnits,
});

export default connect(mapStateToProps)(EditDashabStatusPage);
