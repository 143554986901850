import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './checkbox-list.styles.scss';
import { Checkbox } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const CheckboxList = (props) => {
  const {
    options,
    checkedPermissions,
    disabled,
    onChange,
    isMaxHeight,
    title,
    isRadioMode
  } = props;
  const { t } = useTranslation();

  const handleOnChange = (optionKey) => {
    onChange(optionKey);
  };

  return (
    <>
      {
        title && <span>{title}</span>
      }
      <div className={`${isMaxHeight ? 'max-height' : ''} `}>
        { options.map((option) => (
          <div className="container" key={option.key}>
            <div className="wrapper">
              <Checkbox
                icon={isRadioMode ? <RadioButtonUncheckedIcon /> : <CheckBoxOutlineBlankIcon />}
                checkedIcon={isRadioMode ? <RadioButtonCheckedIcon /> : <CheckBoxIcon />}
                className={`${disabled.includes(option.key) ? 'disabledCheckbox' : 'checkbox'}`}
                disabled={disabled.includes(option.key)}
                checked={checkedPermissions && checkedPermissions.includes(option.key)}
                onChange={() => handleOnChange(option.key)}
              />
              <span className="checkmark">{t(option.value)}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

CheckboxList.defaultProps = {
  isMaxHeight: false,
  isRadioMode: false,
};

CheckboxList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  checkedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  isMaxHeight: PropTypes.bool,
  isRadioMode: PropTypes.bool,
  title: PropTypes.string,
};

export default CheckboxList;
