const columns = [
  {
    id: 'checkSeason',
    label: 'צפה במועמד',
  },
  {
    id: 'delete',
    label: 'הסר התמודדות',
  },
  {
    id: 'fullName',
    label: 'FULL_NAME',
  },
  {
    id: 'armyId',
    label: 'ARMY_ID',
  },
  {
    id: 'seasonName',
    label: 'SEASON',
  },
  {
    id: 'didEnterBunch',
    label: 'DID_ENTER_BUNCH',
  },
  {
    id: 'priority',
    label: 'PRIORITY'
  },
  {
    id: 'jobTitle',
    label: 'JOB',
  },
  {
    id: 'mainUnit',
    label: 'UNIT',
  },
  {
    id: 'unitGroup',
    label: 'UNITGROUP',
  },
  {
    id: 'didPassDashab',
    label: 'DID_PASS_DASHAB',
  },
  {
    id: 'upload',
    label: 'FILE_UPLOAD',
  },
  {
    id: 'download',
    label: 'FILE_DOWNLOAD',
  },
];

export default columns;
