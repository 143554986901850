/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import './add-season-dialog.styles.scss';
import Dialog from '../../../../common/components/dialog/dialog.component';
import TextInput from '../../../../common/components/text-input/text-input.component';
import Button from '../../../../common/components/button/button.component';
import Select from '../../../../common/components/select/select.component';
import SeasonsService from '../../../../services/seasons.service';
import { changeDateFormat } from '../../../../utils';
import { config } from '../../../../config';
import {
  endLoading,
  setMessageError,
  setSuccessMessage,
  startLoading
} from '../../../../store/events/events.actions';
import { RankOptions } from '../../../../store/rank/rank-options';

const boolOptions = [
  { key: '', value: '' },
  { key: true, value: 'TRUE' },
  { key: false, value: 'FALSE' }
];

const formWithBackEndStringFormat = (form) => {
  const newForm = {
    ...form,
    startDate: changeDateFormat(form.startDate),
    endDate: changeDateFormat(form.endDate),
  };
  return newForm;
};

const dateToHtmlDate = (date) => new Date(date).toISOString().split('T')[0];

const AddSeasonDialog = (props) => {
  const {
    isOpen,
    setIsOpen,
    refetch,
    editData,
  } = props;

  const dispatch = useDispatch();

  const [form, setForm] = useState({});
  const [canSubmit, setCanSumbit] = useState(false);

  const editMode = editData && !!Object.keys(editData).length;

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (editMode) {
      setForm(() => ({ ...editData, startDate: dateToHtmlDate(editData.startDate), endDate: dateToHtmlDate(editData.endDate) }));
    }
  }, [editData]);

  useEffect(() => {
    setCanSumbit(form.endDate && form.startDate && form.title && form.rank && form.rbz !== '' && form.rbz !== undefined);
  }, [form]);

  const handleSaveNewSeason = async () => {
    await SeasonsService.createSeason(formWithBackEndStringFormat(form));
  };

  const handleUpdateSeason = async () => {
    const {
      id,
      updatedAt,
      updatedBy,
      rbz,
      rank,
      ...updateForm
    } = form;
    await SeasonsService.updateById(id, formWithBackEndStringFormat(updateForm));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(startLoading());
    try {
      if (editMode) {
        await handleUpdateSeason();
      } else await handleSaveNewSeason();
      dispatch(setSuccessMessage());
      setForm({});
      setIsOpen(false);
      refetch();
    } catch (err) {
      dispatch(setMessageError(err));
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <div>
      <Dialog isOpen={isOpen} handleClose={handleClose} header={editMode ? 'EDIT_SEASON' : 'ADD_SEASON'}>
        <form className="form-add-season" onSubmit={(e) => handleSubmit(e)}>
          <div className="inputs">
            <TextInput required onChange={(value) => setForm({ ...form, title: value })} boundValue={form.title} inverted header="SEASON_TITLE" />
            <TextInput required type="date" onChange={(value) => setForm({ ...form, startDate: value })} boundValue={form.startDate} inverted header="START_DATE" />
            <TextInput required type="date" min={form.startDate} onChange={(value) => setForm({ ...form, endDate: value })} boundValue={form.endDate} inverted header="END_DATE" />
            <Select required disabled={editMode} onChange={(e) => setForm({ ...form, rank: e.target.value })} inverted header="RANK" selected={form.rank || ''} options={RankOptions} />
            <Select required disabled={editMode} onChange={(e) => setForm({ ...form, rbz: e.target.value })} inverted header="IS_RBZ" selected={form.rbz ?? ''} options={boolOptions} />
            <TextInput type="number" min={config.minCandidaciesAmount} boundValue={form.candidaciesAmountLimit} onChange={(value) => setForm({ ...form, candidaciesAmountLimit: value })} inverted header="CANDIDACIES_AMOUNT_LIMIT" />
          </div>
          <div className="buttons">
            <Button text="SEND" isSubmit disabled={!canSubmit} />
          </div>
        </form>
      </Dialog>
    </div>

  );
};

AddSeasonDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  editData: PropTypes.shape(),
  refetch: PropTypes.func.isRequired
};

AddSeasonDialog.defaultProps = {
  editData: {}
};

export default AddSeasonDialog;
