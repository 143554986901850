import { HttpClient } from '../http.client';
import { config } from '../config';

const url = config.endpoints.seasonsApi;

class SeasonsService {
  static async getSeasonsByFilter(filter) {
    return HttpClient.get(url, filter);
  }

  static async updateById(id, body) {
    return HttpClient.put(`${url}/${id}`, body);
  }

  static async createSeason(body) {
    return HttpClient.post(url, body);
  }
}

export default SeasonsService;
