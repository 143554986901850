/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import { IconButton } from '@material-ui/core';

import './desired-job.styles.scss';
import { removeJob } from '../../../../store/desired-jobs/desired-jobs.actions';
import icon from '../../../../../assets/logos/delete.png';
import {
  endLoading,
  setMessageError,
  startLoading,
} from '../../../../store/events/events.actions';

const DesiredJob = (props) => {
  const {
    refId,
    text,
    removeJobDispatch,
    seasonId,
    didSeasonCandidateSent,
    isEnabled,
  } = props;

  const dispatch = useDispatch();

  const handleRemoveJob = () => {
    dispatch(startLoading());
    try {
      removeJobDispatch({ id: refId }, seasonId);
    } catch (err) {
      dispatch(setMessageError(err));
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <div
      className={`desire-job desire-job--animated ${(didSeasonCandidateSent) && 'disabled'}`}
    >
      <div className="desire-job--text" disabled>
        {text}
      </div>
      {!didSeasonCandidateSent && (
        <IconButton onClick={() => handleRemoveJob()} component="span">
          <img src={icon} alt="delete" className="icon" />
        </IconButton>
      )}
    </div>
  );
};

DesiredJob.defaultProps = {
  refId: '#',
  isEnabled: true,
};

DesiredJob.propTypes = {
  text: PropTypes.string.isRequired,
  refId: PropTypes.string,
  removeJobDispatch: PropTypes.func.isRequired,
  seasonId: PropTypes.string.isRequired,
  didSeasonCandidateSent: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const myState = state[ownProps.namespace];
  return {
    seasonId: myState.filter.season.seasonId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeJobDispatch: (job, seasonId) => dispatch(removeJob(ownProps.namespace, job, seasonId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DesiredJob);
