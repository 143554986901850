import { SET_SEASON, SET_CATEGORY, SET_JOB_TITLE } from './filter.types';

export const setSeason = (namespace, season) => ({
  type: `${namespace}/${SET_SEASON}`,
  payload: season
});

export const setCategory = (namespace, category) => ({
  type: `${namespace}/${SET_CATEGORY}`,
  payload: category
});

export const setJobTitle = (namespace, job) => ({
  type: `${namespace}/${SET_JOB_TITLE}`,
  payload: job
});
