/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import './apply-candidacy-popup.styles.scss';
import Dialog from '../../../../common/components/dialog/dialog.component';
import Button from '../../../../common/components/button/button.component';
import { resetJobsAction } from '../../../../store/desired-jobs/desired-jobs.actions';

const ApplyCandidacyPopup = (props) => {
  const {
    isOpen,
    setIsOpen,
    options,
    userDetails,
    dispatchResetJobs
  } = props;

  const history = useHistory();

  const handleClose = () => {
    setIsOpen(false);
  };

  const allowRasan = options.includes('רסן');
  const allowSaal = options.includes('סאל');
  const allowRabaz = options.includes('רבז');
  const allowSeren = options.includes('סרן');
  const allowAlam = options.includes('אלם');
  const allowNagadim = ['רסל', 'רסב', 'רסר', 'רסמ'].every(((rank) => (options.includes(rank))));

  return (
    <Dialog isOpen={isOpen} handleClose={handleClose} header="APPLY_CANDIDACY" applyCandidacyDialog>
      <div className="buttons">
        <Button
          text="INSTATEMENT_DISCUSSION_SEREN"
          disabled={!allowSeren}
          onClick={() => {
            dispatchResetJobs('dashabSeren');
            history.push({
              pathname: '/dashab-seren',
              state: { userId: userDetails.userId, name: userDetails.name, isManagerApply: true }
            });
          }}
        />
        <Button
          text="INSTATEMENT_DISCUSSION_CIRCLE"
          disabled={!allowRasan}
          onClick={() => {
            dispatchResetJobs('dashabRasan');
            history.push({
              pathname: '/dashab-rasan',
              state: { userId: userDetails.userId, name: userDetails.name, isManagerApply: true }
            });
          }}
        />
        <Button
          text="INSTATEMENT_DISCUSSION_TWO_CIRCLES"
          disabled={!allowSaal}
          onClick={() => {
            dispatchResetJobs('dashabSaal');
            history.push({
              pathname: '/dashab-saal',
              state: { userId: userDetails.userId, name: userDetails.name, isManagerApply: true }
            });
          }}
        />
        <Button
          text="INSTATEMENT_DISCUSSION_RBZ"
          disabled={!allowRabaz}
          onClick={() => {
            dispatchResetJobs('dashabRbz');
            history.push({
              pathname: '/dashab-rav-zroie',
              state: { userId: userDetails.userId, name: userDetails.name, isManagerApply: true }
            });
          }}
        />
        <Button
          text="INSTATEMENT_DISCUSSION_ALAM"
          disabled={!allowAlam}
          onClick={() => {
            dispatchResetJobs('dashabAlam');
            history.push({
              pathname: '/dashab-alam',
              state: { userId: userDetails.userId, name: userDetails.name, isManagerApply: true }
            });
          }}
        />
        <Button
          text="INSTATEMENT_DISCUSSION_NAGADIM_DISTINCT"
          disabled={!allowNagadim}
          onClick={() => {
            dispatchResetJobs('dashabNagadim');
            history.push({
              pathname: '/dashab-nagadim',
              state: { userId: userDetails.userId, name: userDetails.name, isManagerApply: true }
            });
          }}
        />
      </div>
    </Dialog>
  );
};

ApplyCandidacyPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  userDetails: PropTypes.shape({ userId: PropTypes.string.isRequired, name: PropTypes.string.isRequired }).isRequired,
  dispatchResetJobs: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  dispatchResetJobs: (namespace) => dispatch(resetJobsAction(namespace)),
});

export default connect(null, mapDispatchToProps)(ApplyCandidacyPopup);
