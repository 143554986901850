import { SET_DASHBOARD_SEARCH_VALUE } from './dashboard.types';

const initialState = {
  dashboardSearchValue: ''
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_SEARCH_VALUE:
      return {
        ...state,
        dashboardSearchValue: action.payload
      };
    default:
      return state;
  }
};

export default dashboardReducer;
