/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PermissionType } from '@dashabim/users';
import ContainerHeader from '../container-header/container-header.component';
import Button from '../../../../common/components/button/button.component';
import UserAvatar from '../../../../common/components/user-avatar/user-avatar.component';
import { login } from '../../../../store/user/user.actions';

import InfoBlock from '../info-block/info-block.component';
import EditPersonalInfoDialog from './edit-personal-info-dialog/edit-personal-info-dialog.component';
import { fields } from './fields';
import { UsersService } from '../../../../services/users.service';
import { changeDateFormat } from '../../../../utils';
import ApplyCandidacyPopup from '../apply-candidacy-popup/apply-candidacy-popup.component';
import './personal-info.styles.scss';
// import SnackbarSuccess from '../../../../common/components/snackbar-success/snackbar-success.component';
// import Dialog from '../../../../common/components/dialog/dialog.component';
import {
  endLoading,
  setMessageError,
  setSuccessMessage,
  startLoading,
} from '../../../../store/events/events.actions';

const PersonalInfo = (props) => {
  const {
    user,
    permissions,
    updateUser,
    originalUserArmyId,
    setDispatchUser,
  } = props;

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const [options, setOptions] = useState(['רסל', 'רסב', 'רסר', 'רסמ', 'סרן', 'רסן', 'סאל', 'רבז', 'אלם']);
  const [personalInfoArray, setPersonalInfo] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [updateForm, setUpdateForm] = useState({});

  const addInfoIntoFields = (data) => fields.map((field) => {
    let information;
    if (field.id === 'name') {
      information = `${data.firstName} ${data.lastName}`;
    } else if (field.isManagerField && data.fieldsManager) {
      information = data.fieldsManager[field.id];
    } else {
      information = data[field.id];
    }
    if (typeof information === 'undefined' || information === null) {
      information = '-';
    }
    return { ...field, info: information };
  });

  useEffect(() => {
    if (permissions.includes(PermissionType.professionalGroupAdmin) || permissions.includes(PermissionType.unitAdmin)) {
      setOptions(['רסן', 'סרן']);
    } else if (permissions.includes(PermissionType.serenGroupAdmin) || permissions.includes(PermissionType.serenUnitAdmin)) {
      setOptions(['סרן']);
    }
  }, [permissions]);

  useEffect(async () => {
    if (user) {
      setPersonalInfo(addInfoIntoFields(user));
    }
  }, [user]);

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-shadow
  const sendUpdateForm = async (updateForm) => {
    try {
      dispatch(startLoading());
      await UsersService.updateById(user.id, { fieldsManager: updateForm });
      const updatedUser = await UsersService.getById(user.id);
      setIsEditMode(false);
      dispatch(setSuccessMessage());
      if (updatedUser.armyId === originalUserArmyId) {
        setDispatchUser(updatedUser);
      } else {
        updateUser(updatedUser);
      }
    } catch (err) {
      setUpdateForm({});
      dispatch(setMessageError(err));
    } finally {
      setUpdateForm({});
      dispatch(endLoading());
    }
  };

  const setForm = (value) => {
    const key = Object.keys(value)[0];
    if (value[key] === '') {
      const deletedValueForm = { ...updateForm, [key]: null };
      setUpdateForm(deletedValueForm);
    } else {
      setUpdateForm((oldForm) => ({ ...oldForm, ...value }));
    }
  };

  const headersToTooltips = {
    POTENTIAL_COMMITTEE_DECISION:
      'לאחר ביצוע מ"ה מתקיימת הועדה שמטרתה לשקלל את הנתונים האישיים ותוצאות המרכז לבחינת התאמה לתפקידי סא"ל',
    RASAN_TOP_COMMITTEE_DECISION:
      'בדיון טו"פ נידונים כלל הרסנ"ים המודיעיניים שנקבעה לגביהם תנועת רוחב, ועליהם מתקבלות ההחלטות באשר לציר הפיתוח והתפקיד העתידי שנראה לנכון למסלול שירותם',
    CLASSIFICATION_COMMITTEE_DECISION:
      "מטרתו של הדיון לאשר את רלוונטיות הקצינים המסוכמים כפוטנציאל סא\"ל (א', ב' בוועדת פוט') להתמודדות באותה עונת שיבוצים, זאת למול התפקידים הצפויים להיפתח",
    SECOND_ORGANIZATION_GATE:
      'עבור משרתי הקבע המשויכים למסלול השירות 2016 הוא המועד בו משלימים 14 שנות שירות בקבע (12 שנים לעתודאים). תאריך זה מהווה צומת לקידום לרס״ן בכיר, לסא״ל או לשחרור.',
    PROFESSIONAL_UNIT:
      'ציר הפיתוח המערכי המקצועי שלך בחלוקה למערכי הפיתוח בחיל',
    ORGANIZATION_DISCIPLINE: 'חלוקה מקצועית ע"פ מסלול שירותך',
  };

  const createInfoBlock = (line) => (
    <InfoBlock
      key={line.label}
      id={line.id}
      header={line.label}
      tooltip={headersToTooltips[line.label]}
      info={line.date ? changeDateFormat(line.info) : line.info}
      isInEditMode={false}
      editType={line.editable}
      options={line.options}
      setForm={() => {}}
    />
  );

  const createInfoBlockOnEdit = (line) => (
    <InfoBlock
      key={line.label}
      id={line.id}
      header={line.label}
      tooltip={headersToTooltips[line.label]}
      info={line.info}
      isInEditMode
      editType={line.editable}
      options={line.options}
      setForm={(value) => setForm(value)}
      isConfig={line.isConfig}
      configRoute={line.configRoute}
      isDisabled={
        ((permissions.includes(PermissionType.professionalGroupAdmin)
         || permissions.includes(PermissionType.unitAdmin))
        && line.label !== 'MOVEMENT'
        && line.label !== 'NEW_MOVEMENT'
        && line.label !== 'ALAM_EVALUATION_CENTER'
        && line.label !== 'COMMITTED_TO_PROMOTION'
        && line.label !== 'RASAN_TOP_COMMITTEE_DECISION'
        && line.label !== 'FIRST_ORGANIZATION_GATE')
        || ((permissions.includes(PermissionType.serenGroupAdmin)
        || permissions.includes(PermissionType.serenUnitAdmin))
        && line.label !== 'MOVEMENT'
        && line.label !== 'NEW_MOVEMENT'
        && line.label !== 'ALAM_EVALUATION_CENTER'
        && line.label !== 'COMMITTED_TO_PROMOTION'
        && line.label !== 'RASAN_TOP_COMMITTEE_DECISION'
        && line.label !== 'FIRST_ORGANIZATION_GATE')
      }
    />
  );

  const editablePersonalInfo = personalInfoArray.filter(
    (info) => 'editable' in info
  );
  return (
    <div className="personal-info-block">
      <EditPersonalInfoDialog
        user={user}
        permissions={permissions}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        sendUpdateForm={sendUpdateForm}
        setUpdateForm={setUpdateForm}
        createInfoBlockOnEdit={createInfoBlockOnEdit}
        editablePersonalInfo={editablePersonalInfo}
        updateForm={updateForm}
      />
      <ApplyCandidacyPopup
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        options={options}
        userDetails={{
          userId: user?.id,
          name: `${user?.firstName} ${user?.lastName}`,
        }}
      />
      <ContainerHeader header="PERSONAL_INFO">
        <span className="updated-at-text">
          {t('PERSONAL_INFO_UPDATED_AT')}

          {user && (user.updatedAt ? changeDateFormat(user.updatedAt) : '-')}
        </span>
        {(permissions.includes(PermissionType.Manager)
          || permissions.includes(PermissionType.SystemAdmin)
          || permissions.includes(PermissionType.professionalGroupAdmin)
          || permissions.includes(PermissionType.unitAdmin)
          || permissions.includes(PermissionType.serenGroupAdmin)
          || permissions.includes(PermissionType.serenUnitAdmin))
          && !isEditMode && (
            <Button
              text="EDIT"
              isSmall
              onClick={() => {
                setIsEditMode(true);
              }}
            />
        )}
        {/* allow who can create candidacy */}
        {/* {(permissions.includes(PermissionType.Manager)
          || permissions.includes(PermissionType.SystemAdmin)
          || (
            (permissions.includes(PermissionType.professionalGroupAdmin)
            || permissions.includes(PermissionType.unitAdmin))
            && (user?.rank === 'רסן' || user?.rank === 'סרן'))
          || (
            (permissions.includes(PermissionType.serenGroupAdmin)
            || permissions.includes(PermissionType.serenUnitAdmin))
            && user?.rank === 'סרן')
        )
          && originalUserArmyId !== user?.armyId && (
            <div className="apply-candidacy-btn">
              <Button
                text="APPLY_CANDIDACY"
                isSmall
                onClick={() => setIsDialogOpen(true)}
              />
            </div>
        )} */}
        {permissions.includes(PermissionType.Manager)
          && originalUserArmyId !== user?.armyId && (
            <div className="apply-candidacy-btn">
              <Button
                text="APPLY_CANDIDACY"
                isSmall
                onClick={() => setIsDialogOpen(true)}
              />
            </div>
        )}
      </ContainerHeader>
      <div className="personal-info-container">
        <UserAvatar
          picture={user ? user.picture : null}
          gender={user ? user.gender : null}
        />
        {permissions.includes(PermissionType.Manager)
        || permissions.includes(PermissionType.SystemAdmin)
        || permissions.includes(PermissionType.professionalGroupAdmin)
        || permissions.includes(PermissionType.unitGroupAdmin)
        || permissions.includes(PermissionType.serenGroupAdmin)
        || permissions.includes(PermissionType.serenUnitAdmin)
          ? personalInfoArray.map((line) => createInfoBlock(line))
          : personalInfoArray
            .filter(
              (line) => !line.onlyManager && !(line.info === '-' && line.hideEmpty)
            )
            .map((line) => createInfoBlock(line))}
      </div>
    </div>
  );
};

PersonalInfo.propTypes = {
  user: PropTypes.shape(),
  permissions: PropTypes.arrayOf(PropTypes.string),
  updateUser: PropTypes.func.isRequired,
  originalUserArmyId: PropTypes.string.isRequired,
  setDispatchUser: PropTypes.func.isRequired,
};

PersonalInfo.defaultProps = {
  user: null,
  permissions: [],
};

const mapStateToProps = (state) => ({
  permissions: state.user.permissions,
  originalUserArmyId: state.user.armyId,
});

const mapDispatchToProps = (dispatch) => ({
  setDispatchUser: (user) => dispatch(login(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
