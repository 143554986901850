/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import columns from './data-for-table';

import './all-seasons-dialog.styles.scss';
import Dialog from '../../../../common/components/dialog/dialog.component';
import PositionsService from '../../../../services/positions.service';
import EditorTable from '../../../../common/components/editor-table/editor-table.component';
import AddSeasonDialog from '../add-season-dialog/add-season-dialog.component';
import { changeDateFormat } from '../../../../utils';
import editContentIcon from '../../../../../assets/logos/edit-content.svg';
import deleteIcon from '../../../../../assets/logos/delete.svg';
import {
  endLoading,
  setMessageError,
  setSuccessMessage,
  startLoading
} from '../../../../store/events/events.actions';

const AllSeasonsDialog = (props) => {
  const { t } = useTranslation();
  const {
    isOpen,
    setIsOpen,
    refetchPage,
    seasons,
  } = props;

  const dispatch = useDispatch();

  const [rows, setRows] = useState();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [seasonToEdit, setSeasonToEdit] = useState({});

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleEdit = (season) => {
    setSeasonToEdit(season);
    setIsEditDialogOpen(true);
  };

  const handleDelete = async (id) => {
    dispatch(startLoading());
    try {
      await PositionsService.deleteSeason(id);
      dispatch(setSuccessMessage());
      refetchPage();
    } catch (err) {
      dispatch(setMessageError(err));
    } finally {
      dispatch(endLoading());
    }
  };

  const setupRows = () => {
    const rowsWithInput = seasons.map((season) => ({
      ...season,
      rbz: t(season.rbz.toString().toUpperCase()),
      startDate: changeDateFormat(season.startDate),
      endDate: changeDateFormat(season.endDate),
      editAndDeleteSeason:
  <div className="all-seasons__buttons">
    <button type="button" onClick={() => handleEdit(season)} className="all-seasons__button">
      <img src={editContentIcon} alt="edit" />
    </button>
    <button type="button" onClick={() => handleDelete(season.id)} className="all-seasons__button all-seasons__button--change-color">
      <img src={deleteIcon} alt="delete" />
    </button>
  </div>
    }));
    setRows(rowsWithInput);
  };

  useEffect(() => {
    setupRows();
  }, [seasons]);

  useEffect(() => {
    if (!isEditDialogOpen) {
      setSeasonToEdit({});
    }
  }, [isEditDialogOpen]);

  return (
    <div>
      <AddSeasonDialog isOpen={isOpen && isEditDialogOpen} setIsOpen={setIsEditDialogOpen} refetch={refetchPage} editData={seasonToEdit} />
      <Dialog tableDialog isOpen={isOpen} handleClose={handleClose} header="ALL_SEASONS">
        <EditorTable columns={columns} rows={rows} />
      </Dialog>
    </div>

  );
};

AllSeasonsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  refetchPage: PropTypes.func.isRequired,
  seasons: PropTypes.shape().isRequired,
};

export default AllSeasonsDialog;
