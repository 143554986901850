/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import Dialog from '../../../../../common/components/dialog/dialog.component';
import Button from '../../../../../common/components/button/button.component';
import { POPUP_MODES } from './popup-modes';
import Select from '../../../../../common/components/select/select.component';
import TextInput from '../../../../../common/components/text-input/text-input.component';

import {
  endLoading,
  setMessageError,
  startLoading,
} from '../../../../../store/events/events.actions';
import SnackbarSuccess from '../../../../../common/components/snackbar-success/snackbar-success.component';
import SnackbarError from '../../../../../common/components/snackbar-error/snackbar-error.component';
import './second-priority-popup.styles.scss';
import { ApplyCandidatesService } from '../../../../../services/apply-candidates.service';

const SecondPriorityPopup = (props) => {
  const {
    candidacies, userCandidacies, isOpen, setIsOpen
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [userCandidaciesData, setUserCandidaciesData] = useState([
    userCandidacies,
  ]);
  const [candidaciesData, setCandidaciesData] = useState(candidacies);
  const [currCandidacy, setCurrCandidacy] = useState(userCandidacies[0]);

  const secondPriorityText = useRef(currCandidacy?.secondPriority || '');

  // console.log('candidaciesData', candidaciesData);
  // console.log('userCandidaciesData', userCandidaciesData);
  // console.log('currCandidacy', currCandidacy);
  // console.log('secondPriorityText', secondPriorityText);
  // console.log('----------------------------------------');

  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  const onSubmit = () => {
    dispatch(endLoading());
    setOpenSuccessSnackbar(true);
    setTimeout(() => {
      setOpenSuccessSnackbar(false);
    }, 2500);
    setIsOpen(false);
  };
  const onError = () => {
    dispatch(endLoading());
    setOpenErrorSnackbar(true);
    setTimeout(() => {
      setOpenErrorSnackbar(false);
    }, 2500);
  };

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#00962A',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00962A',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#00962A',
        },
        '&:hover fieldset': {
          borderColor: '#00962A',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00962A',
        },
      },
    },
  })(TextField);

  const handleSaveButton = async () => {
    dispatch(startLoading());
    // save current secondPriority
    const index = userCandidaciesData.findIndex(
      (cand) => cand.id === currCandidacy.id
    );
    const newState = _.cloneDeep(userCandidaciesData);
    newState[index] = {
      ...newState[index],
      secondPriority: secondPriorityText.current.value,
    }; // copy the priority text value

    // get only the changed candidacies so we only update relevant
    const changedUserCandidates = newState.filter((cand) => {
      for (let i = 0; i < userCandidacies.length; i += 1) {
        if (
          cand.seasonId === userCandidacies[i].seasonId
          && cand.secondPriority !== userCandidacies[i].secondPriority
        ) {
          return true;
        }
      }
      return false;
    });
    const promises = changedUserCandidates.map((cad) => ApplyCandidatesService.updateSecondPriority(
      cad.id,
      cad.secondPriority || ''
    ));
    await Promise.all(promises)
      .then((values) => {
        onSubmit();
      })
      .catch((err) => {
        onError();
      });
  };

  useEffect(() => {
    setUserCandidaciesData(userCandidacies);
    setCurrCandidacy(userCandidacies[0]);
    // console.log('resetting');
  }, [userCandidacies]);
  useEffect(() => {
    setCandidaciesData(candidacies);
  }, [candidacies]);

  const getSelect = (header, options) => {
    let optionsWithKeyAndVal = options.map((option) => ({
      key: option.seasonId,
      value: option.seasonName,
    }));
    // replace seasonId with user cad ID because we want to update by Cad ID the user candidacies DB collection
    optionsWithKeyAndVal = optionsWithKeyAndVal.map((option) => {
      // eslint-disable-next-line no-underscore-dangle
      // console.log('userCandidaciesData', userCandidaciesData);
      const filter = userCandidaciesData.filter(
        (x) => x.seasonId === option.key
      )[0];
      // console.log('filter', filter);
      if (!filter) return {};
      const userCadId = filter.id;
      return { ...option, key: userCadId };
    });
    // console.log('final optionsWithKeyAndVal', optionsWithKeyAndVal);
    return (
      <Select
        options={optionsWithKeyAndVal}
        header={header}
        onChange={(e) => {
          // change the secondPriority in userCandidacyData  by currCandidacy ID
          // find the index of the element

          if (secondPriorityText.current.value !== '') {
            const index = userCandidaciesData.findIndex(
              (cand) => cand.id === currCandidacy.id
            );
            // console.log(
            //   'found location to replace at index',
            //   index,
            //   userCandidaciesData[index]
            // );
            const newState = _.cloneDeep(userCandidaciesData);
            newState[index] = {
              ...newState[index],
              secondPriority: secondPriorityText.current.value,
            }; // copy the priority text value
            // console.log(
            //   'Changed index',
            //   index,
            //   newState[index],
            //   secondPriorityText.current.value
            // );
            setUserCandidaciesData(newState);
          }
          // get the next value to be shown, it is the selected season secondpriority field or an empty one if does not exists
          // console.log('e.target.value', e.target.value);
          // console.log('userCandidaciesData', userCandidaciesData);
          const nextCandidacy = userCandidaciesData.filter(
            (cand) => cand.id === e.target.value
          )[0];
          // console.log('nextCandidacy', nextCandidacy);
          setCurrCandidacy(nextCandidacy);
          secondPriorityText.current.value = nextCandidacy.secondPriority || ''; // reset the value to the next one selected or empty
        }}
        required
        inverted
        selected=""
      />
    );
  };

  const getTextField = (text) => (
    <div className="card card__text">
      <CssTextField
        id="standard-textarea"
        defaultValue={text}
        autoFocus
        disableUnderline
        multiline
        rows={7}
        inputRef={secondPriorityText}
        inputProps={{
          padding: '10px',
          style: { fontSize: '1.7rem', lineHeight: 1.1 },
        }}
      />
    </div>
  );

  const getDialogContent = () => (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item xs={10} />
      <Grid item xs={2} justifyContent="flex-end">
        {getSelect('', candidaciesData)}
      </Grid>

      <Grid item xs={12}>
        <p style={{ fontSize: '1.5rem', textAlign: 'center' }}>
          מועמד/ת יקר/ה, במידת שינוי תעדופך לתפקידים אליהם הינך מתמודד (אושרת
          באשכול ועדיין לא היה דיון איוש), ניתן לעדכן כאן את הבקשות.
        </p>
        <p style={{ fontSize: '1.5rem', textAlign: 'center' }}>
          לתשומת ליבך, כל מלל שייכתב בתיבה מטה יועבר אל סגלי המשא״ן הרלוונטים.
        </p>
      </Grid>
      <Grid item xs={12} justifyContent="center">
        {getTextField(currCandidacy?.secondPriority || '')}
      </Grid>
      <Grid item xs={10} />
      <Grid item xs={2}>
        <Button text="SAVE" onClick={handleSaveButton} />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Dialog
        isOpen={isOpen}
        handleClose={() => {
          setCurrCandidacy(userCandidacies[0]);
          setIsOpen(false);
        }}
        header={t('SECOND_PRIORITY')}
        showCloseButton={false}
      >
        {getDialogContent()}
      </Dialog>
      {openSuccessSnackbar && <SnackbarSuccess textSuccess="SUCCESS.ACTION" />}
      {openErrorSnackbar && <SnackbarError textError="ERROR.GENERAL" />}
    </>
  );
};

SecondPriorityPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  candidacies: PropTypes.instanceOf(Array).isRequired,
  userCandidacies: PropTypes.instanceOf(Array).isRequired,
};

export default SecondPriorityPopup;
