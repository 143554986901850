/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PermissionType } from '@dashabim/users';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { NavLink, withRouter } from 'react-router-dom';
import OuterUserDialog from './outer-user-dialog/outer-user-dialog';
import SidebarItem from './sidebar-item/sidebar-item.component';
import PersonalInfoForSideBar from './sidebar-personal-info/sidebar-personal-info';
import './sidebar.styles.scss';

const canViewManagerItems = (permissions) => permissions
  && (permissions.includes(PermissionType.Manager)
    || permissions.includes(PermissionType.SystemAdmin)
    || permissions.includes(PermissionType.Watch)
    || permissions.includes(PermissionType.professionalGroupWatch)
    || permissions.includes(PermissionType.professionalGroupAdmin)
    || permissions.includes(PermissionType.unitAdmin)
    || permissions.includes(PermissionType.serenGroupAdmin)
    || permissions.includes(PermissionType.serenUnitAdmin));

const Sidebar = (props) => {
  const { t } = useTranslation();
  const { items, managerItems, permissions } = props;
  const [outerUsersPopupOpen, setOuterUsersPopupOpen] = useState(false);

  const createItems = (wantedItems) => wantedItems.map((item) => {
    const sideBar = (
      <SidebarItem
        sidebarHeader={item.sidebarHeader}
        pageHeader={item.pageHeader}
        logo={item.logo}
        isCurrentPage={`/${item.path}` === window.location.pathname}
        isIncludeSearchBar={item.isIncludeSearchBar}
        placeholderForSearchBar={item.placeholder}
        namespaceForHeader={item.namespace}
        dropDownOptions={item.dropDownOptions}
        onClick={item.openPopup}
      />
    );

    let wantedItem;
    if (item.isExternalUrl) {
      wantedItem = (
        <a
          key={Symbol(item.text).toString()}
          className="nav-link"
          rel="noreferrer"
          target="_blank"
          href={item.path}
        >
          {sideBar}
        </a>
      );
    } else if (item.dropDownOptions || item.openPopup) {
      wantedItem = sideBar;
    } else {
      wantedItem = (
        <NavLink
          to={`/${item.path}`}
          key={item.sidebarHeader}
          className="nav-link"
        >
          {sideBar}
        </NavLink>
      );
    }

    if (item.permissions) {
      return item.permissions.some((permission) => permissions.includes(permission)) && wantedItem;
    }
    return wantedItem;
  });

  return (
    <>
      <Drawer
        elevation={0}
        variant="permanent"
        anchor="right"
        classes={{ paper: 'sidebar' }}
      >
        <PersonalInfoForSideBar picture="a" />
        <List>{createItems(items)}</List>
        {canViewManagerItems(permissions) ? (
          <>
            <span className="manager-menu">{t('MANAGER_MENU')}</span>
            <div className="manager-sidebar">
              <List>{createItems(managerItems(setOuterUsersPopupOpen))}</List>
            </div>
          </>
        ) : (
          <></>
        )}
      </Drawer>
      <OuterUserDialog
        isOpen={outerUsersPopupOpen}
        setIsOpen={setOuterUsersPopupOpen}
      />
    </>
  );
};

Sidebar.defaultProps = {
  permissions: [],
};

Sidebar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      logo: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
  managerItems: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  permissions: state.user.permissions,
});

export default connect(mapStateToProps)(withRouter(Sidebar));
