import React from 'react';
import PropTypes from 'prop-types';

import campaign from '../../../../../assets/logos/campaign.svg';
import calendar from '../../../../../assets/logos/calendar.svg';
import './message-banner.styles.scss';
import { changeDateFormat } from '../../../../utils';

const MessageBanner = (props) => {
  const { messageBanner } = props;

  return (
    <div className="message-banner">
      <div className="message-header">
        <div className="message-main">
          <img src={calendar} alt="" />
          {changeDateFormat(messageBanner.creationDate)}
        </div>
        { messageBanner.visibility.level === 'EVERYONE' && <img src={campaign} alt="" />}
      </div>
      <div className="message-text">
        {messageBanner.message}
      </div>
    </div>
  );
};

MessageBanner.propTypes = {
  messageBanner: PropTypes.shape({
    message: PropTypes.string.isRequired,
    creationDate: PropTypes.string.isRequired,
    visibility: PropTypes.shape({
      level: PropTypes.string.isRequired,
    }).isRequired
  }).isRequired,
};

export default MessageBanner;
