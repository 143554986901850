import React from 'react';
import './table-filters.styles.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { filterField } from './filter-fields.consts';

const TableFilters = (props) => {
  const { t } = useTranslation();
  const {
    users, permissionToFilter, changeFilter, getHebrewName
  } = props;

  const onFilterField = (newPermission) => {
    changeFilter(getHebrewName(newPermission));
  };

  return (
    <Autocomplete
      value={permissionToFilter}
      onChange={(newValue) => onFilterField(newValue.target.innerHTML)}
      options={['', ...filterField.getOptions(users)].map((option) => getHebrewName(option))}
      classes={{
        input: 'autocomplete',
        root: 'root-autocomplete',
        listbox: 'listbox-autocomplete',
      }}
      disableClearable
      getOptionLabel={(option) => (option ? option.toString() : t(filterField.allOptionsLabel))}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          variant="standard"
          classes={{ root: 'autocomplete' }}
        />
      )}
    />
  );
};

TableFilters.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  permissionToFilter: PropTypes.string,
  changeFilter: PropTypes.func,
  getHebrewName: PropTypes.func,
};

export default TableFilters;
