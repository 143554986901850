/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import './select-user-popup.styles.scss';
import Dialog from '../dialog/dialog.component';
import UserOption from './user-option/user-option.component';

const SelectUserPopup = (props) => {
  const {
    isOpen,
    setIsOpen,
    setSelectedUser,
    users,
    onPopupCancel
  } = props;

  const handleClick = (selectedUser) => {
    setIsOpen(false);
    setSelectedUser(selectedUser);
  };

  const handleClose = () => {
    setIsOpen(false);
    onPopupCancel();
  };

  return (
    <Dialog isOpen={isOpen} handleClose={handleClose} header="CHOOSE_USER">
      <div className="select-user-popup">
        {users.map((user) => <UserOption user={user} onClick={(selectedUser) => handleClick(selectedUser)} />)}
      </div>
    </Dialog>
  );
};

SelectUserPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  onPopupCancel: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    armyId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
  }))
};

SelectUserPopup.defaultProps = {
  users: [],
};

export default SelectUserPopup;
