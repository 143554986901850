import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';

import xButton from '../../../../assets/logos/x-button.svg';

import './snackbar-error.styles.scss';
import { setMessageError } from '../../../store/events/events.actions';

const SnackbarError = (props) => {
  const { textError } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      dispatch(setMessageError(null));
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={textError}
      autoHideDuration={3000}
      onClose={handleClose}
      className="snackbar"
    >
      <div className="snackbar-error-content">
        <span>{t(textError)}</span>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <img src={xButton} alt="search" />
        </IconButton>
      </div>
    </Snackbar>
  );
};

SnackbarError.propTypes = {
  textError: PropTypes.string.isRequired,
};

export default SnackbarError;
