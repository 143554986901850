/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './summary-table.styles.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { headers } from './table-headers.consts';

const getLabelByUser = (user) => {
  if (!user) return null;
  const CORP_NOT_TO_SHOW = 'חמנ';
  const corpLabel = !user.corp || user.corp === CORP_NOT_TO_SHOW ? '' : ` (${user.corp})`;
  return (
    <div>{`${user.rank} ${user.name}${corpLabel}`}</div>
  );
};

const CandidaciesView = (candidacies) => {
  if (!candidacies) return null;
  const candidaciesCell = candidacies.map((candidacy) => getLabelByUser(candidacy));
  return candidaciesCell;
};

const AgreedView = (agreed) => {
  if (!agreed) return null;
  return getLabelByUser(agreed);
};

const getViewByColumn = (row, column) => {
  const value = row[column.id];

  switch (column.id) {
    case 'candidaciesForJob':
      return CandidaciesView(value);
    case 'agreed':
      return AgreedView(value);
    default:
      return value;
  }
};

const SummaryTable = (props) => {
  const { t } = useTranslation();
  const { tableData, currentUserId } = props;

  // eslint-disable-next-line max-len
  const isCurrentUserInCandidates = (dataOfRow) => (!dataOfRow.candidaciesForJob
    ? false
    : dataOfRow.candidaciesForJob
      .map((cantdidate) => cantdidate.userId)
      .includes(currentUserId));

  return (
    <div className="root">
      <TableContainer>
        <Table className="root-table">
          <TableHead>
            <TableRow>
              {headers.map((column) => (
                <TableCell key={column.id} align={column.align}>
                  {t(column.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="body">
            {tableData.map((row) => (
              <TableRow
                key={row.id}
                className={isCurrentUserInCandidates(row) && 'current-user'}
              >
                {headers.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    {getViewByColumn(row, column)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

SummaryTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      seasonTitle: PropTypes.string,
      jobTitle: PropTypes.string,
      unitGroup: PropTypes.string,
      isCore: PropTypes.bool || undefined,
      isForOfficers: PropTypes.bool || undefined,
      isForNagadim: PropTypes.bool || undefined,
      dateOfApproval: PropTypes.string,
      mainUnit: PropTypes.string,
      categoryName: PropTypes.string,
      candidaciesForJob: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          userId: PropTypes.string,
          rank: PropTypes.string
        })
      ),
      agreed: PropTypes.shape({
        name: PropTypes.string,
        rank: PropTypes.string
      }),
    })
  ).isRequired,
  currentUserId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  currentUserId: state.user.id,
});

export default connect(mapStateToProps)(SummaryTable);
