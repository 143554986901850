import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Grid } from '@material-ui/core';
import JobContentCard from '../job-content-card/job-content-card.component';
import JobInfo from '../job-info/job-info.component';
import { displayFields } from './display-fields.consts';
import ContainerBox from '../container-box/container-box.component';
import './openable-container-box.styles.scss';
import core from '../../../../../assets/logos/core.png';

const OpenableContainerBox = (props) => {
  const {
    header,
    direction,
    managerMode,
    namespace,
    job,
    currentUserId,
    currentUserRank,
    currentUserClassificationDiscussionDecision,
  } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => setIsOpen(!isOpen);

  const isDisabled = () => job.jobStatus !== 'openToApply';

  const createJobBodyClosedMode = () => (
    <div className="card-container__body">
      <Grid container spacing={2} alignItems="center">
        {displayFields.map((field) => (
          <Grid item xs={3}>
            <div className="display-field">
              {`${t(field.label)}: ${
                job[field.propertyName] || t('EMPTY')
              }`}

            </div>
          </Grid>
        ))}
        <Grid item xs={1} className="image-grid-item">
          {job.isCore && <img className="core-image" alt="core" src={core} />}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1}>
          <IconButton className="button" onClick={() => toggleIsOpen()}>
            {isOpen ? (
              <KeyboardArrowUpIcon fontSize="large" />
            ) : (
              <KeyboardArrowDownIcon fontSize="large" />
            )}
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );

  const createJobBodyOpenMode = () => (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        {createJobBodyClosedMode()}
      </Grid>
      <Grid item xs={12}>
        <div className="card-container__body">
          <JobContentCard
            header="JOB_DESCRIPTION"
            text={job.jobDescription}
            namespace={namespace}
          />
          <JobContentCard
            header="NECESSARY_CONDITIONS"
            text={job.necessaryConditions}
            namespace={namespace}
          />
          <JobContentCard
            header="JOB_SKILLS_TITLE"
            text={job.qualificationAndSkills}
            namespace={namespace}
          />
          <JobInfo
            job={job}
            namespace={namespace}
            userId={currentUserId}
            disabled={isDisabled()}
            currentUserRank={currentUserRank}
            currentUserClassificationDiscussionDecision={
              currentUserClassificationDiscussionDecision
            }
          />
        </div>
      </Grid>
    </Grid>
  );

  const createBody = () => (
    <div>{isOpen ? createJobBodyOpenMode() : createJobBodyClosedMode()}</div>
  );

  return (
    <ContainerBox
      managerMode={managerMode}
      direction={direction}
      header={header}
      body={createBody()}
      disabled={isDisabled()}
    />
  );
};

OpenableContainerBox.defaultProps = {
  header: '',
  direction: 'row',
  managerMode: false,
};

OpenableContainerBox.propTypes = {
  header: PropTypes.string,
  direction: PropTypes.string,
  job: PropTypes.shape({
    jobDescription: PropTypes.string,
    header: PropTypes.string,
    necessaryConditions: PropTypes.string,
    qualificationAndSkills: PropTypes.string,
    category: PropTypes.string,
    mainUnit: PropTypes.string,
    unitGroup: PropTypes.string,
    isCore: PropTypes.bool || undefined,
    isForOfficers: PropTypes.bool || undefined,
    isForNagadim: PropTypes.bool || undefined,
    dateOfApproval: PropTypes.string,
    jobStatus: PropTypes.string,
  }),
  managerMode: PropTypes.bool,
  namespace: PropTypes.string,
  currentUserId: PropTypes.string,
  currentUserRank: PropTypes.string,
  currentUserClassificationDiscussionDecision: PropTypes.string,
};

export default OpenableContainerBox;
