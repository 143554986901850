import { HttpClient } from '../http.client';
import { config } from '../config';

const url = config.endpoints.banners;

class BannerService {
  static getByUserArmyId(armyId, startIndex, endIndex) {
    return HttpClient.get(`${url}/placementDiscussions/user/${armyId}`, { startIndex, endIndex });
  }

  static create(body) {
    return HttpClient.post(`${url}/placementDiscussions`, body);
  }
}

// eslint-disable-next-line import/prefer-default-export
export { BannerService };
