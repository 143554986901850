import i18n from '../../i18n';
import { getErrorMessage } from '../../utils';
import { SET_SUCCESS_MESSAGE, SET_LOADING, SET_ERROR } from './events.types';

// eslint-disable-next-line import/prefer-default-export
export const setSuccessMessage = (type = 'ACTION') => ({
  type: SET_SUCCESS_MESSAGE,
  payload: type ? `SUCCESS.${type}` : null
});

export const setMessageError = (error) => ({
  type: SET_ERROR,
  payload: error ? getErrorMessage(error, i18n.t.bind(i18n)) : null
});

export const startLoading = () => ({
  type: SET_LOADING,
  payload: true
});

export const endLoading = () => ({
  type: SET_LOADING,
  payload: false
});
