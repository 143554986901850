import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './container-header.styles.scss';

const ContainerHeader = (props) => {
  const { t } = useTranslation();
  const { header, children } = props;
  return (
    <div className="container-header">
      <span>{t(header)}</span>
      <div className="buttons">{children}</div>
    </div>
  );
};

ContainerHeader.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ContainerHeader.defaultProps = {
  children: <></>
};

export default ContainerHeader;
