import { HttpClient } from '../http.client';
import { config } from '../config';

const url = config.endpoints.produceExcelCompositor;

class produceExcelService {
  static async getByJob(jobId) {
    return HttpClient.getExcelFile(`${url}/by-job/${jobId}`, null);
  }

  static async getByPerson(personId) {
    return HttpClient.getExcelFile(`${url}/by-person/${personId}`, null);
  }

  static getDashabResults(filter = null) {
    return HttpClient.getExcelFile(`${url}/dashab-results`, filter);
  }
}

// eslint-disable-next-line import/prefer-default-export
export { produceExcelService };
