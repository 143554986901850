import React from 'react';
import { useTranslation } from 'react-i18next';
import './error.styles.scss';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <div className="error-container">
      <div className="massage">
        <ErrorOutlineIcon />
        <div>{t('ERROR_MASSAGE')}</div>
      </div>
      <div className="description">{t('ERROR_DESCRIPTION')}</div>
    </div>
  );
};

export default ErrorPage;
