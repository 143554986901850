// eslint-disable-next-line import/prefer-default-export
export const POPUP_MODES = {
  VIEW: 0,
  EDIT: 1
};

export const DISPLAYABLE_MODES = {
  UNIT: 'unit',
  PROFESSIONAL_GROUP: 'professionalGroup'
};
