import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FilterBarItem from '../filter-bar-item/filter-bar-item.component';
import AutoComplete from '../../../../common/components/auto-complete/auto-complete.component';
import SeasonsService from '../../../../services/seasons.service';
import ConfigService from '../../../../services/config.service';
import { setHeaderText } from '../../../../store/header-properties/header-properties.actions';
import './filter-bar.styles.scss';
import {
  setSeason,
  setCategory,
} from '../../../../store/filter/filter.actions';
import { items } from '../../../sidebar-items';

const Ranks = {
  dashabRasan: 'רסן',
  dashabSaal: 'סאל',
  dashabSeren: 'סרן',
  dashabAlam: 'אלם',
  dashabNagadim: 'נגדים',
};

const initialCategory = 'הכל';

const createCategories = (categories, setCategoryOnClick, currCategoryName) => {
  if (categories) {
    return categories.map((category) => (
      <FilterBarItem
        category={category}
        onClickFunc={setCategoryOnClick}
        selected={currCategoryName === category}
        key={`filter-bar--${category}`}
      />
    ));
  }
  return [];
};

const createSeasonFilter = (namespace) => {
  if (namespace === 'dashabRasan' || namespace === 'dashabSaal' || namespace === 'dashabSeren' || namespace === 'dashabAlam' || namespace === 'dashabNagadim') {
    return { rank: [Ranks[namespace]] };
  }
  if (namespace === 'dashabRbz') {
    return { rbz: true };
  }
  throw new Error('unknown namespace');
};

const FilterBar = (props) => {
  const {
    filter,
    setCategoryDispatch,
    setSeasonDispatch,
    namespace,
    setDispatchHeaderText,
    personalName,
    unitGroupOptions,
    onFilterUnitGroup,
  } = props;

  const [seasons, setSeasons] = useState([]);
  const [categories, setCategories] = useState([]);
  const [seasonState, setSeasonState] = useState({});
  const [unitGroupState, setUnitGroupState] = useState({});
  const { t } = useTranslation();

  useEffect(async () => {
    try {
      // init seasons
      const seasonsFilter = createSeasonFilter(namespace);
      const unsortedSeasons = await SeasonsService.getSeasonsByFilter(
        seasonsFilter
      );
      const sortedSeasons = unsortedSeasons.sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      );
      const finalSeasons = sortedSeasons.map((season) => ({
        seasonId: season.id,
        seasonName: season.title,
        rank: season.rank,
      }));

      setSeasons(finalSeasons);

      if (finalSeasons.length) {
        setSeasonDispatch(finalSeasons[0]);
        setSeasonState(finalSeasons[0]);
      } else {
        setSeasonDispatch({});
        setSeasonState({});
      }

      if (finalSeasons.length) {
        setSeasonDispatch(finalSeasons[0]);
        setSeasonState(finalSeasons[0]);
      } else {
        setSeasonDispatch({});
        setSeasonState({});
      }

      // init unitGroup
      setUnitGroupState('');

      // init categories
      const allCategories = await ConfigService.getAllCategories();
      setCategories([initialCategory].concat(allCategories));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, [namespace]);

  useEffect(() => {
    if (seasonState.seasonName) {
      const pageHeaderText = items.find((item) => namespace === item.namespace)
        .pageHeader;
      if (namespace === 'dashabRbz') {
        setDispatchHeaderText(
          `${t(pageHeaderText)} - ${seasonState.seasonName} - ${seasonState.rank} ${
            personalName || ''
          }`
        );
      } else {
        setDispatchHeaderText(
          `${t(pageHeaderText)} - ${seasonState.seasonName} ${personalName || ''}`
        );
      }
    }
  }, [seasonState]);

  const onChangeSeason = (newValue) => {
    setSeasonDispatch(newValue);
    setSeasonState(newValue);
  };

  const onChangeUnitGroup = (newValue) => {
    onFilterUnitGroup(newValue);
    setUnitGroupState(newValue);
  };

  useEffect(() => {
    setUnitGroupState('');
  }, [filter.category, filter.season]);

  return (
    <div className="filter__bar">
      <div className="basic-filter">
        {createCategories(
          categories,
          setCategoryDispatch,
          filter.category || initialCategory
        )}
      </div>
      <div className="auto-complete-grid">
        <Grid container spacing={3} className="auto-complete-grid">
          <Grid item xs={6}>
            <AutoComplete
              value={seasonState}
              onChange={(newValue) => onChangeSeason(newValue)}
              options={seasons}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              value={unitGroupState || ''}
              onChange={(newValue) => onChangeUnitGroup(newValue.target.innerHTML)}
              options={['', ...unitGroupOptions]}
              classes={{
                input: 'autocomplete',
                root: 'root-autocomplete',
                listbox: 'listbox-autocomplete',
              }}
              disableClearable
              getOptionLabel={(option) => (option ? option.toString() : t('ALL_UNITGROUPS'))}
              renderInput={(params) => (
                <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                  variant="standard"
                  classes={{ root: 'autocomplete' }}
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

FilterBar.propTypes = {
  namespace: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    season: PropTypes.shape({
      seasonName: PropTypes.string,
      seasonId: PropTypes.string,
    }),
    category: PropTypes.string,
  }).isRequired,
  setCategoryDispatch: PropTypes.func.isRequired,
  setSeasonDispatch: PropTypes.func.isRequired,
  setDispatchHeaderText: PropTypes.func.isRequired,
  personalName: PropTypes.string,
  unitGroupOptions: PropTypes.arrayOf(PropTypes.string.isRequired),
  onFilterUnitGroup: PropTypes.func.isRequired,
};

FilterBar.defaultProps = {
  personalName: '',
};

const mapStateToProps = (state, ownProps) => {
  const myState = state[ownProps.namespace];
  return {
    filter: myState.filter,
    namespace: ownProps.namespace,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSeasonDispatch: (season) => dispatch(setSeason(ownProps.namespace, season)),
  setCategoryDispatch: (category) => dispatch(setCategory(ownProps.namespace, category)),
  setDispatchHeaderText: (text) => dispatch(setHeaderText(text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
