import { HttpClient } from '../http.client';
import { config } from '../config';

const url = config.endpoints.usersCompositor;

class UsersService {
  static async getByFilter(filter) {
    return HttpClient.get(`${url}`, filter);
  }

  static async postVisit() {
    return HttpClient.post(`${url}/visit`);
  }

  static async getAllNonRegular() {
    return HttpClient.get(`${url}/non-regular`, null);
  }

  static async getById(id) {
    return HttpClient.get(`${url}/${id}`, null);
  }

  static updateById(id, body) {
    return HttpClient.put(`${url}/${id}`, body);
  }

  static async createAndGet(id) {
    return HttpClient.get(`${url}/createAndGet/${id}`, null);
  }

  static async addOuterUser(user) {
    return HttpClient.post(`${url}/addOuterUser`, user);
  }
}

// eslint-disable-next-line import/prefer-default-export
export { UsersService };
