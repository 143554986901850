import { SET_SUCCESS_MESSAGE, SET_LOADING, SET_ERROR } from './events.types';

const initialState = {
  successMessage: null,
  isLoading: false,
  errorMessage: null
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: action.payload
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case SET_ERROR:
      return {
        ...state,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

export default eventsReducer;
