/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
// import cx from 'classnames';
import cx from 'classnames';
import Button from '../../../../common/components/button/button.component';
import Dialog from '../../../../common/components/dialog/dialog.component';
import TextInput from '../../../../common/components/text-input/text-input.component';
import uploadIcon from '../../../../../assets/logos/upload.svg';
import ConfigService from '../../../../services/config.service';
import { setMessageError } from '../../../../store/events/events.actions';
import styles from '../../../../common/components/button/button.module.scss';
import './file-popup.styles.scss';

const FilePopup = (props) => {
  const {
    isOpen, setIsOpen, uploadFile, user
  } = props;
  const { t } = useTranslation();
  const [fileData, setFileData] = useState({});
  const [fileTypes, setFileTypes] = useState('');
  const dispatch = useDispatch();

  useEffect(async () => {
    const configFileTypes = await ConfigService.getFileTypes().catch((err) => {
      dispatch(setMessageError(err));
    });
    setFileTypes(configFileTypes);
  }, []);
  useEffect(async () => {
    if (user) setFileData({ armyId: user.armyId });
  }, [user]);

  const onSubmit = () => {
    uploadFile(fileData);
    setFileData({
      armyId: user.armyId
    });
    setIsOpen(false);
  };

  const getTextInput = (
    header,
    property,
    isDate = false,
    min = '',
    max = ''
  ) => (
    <TextInput
      header={header}
      onChange={(value) => setFileData({ ...fileData, [property]: value })}
      type={isDate ? 'date' : 'text'}
      min={min}
      max={max}
      required
      inverted
      boundValue={fileData[property] || ''}
    />
  );

  const getDialogContent = () => (
    <Grid
      container
      spacing={5}
      direction="row"
      justify="center"
      alignItems="flex-end"
    >
      <Grid item xs={4}>
        {getTextInput('FILE_NAME', 'title')}
      </Grid>
      <Grid item xs={4}>
        {getTextInput('DATE', 'date', true)}
      </Grid>
      <Grid item xs={4}>
        <label htmlFor="input" className={cx(styles.button)}>
          {t('CHOOSE_FILE')}
        </label>
        <input
          hidden
          accept={fileTypes}
          type="file"
          id="input"
          onChange={(e) => setFileData({ ...fileData, file: e.target.files[0] })}
        />
        {fileData.file && (
          <span className="chosen-file">
            {`${t('CHOSEN_FILE')}: ${fileData.file.name}`}
          </span>
        )}
      </Grid>
      <Grid item xs={10} />
      <Grid item xs={2}>
        <Button
          image={uploadIcon}
          text={t('FILE_UPLOAD')}
          onClick={() => onSubmit()}
          disabled={!fileData.date || !fileData.title || !fileData.file}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Dialog
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        header={`${t('UPLOAD_FILE_FOR')} - ${
          user && `${user.firstName} ${user.lastName}`
        }`}
        jobDialog
      >
        {getDialogContent()}
      </Dialog>
    </>
  );
};

FilePopup.propTypes = {
  user: PropTypes.shape().isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
};

export default FilePopup;
